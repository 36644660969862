import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import firebase from "../../../firebase";
import {
  Stack,
  Typography,
  TextField,
  Grid,
  Card,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function AdminView() {
  const navigate = useNavigate();
  const [progress, setprogress] = React.useState(false);
  const [admin, setAdmin] = React.useState(null);
  const [values, setValues] = useState("");
  // get the project id from the url
  const location = useLocation();
  const email = location.pathname.split("/")[3];

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleAdminActivate = () => {
    setprogress(true);
    // setmsg("Creating client...");
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("admins")
            .doc(doc.id)
            .update({
              status: "active",
            })
            .then((docRef) => {
              setprogress(false);
              // notify("Client created successfully");
              navigate("/admins");
            })
            .catch((error) => {
              setprogress(false);
              // notify("Error creating client");
            });
        });
      })

      .catch((error) => {
        setprogress(false);
        // notify("Error creating client");
      });
  };

  const handleAdminDeActivate = () => {
    setprogress(true);
    // setmsg("Creating client...");
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("admins")
            .doc(doc.id)
            .update({
              status: "inactive",
            })
            .then((docRef) => {
              setprogress(false);
              // notify("Client created successfully");
              navigate("/admins");
            })
            .catch((error) => {
              setprogress(false);
              // notify("Error creating client");
            });
        });
      })

      .catch((error) => {
        setprogress(false);
        // notify("Error creating client");
      });
  };

  const handleUpdateClient = () => {
    setprogress(true);
    // setmsg("Creating client...");
    firebase
      .firestore()
      .collection("clients")
      .add({
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        type: "client",
        createdAt: new Date(Date.now()).toLocaleString(),
      })
      .then((docRef) => {
        setprogress(false);
        // notify("Client created successfully");
        navigate("/clients/view/" + values.email);
      })
      .catch((error) => {
        setprogress(false);
        // notify("Error creating client");
      });
  };
  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .onSnapshot((snapshot) => {
        const newAdmin = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAdmin(newAdmin[0]);
        console.log(newAdmin, "check admin fetch");
      });

    return () => unsubscribe();
  }, []);
  return (
    <div>
      <Link to="/admins">Go back</Link> <br />
      <Stack direction="row" justifyContent="flex-end" mb={5}>
        <Stack direction="row" mb={5}>
          {admin && admin?.status === "active" ? (
            <Button
              variant="contained"
              color="error"
              onClick={handleAdminDeActivate}
            >
              Deactivate
            </Button>
          ) : admin?.status === "inactive" ? (
            <Button
              variant="contained"
              color="success"
              onClick={handleAdminActivate}
            >
              Activate
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              onClick={handleAdminDeActivate}
            >
              Deactivate
            </Button>
          )}
        </Stack>
        <br />
      </Stack>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Update client
          </Typography>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  onChange={handleChange("first_name")}
                  label="First Name"
                />
                <TextField
                  fullWidth
                  onChange={handleChange("last_name")}
                  label="Last Name"
                />
                <TextField
                  fullWidth
                  type="email"
                  onChange={handleChange("email")}
                  label="Email Address"
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={progress}
                  onClick={handleUpdateClient}
                >
                  Update client
                </LoadingButton>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
