// material
import {
  Box,
  Grid,
  Container,
  Typography,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
} from "@mui/material";
import { Link, Link as RouterLink, useLocation } from "react-router-dom";
// components
import Page from "../components/Page";
import {
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppWeeklySales,
  AppConversionRates,
} from "../sections/@dashboard/app";
import { alpha, styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import useGetQuotes from "..//hooks/useGetQuotes";
import Scrollbar from "../components/Scrollbar";
import useGetBlocks from "../hooks/useGetBlocks";

import UserView from "./pages/UserView";
import QuoteView from "./pages/QuoteView";
import { fNumber } from "../utils/formatNumber";
import AppCurrentDownload from "src/sections/@dashboard/app/AppCurrentDownload";
import BookingTotal from "src/sections/@dashboard/app/BookingTotal";

import AppQuoteSource from "src/sections/@dashboard/app/AppQuoteSource";
import AppTotal from "src/sections/@dashboard/app/AppTotal";
import { formatDateWithMoment } from "src/utils/smartUtils";

export default function DashboardApp() {
  const data = useGetQuotes().docs;

  let logs = useGetBlocks().docs;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [_quotes, setQuotes] = useState([]);

  useEffect(() => {
    data && arrangeStuff(data);
  }, [data]);

  const arrangeStuff = (data) => {
    const _data = [];
    const groupByCategory = data.reduce((group, user) => {
      const inputDate = "10/04/2023";

      // Split the date string into an array using "/" as the delimiter
      const dateArray = inputDate.split("/");

      // Rearrange the elements of the dateArray to the desired order
      [dateArray[1], dateArray[0]] = [dateArray[0], dateArray[1]];

      // Join the elements of the dateArray back into a string using "/" as the separator
      const rearrangedDate = dateArray.join("/");

      // console.log(rearrangedDate); // Output: "04/10/2023"

      group[new Date(rearrangedDate).getMonth()] =
        group[new Date(rearrangedDate).getMonth()] ?? [];
      group[new Date(rearrangedDate).getMonth()].push(user);
      return group;
    }, {});
    const keys = Object.keys(groupByCategory);
    keys.forEach((key) => {
      // push to _data if key is not NaN
      if (!isNaN(key)) {
        _data.push({
          label: key,
          value: groupByCategory[key]?.length,
        });
      }
      // console.log(`${key}: ${groupByCategory[key].length}`);
    });

    setQuotes(_data);
  };

  let new_quotes = data.filter(function (e) {
    return e.status === "NEW";
  });

  // limit the number of quotes to 5
  let completed_quotes = data
    .filter(function (e) {
      return e.status === "COMPLETED";
    })
    .slice(0, 5);

  return (
    <Page title="Dashboard">
      <Container>
        <Grid container spacing={2} style={{ display: "flex" }}>
          {/* <h3>Dashboard under reconstruction</h3> */}
          <Grid item xs={12} sm={12} md={9}>
            <AppConversionRates
              title="Quotes Per Month"
              subheader={new Date(Date.now()).getFullYear() + ""}
              chartData={
                _quotes &&
                _quotes?.map((asd) => {
                  console.log(asd);
                  return {
                    label: months[asd.label],
                    value: asd.value,
                  };
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} spacing={3} md={3}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              style={{ marginBottom: 10 }}
            >
              <Link
                to={"/quote/create/"}
                style={{
                  display: "grid",
                  color: "inherit",
                  textDecoration: "none",
                  justifyContent: "center",
                }}
              >
                Create a Quote
              </Link>
            </Button>

            <AppCurrentDownload data={data} />
            <br />
            <BookingTotal data={data} />
          </Grid>

          {/* new quotes */}
          <Grid item xs={12} sm={6} md={4}>
            <AppWeeklySales data={data} />
          </Grid>

          {/* progress */}
          <Grid item xs={12} sm={6} md={4}>
            <AppItemOrders data={data} />
          </Grid>

          {/* closed */}
          <Grid item xs={12} sm={6} md={4}>
            <AppBugReports data={data} />
          </Grid>

          {/* open */}
          <Grid item xs={12} sm={6} md={4}>
            <AppNewUsers data={data} />
          </Grid>

          {/* total */}
          <Grid item xs={12} sm={6} md={4}>
            <AppTotal data={data} />
          </Grid>
          {/* bound */}
          <Grid item xs={12} sm={6} md={4}>
            <AppQuoteSource data={data} />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardHeader
                title={`${new_quotes.length} New Quotes`}
                sx={{ mb: 3 }}
              />
              <Scrollbar>
                <TableContainer sx={{}}>
                  <Table>
                    <TableBody>
                      {new_quotes.slice(0, 10).map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.client}</TableCell>
                          <TableCell>R {fNumber(row.TotalFees)}</TableCell>

                          <TableCell>
                            <Link
                              to={"/quote/" + row.id}
                              state={{ data: row.id }}
                              style={{
                                display: "grid",
                                color: "inherit",
                                textDecoration: "none",
                                justifyContent: "center",
                              }}
                            >
                              <Button size="small" variant="contained">
                                View
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardHeader
                title={`${completed_quotes.length} Completed Quotes`}
                sx={{ mb: 3 }}
              />
              <Scrollbar>
                <TableContainer sx={{}}>
                  <Table>
                    <TableBody>
                      {completed_quotes.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.client}</TableCell>
                          <TableCell>R{fNumber(row.TotalFees)}</TableCell>

                          <TableCell>
                            <Link
                              to={"/quote/" + row.id}
                              state={{ data: row.id }}
                              style={{
                                display: "grid",
                                color: "inherit",
                                textDecoration: "none",
                                justifyContent: "center",
                              }}
                            >
                              <Button size="small" variant="contained">
                                View
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardHeader title="Activity" sx={{ mb: 3 }} />
              <Scrollbar>
                <TableContainer sx={{}}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {logs.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {row.user_id ? (
                              <>
                                <Typography
                                  variant="h6"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <UserView data={row?.user_id} />
                                </Typography>{" "}
                                {" Changed "}
                              </>
                            ) : null}
                            {row.user_id ? null : null}
                            <QuoteView data={row.item_id} /> to {row.log}.{" "}
                            <span style={{ color: "grey" }}>
                              {" "}
                              - {formatDateWithMoment(row.createdAt)}
                            </span>
                          </TableCell>

                          <TableCell>
                            <Link
                              to={"/quote/" + row.item_id}
                              state={{ data: row.item_id }}
                              style={{
                                display: "grid",
                                color: "inherit",
                                textDecoration: "none",
                                justifyContent: "center",
                              }}
                            >
                              <Button size="small" variant="contained">
                                View
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
