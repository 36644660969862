import React from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  // Block,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { InputBox } from "../input";
import { FileUpload } from "../fileuploads";
import { ScrollDialogPopUp } from "../popopOverlay";
import { sanitiseFieldName } from "src/utils/smartUtils";
import Label from "src/components/Label";
import { useExternalAuth } from "src/context/externalUserContext";
import { ca, el } from "date-fns/locale";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  "& > *": { mx: 1 },
};
export const ControlledInputs = (props) => {
  const {
    label,
    value,
    onChange,
    hideVerifyButton = false,
    type = "text",
    status,
    helperText,
    inputsExpected,
    inputsExpected2,
    inputsExpected3,
    radio,
    data,
    readOnly = false,
    projectCode,
    isAdmin,
    hints,
    adminCanSubmit,
    page,
    dropDownVals,
  } = props;

  const [openInputDialogue, setOpenInputDialogue] = React.useState(false);
  const [dialogueData, setDialogueData] = React.useState(null);
  const [externalAuth, setExternalAuth] = useExternalAuth();
  const [canSubmitFields, setCanSubmitFields] = React.useState(false);
  const [whichRadioSelected, setWhichRadioSelected] = React.useState(null);

  const [showTheseInputs, setShowTheseInputs] = React.useState(
    inputsExpected || []
  );
  const [dropDownValue, setDropDownValue] = React.useState("");
  const [shouldShowInputs, setShouldShowInputs] = React.useState(
    radio?.length > 0 ? false : true
  );
  const handleShowInputs = (e) => {
    setShouldShowInputs(
      e?.toLowerCase() === "yes" ||
        e?.toLowerCase() === "single" ||
        e?.toLowerCase() === "double" ||
        e?.toLowerCase() === "dual flush" ||
        e?.toLowerCase() === "heat pump" ||
        e?.toLowerCase() === "boiler" ||
        e?.toLowerCase() === "solar geysers"
    );
  };

  const [inputType, setInputType] = React.useState(() => {
    if (radio && radio.length > 0) {
      return "radio";
    } else {
      return "text";
    }
  });

  // use one state to handle all inputs and pass it to the input component
  const [inputValues, setInputValues] = React.useState({});

  const handleInputChange = (e, label, type) => {
    const { name, value } = e.target;
    console.log(e.target);

    if (value === "Boiler") {
      setShowTheseInputs(inputsExpected2);
    } else if (value === "Solar geysers") {
      setShowTheseInputs(inputsExpected3);
    } else if (value === "Heat pump") {
      setShowTheseInputs(inputsExpected);
    } else {
      setShowTheseInputs(inputsExpected);
    }

    if (dropDownVals?.options?.length > 0) {
      console.log(dropDownVals?.label, "dropDownVals?.label", dropDownValue);
      setInputValues({
        ...inputValues,
        [name]: value,
        [dropDownVals?.label]: dropDownValue,
        type,
      });
      return;
    } else {
      setInputValues({ ...inputValues, [name]: value, type });
    }
  };

  const handleIfDataIsRadio = () => {
    console.log(data?.value?.radio, "radio");
    if (data?.value?.radio) {
      return data?.value?.radio;
    }
  };

  // set input values to the data from the database
  React.useEffect(() => {
    if (data?.value) {
      setInputValues(data?.value);

      if (data?.value?.radio) {
        handleShowInputs(data?.value?.radio);
      }

      if (data?.value?.file) {
        // console.log("file");
        handleShowInputs(data?.value?.file);
      }
    }
  }, [data]);

  // set canSubmitFields based on page and externalAuth profession
  React.useEffect(() => {
    if (page === externalAuth?.profession) {
      setCanSubmitFields(true);
    } else if (isAdmin) {
      setCanSubmitFields(true);
    } else if (
      !externalAuth?.profession ||
      externalAuth?.profession === undefined
    ) {
      setCanSubmitFields(true);
    } else {
      setCanSubmitFields(false);
    }
  }, [page, externalAuth]);

  const handleWhichRadioSelected = () => {
    const sanitisedLabel = sanitiseFieldName(label);
    if (data?.value?.[sanitisedLabel] === "Yes") {
      handleShowInputs("Yes");
      return "Yes";
    } else if (data?.value?.[sanitisedLabel] === "No") {
      return "No";
    }
  };

  return (
    <div>
      <ScrollDialogPopUp
        open={openInputDialogue}
        setOpen={setOpenInputDialogue}
        dialogueData={dialogueData}
        label={label}
      />
      <Box
        sx={{
          width: "80%",
          paddingTop: "2rem",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "primary.main",
            },
          },
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {label}
            </Typography>

            <QuestionStatus
              status={data?.status || "pending"}
              helperText={helperText}
            />

            {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {status || "Pending"}
            </Typography> */}
          </Stack>
          {helperText && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {helperText}
            </Typography>
          )}
          {hints && (
            <Label
              title="question pending response"
              color="default"
              style={{
                marginBottom: "1rem",
              }}
            >
              {hints}
            </Label>
            //  <Typography variant="caption" sx={{ color: "warning.main",}}>
            //   {hints}
            //  </Typography>
          )}
        </Stack>
        {radio?.length > 0 && (
          <RadioGroup row defaultValue={handleWhichRadioSelected}>
            {radio.map((radio) => (
              <FormControlLabel
                value={radio}
                onChange={(e) => {
                  handleShowInputs(radio);
                  handleInputChange(e, label, "radio");
                }}
                name={sanitiseFieldName(label)}
                control={<Radio color="info" />}
                label={radio}
                disabled={readOnly}
              />
            ))}
          </RadioGroup>
        )}

        {shouldShowInputs &&
          showTheseInputs?.map((input, index) => (
            <InputBox
              key={index}
              mainLabel={label}
              projectCode={projectCode}
              readOnly={
                data?.status === "approved"
                  ? true
                  : !canSubmitFields || readOnly
                  ? true
                  : false
              }
              input={input}
              handleInputChange={handleInputChange}
              inputValues={inputValues}
            />
          ))}

        {shouldShowInputs && dropDownVals?.options?.length > 0 && (
          <TextField
            sx={{
              mt: 2,
            }}
            select
            fullWidth
            label={dropDownVals?.label}
            placeholder={dropDownVals?.placeholder}
            SelectProps={{ native: true }}
            value={dropDownValue}
            name={`glazing_${dropDownVals?.label}`}
            onChange={(e) => {
              setDropDownValue(e.target.value);
              handleInputChange(e, label, "dropdown");
            }}
          >
            <option value="" />
            {dropDownVals?.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        )}

        {/* {canSubmitFields && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            We can upload bro
          </Typography>
        )} */}

        {!canSubmitFields ? null : (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {
                data?.comments ? (
                  <Label color="error" variant="filled">
                    {data?.comments}
                  </Label>
                ) : data?.status === "approved" ? null : null // </Typography> //   <a href="#!">Request changes ?</a> //   response approved and cannot be edited{" "} // <Typography variant="body2" sx={{ color: "text.secondary" }}>
              }
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {readOnly ? (
                data?.status === "submitted" || data?.status === "approved" ? (
                  <Button
                    onClick={() => {
                      setOpenInputDialogue(true);
                      setDialogueData({
                        title: label,
                        description: helperText,
                        value: data,
                        projectCode: projectCode,
                        type: data?.type || "text",
                      });
                    }}
                    variant="text"
                    color="info"
                    size="small"
                  >
                    {data?.status === "approved" ? "Update" : "Verifyrr"}
                  </Button>
                ) : (
                  <Button
                    title="No data has been submitted"
                    variant="text"
                    color="info"
                    size="small"
                    disabled
                  >
                    Verifyddd
                  </Button>
                )
              ) : data?.status === "approved" ? null : (
                <Button
                  onClick={() => onChange(inputValues, label, inputType)}
                  variant="text"
                  color="info"
                  size="small"
                >
                  {data?.status === "rejected"
                    ? "Update"
                    : data?.status === "submitted"
                    ? "Update"
                    : "Submit"}
                </Button>
              )}
            </Typography>
          </Stack>
        )}
        {isAdmin && data?.status === "submitted" && (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {
                data?.comments ? (
                  <Label color="error" variant="filled">
                    {data?.comments}
                  </Label>
                ) : data?.status === "approved" ? null : null // </Typography> //   <a href="#!">Request changes ?</a> //   response approved and cannot be edited{" "} // <Typography variant="body2" sx={{ color: "text.secondary" }}>
              }
            </Typography>
            <Button
              onClick={() => {
                setOpenInputDialogue(true);
                setDialogueData({
                  title: label,
                  description: helperText,
                  value: data,
                  projectCode: projectCode,
                  type: data?.type || "text",
                });
              }}
              variant="text"
              color="primary"
              size="small"
            >
              {data?.status === "approved"
                ? "Update"
                : hideVerifyButton
                ? null
                : "Verify"}
            </Button>
          </Stack>
        )}
      </Box>
    </div>
  );
};

const QuestionStatus = ({ status }) => {
  switch (status) {
    case "approved":
      return (
        <Label
          title="This reponse has been approved and can't be edited"
          color="success"
        >
          {status}
        </Label>
      );
    case "rejected":
      return (
        <Label title="This response requires some changes" color="error">
          {status}
        </Label>
      );
    case "submitted":
      return (
        <Label
          title="The response has been submitted, pending approval"
          color="info"
        >
          {status}
        </Label>
      );
    default:
      return (
        <Label title="question pending response" color="warning">
          {status}
        </Label>
      );
  }
};
