import { filter } from "lodash";
import { sentenceCase } from "change-case";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import firebase from "../../../firebase";

import Scrollbar from "../../../components/Scrollbar";
import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
//
import { MainLoadingPage } from "src/components/customs/MainLoadingPage";

//import USERLIST from '../_mocks_/user';
import useGetQuotes from "../../../hooks/useGetQuotes";
import { fNumber } from "src/utils/formatNumber";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "project", label: "Project Name", alignRight: false },
  { id: "TotalFees", label: "Cost", alignRight: false },
  { id: "status", label: "Status", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.client.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

// list of projects per client
export function ClientsProjects() {
  const location = useLocation();
  // const email = location.state.data;
  const navigate = useNavigate();
  // get the project id from the url
  const email = location.pathname.split("/")[3];
  const [client, setClient] = useState(null);
  const [quote, setQuote] = useState(null);
  const [msg, setMsg] = useState("Loading your projects");
  const [progress, setprogress] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let USERLIST = useGetQuotes().docs;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.client);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("clients")
      .where("email", "==", email)
      .onSnapshot((snapshot) => {
        const newClient = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClient(newClient[0]);
        setprogress(false);
      });
    return () => unsubscribe();
  }, [email]);

  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("quotes")
      .where("client", "==", email)
      .onSnapshot((snapshot) => {
        const newQuotes = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuote(newQuotes);
        setprogress(false);
        console.log(newQuotes, "newQuotes");
      });
    return () => unsubscribe();
  }, []);

  return (
    <Page title="Quotes">
      <Container>
        {/* back button */}
        <Button
          color="primary"
          size="medium"
          variant="text"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {/* {progress && <MainLoadingPage msg={msg} />} */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          {/* <Link to={`/clients/view/${email}`}>Back to client</Link> */}
          <Typography variant="h4" gutterBottom>
            {client?.firstName || email} Quotes
          </Typography>

          <Button
            variant="contained"
            component={Link}
            to={`/quote/create?email=${email}`}
          >
            Create quote
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            {USERLIST.length > 0 ? (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .filter((quote) => quote.email === email)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          client,
                          status,
                          project,
                          TotalFees,
                          email,
                        } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            {/* <TableCell padding="checkbox">
                        
                          </TableCell> */}

                            <TableCell align="left">{project}</TableCell>
                            <TableCell align="left">
                              R {fNumber(TotalFees)}
                            </TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">
                              {" "}
                              <Link
                                to={"/quote/" + id}
                                state={{ data: id }}
                                style={{
                                  display: "grid",
                                  color: "inherit",
                                  textDecoration: "none",
                                  justifyContent: "center",
                                }}
                              >
                                View
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="h4" gutterBottom>
                No Projects for user
              </Typography>
            )}
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
