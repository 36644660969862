import { forwardRef, useState, useEffect } from "react";
// material
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
  TextareaAutosize,
  Input,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import firebase from "src/firebase";
import {
  CERTIFICATION_STATUS,
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
  ROUND1_STATUS,
} from "src/config/constants";
// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function Round1SubmissionDialogue({
  setOpen,
  open,
  projectCode,
}) {
  const [progress, setprogress] = useState(false);
  const [rejectProgress, setReajectProgress] = useState(false);
  const [project, setProject] = useState(null);
  const [activatePrompt, setActivatePrompt] = useState(false);
  const [round, setRound] = useState(1);
  const [reason, setReason] = useState("");
  const [rejectedReason, setRejectReason] = useState(null);
  const [roundStatus, setRoundStatus] = useState({});
  const [completedFound, setCompletedFound] = useState(false);
  const [completedNumber, setCompletedNumber] = useState(null);

  // const handleRoundSubmitted = () => {
  //   setprogress(true);
  //   firebase
  //     .firestore()
  //     .collection("Round1")
  //     .doc(projectCode)
  //     .update({
  //       status: ROUND1_STATUS.COMPLETED,
  //     })
  //     .then(() => {
  //       // update the certification status to GET_STARTED
  //       firebase
  //         .firestore()
  //         .collection("Certification")
  //         .doc(projectCode)
  //         .set({
  //           status: CERTIFICATION_STATUS.GET_STARTED,
  //         })
  //         .then(() => {
  //           setprogress(false);
  //           setOpen(false);
  //           // refresh the page
  //           // window.location.reload();
  //         });
  //     });
  // };

  const handleRoundSubmitted = () => {
    setprogress(true);

    let roundCollection;
    switch (round) {
      case 1:
        roundCollection = "Round1";
        break;
      case 2:
        roundCollection = "Round2";
        break;
      case 3:
        roundCollection = "Round3";
        break;
      default:
        console.error("Invalid round number");
        return;
    }

    firebase
      .firestore()
      .collection(roundCollection)
      .doc(projectCode)
      .set(
        {
          status: ROUND1_STATUS.COMPLETED,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        // update the certification status to GET_STARTED
        firebase
          .firestore()
          .collection("Certification")
          .doc(projectCode)
          .set({
            status: CERTIFICATION_STATUS.GET_STARTED,
          })
          .then(() => {
            setprogress(false);
            setOpen(false);
            // refresh the page
            // window.location.reload();
          });
      });
  };

  const handleRoundRejected = (rejectionReason) => {
    if (!reason) {
      alert("kindly include the feedback for the submission");
      return;
    }
    setReajectProgress(true);

    let roundCollection;
    switch (round) {
      case 1:
        roundCollection = "Round1";
        break;
      case 2:
        roundCollection = "Round2";
        break;
      case 3:
        roundCollection = "Round3";
        break;
      default:
        console.error("Invalid round number");
        return;
    }

    firebase
      .firestore()
      .collection(roundCollection)
      .doc(projectCode)
      .set(
        {
          status: "REJECTED",
          reason: reason,
        },
        {
          merge: true,
        }
      )
      .then(() => {
        setReajectProgress(false);
        setOpen(false);
      });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("Round1")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setprogress(false);
          if (doc.data()?.status === INITIAL_ASSESSEMENT_STATUS.COMPLETED) {
            setActivatePrompt(true);
            setRound(1);
          }
        } else {
          setRound(1);
          console.log("No such document found!");
        }
      });
  }, [projectCode]);

  // useEffect(() => {
  //   const fetchRoundStatus = async () => {
  //     const roundStatus = {};
  //     for (let i = 1; i <= 3; i++) {
  //       const doc = await firebase
  //         .firestore()
  //         .collection(`Round${i}`)
  //         .doc(projectCode)
  //         .get();
  //       if (doc.exists) {
  //         roundStatus[i] = doc.data().status;
  //       } else {
  //         roundStatus[i] = "NOT_STARTED";
  //       }
  //     }
  //     setRoundStatus(roundStatus);
  //   };

  //   fetchRoundStatus();
  // }, []);
  useEffect(() => {
    const fetchRoundStatus = async () => {
      const roundStatus = {};
      let latestRound = 1;
      for (let i = 1; i <= 3; i++) {
        const doc = await firebase
          .firestore()
          .collection(`Round${i}`)
          .doc(projectCode)
          .get();
        if (doc.exists) {
          roundStatus[i] = doc.data().status;
          if (doc.data().status !== "NOT_STARTED") {
            latestRound = i;
          }
        } else {
          roundStatus[i] = "NOT_STARTED";
        }
      }

      setRoundStatus(roundStatus);
      setRound(latestRound + 1);
      for (const number in roundStatus) {
        if (roundStatus[number] === "COMPLETED") {
          setCompletedFound(true);
          setCompletedNumber(number);
          break; // Exit the loop once 'COMPLETED' is found
        }
      }
    };

    fetchRoundStatus();
  }, [projectCode]);

  console.log(roundStatus, "check for round status");

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {completedFound ? (
          <DialogTitle id="alert-dialog-slide-title">
            Round Submission
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-slide-title">
            Confirm Round {round} Submission
          </DialogTitle>
        )}
        {/* <DialogContent>
          {project?.status === ROUND1_STATUS.COMPLETED && (
            <Typography variant="caption">
              ✅ Round {round} submission confirmed.
            </Typography>
          )}
        </DialogContent> */}
        <DialogContent>
          {Object.entries(roundStatus).map(([round, status]) => {
            if (status === "COMPLETED") {
              return (
                <Stack>
                  <Typography key={round} variant="body1">
                    ✅ Round {round} submission confirmed.
                  </Typography>
                </Stack>
              );
            } else if (status === "REJECTED") {
              !completedFound && (
                <Stack>
                  <Typography key={round} variant="body1">
                    ❌ Round {round} submission rejected.
                  </Typography>
                  <Typography key={round} variant="caption">
                    {reason}
                  </Typography>
                </Stack>
              );
            } else {
              return null;
            }
          })}
        </DialogContent>
        {!completedFound && (
          <DialogContent>
            <Input
              style={{
                width: "100%",
              }}
              value={reason}
              placeholder="leave a comment if the submission is rejected"
              onChange={(e) => setReason(e.target.value)}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            disabled={
              project?.status === ROUND1_STATUS.COMPLETED || completedFound
            }
            loading={rejectProgress}
            color="warning"
            variant="contained"
            onClick={handleRoundRejected}
          >
            Reject
          </LoadingButton>
          <LoadingButton
            disabled={
              project?.status === ROUND1_STATUS.COMPLETED || completedFound
            }
            variant="contained"
            onClick={handleRoundSubmitted}
            loading={progress}
            color="primary"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
