import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
// components
import Page from "../../components/Page";

import firebase from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useGetUser from "src/hooks/useGetUser";
import LoadingScreen from "src/components/LoadingScreen";

export default function MyProfile() {
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const [user, setuser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  // let user = useGetUser(firebase.auth().currentUser.uid).docs;

  console.log(firebase.auth().currentUser.uid);
  const notify = (msg) => toast(msg);

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate("/", { replace: true });
        window.location.reload(false);
      });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setuser(doc.data());
          setIsLoading(false);
          console.log(doc.data(), "check use on auth");
        });
      });
  }, []);

  console.log(user, "check user");

  return (
    <Page title="Dashboard">
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              My Profile {user?.firstName}
            </Typography>
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Typography variant="body2" gutterBottom>
                    First Name
                    <Typography variant="h5" gutterBottom>
                      {user?.firstName}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Last Name
                    <Typography variant="h5" gutterBottom>
                      {user?.lastName}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Email
                    <Typography variant="h5" gutterBottom>
                      {user?.email}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Admin
                    <Typography variant="h5" gutterBottom>
                      {user?.admin ? "Admin" : null}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Account Status
                    <Typography variant="h5" gutterBottom>
                      {user?.accountDisabled ? "Account Disabled" : "Active"}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Group
                    <Typography variant="h5" gutterBottom>
                      {user?.group}
                    </Typography>
                  </Typography>

                  <Button
                    fullWidth={false}
                    variant="contained"
                    onClick={() => logout()}
                  >
                    Log Out
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
