import { useState } from "react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function FourFilesUploaded({ open, setOpen }) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Files uploaded successfully.</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have submitted all the required files. The solid green team will
          be review and submit submit on your behalf.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
