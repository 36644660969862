import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetQuote = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("quotes")
      .doc(id)
      .onSnapshot((doc) => {
        setdocs({ ...doc.data(), id: doc.id });
      });
    return () => unsub();
  }, []);
  return { docs };
};

export default useGetQuote;
