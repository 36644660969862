import { Link as RouterLink, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
// material
import { Grid, Button, Container, Checkbox, Stack, Typography, Card, TextField, MenuItem } from '@mui/material';
// components
import Page from '../../components/Page';
import { fNumber } from '../../utils/formatNumber';
import firebase from '../../firebase' 
import useConstants from 'src/hooks/useConstants';
import Office from './settings/office';
import Residential from './settings/resident';
import Warehouse from './settings/warehouse';

export default function Settings() {
  const [tab, setTab] = useState(0)

    let data = useConstants().docs

    useEffect(() => {
       arrangeStuff()
      },[tab])

    const [office, setoffice] = useState(null)
    const [residential, setresidential] = useState(null)
    const [warehouse, setWarehouse] = useState(null)
   
    // display the correct form based on the selected id
    const arrangeStuff = () =>{
        data.forEach((ele)=>{
            if(ele.id === "office")
            {
                setoffice(ele)
            }
            if(ele.id === "residential")
            {
                setresidential(ele)
            }
            if(ele.id === "warehouse")
            {
                setWarehouse(ele)
            }

        })
    }


  return (
    <Page title="Dashboard">
    <Container>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography> 
      </Stack>
      
      <Stack direction="row" alignItems="flex-start"  spacing={3} mb={2}>
            <Button onClick={()=>setTab(0)} variant="contained"  color={tab === 0 ? 'info' : 'primary'} size="medium">Office</Button>    
            <Button onClick={()=>setTab(1)} variant="contained"  color={tab === 1 ? 'info' : 'primary'}  size="medium">Residential</Button>
            <Button onClick={()=>setTab(2)} variant="contained"  color={tab === 2 ? 'info' : 'primary'}  size="medium">Warehouse</Button>
      </Stack>

      <Grid container spacing={3}>     
        {tab === 0 && office && <Office data={office}/>}
        {tab === 1 && residential && <Residential  data={residential} />}
        {tab === 2 && warehouse && <Warehouse  data={warehouse} />}
        
      </Grid>
    </Container>
  </Page>
  )
}
