import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
import validator from "validator";

import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MemberList from "./components/WorkingTeam";
import { MainLoadingPage } from "src/components/customs/MainLoadingPage";

export default function MemberListLink() {
  // get the project id from the url
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = location.pathname.split("/")[3];

  if (!projectId) {
    navigate("/404");
  }
  const [errorMessage, setErrorMessage] = useState("");

  // check if the quote exists
  // const [quote, setQuote] = useState(null);
  // const [project, setProject] = useState(null);
  // const [projectExists, setProjectExists] = useState(false);

  const notify = (msg) => toast(msg);

  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");
  const [project, setProject] = useState(null);
  const [members, setMembers] = useState([]);

  const [driveUrl, setDriveUrl] = useState(
    "https://drive.google.com/drive/folders/1JOhpSA5md4mnuJO0HvjDIvajvFFEeY9C?usp=share_link"
  );

  const handleRemoveMember = (email) => {
    let newMembers = members.filter((member) => member.email !== email);
    setMembers(newMembers);
  };

  const handleProjectMembersSave = (projectId) => {
    // setprogress(true);

    // update the project members
    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .update({
        members: members,
      })
      .then(() => {
        setprogress(false);
        navigate(`projects/view/${projectId}`);
      })
      .catch((err) => {
        setprogress(false);

        notify("Error updating project members");
      });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setMembers(doc.data()?.members);
        } else {
          navigate("/404");
        }
      });
  }, []);

  return (
    <Page title="Dashboard">
      {progress && <MainLoadingPage msg={msg} />}

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>
        <Stack mb={5}>
          <Typography variant="h4" gutterBottom>
            Project Members
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Add or remover members to the project
          </Typography>
        </Stack>

        <Stack bgcolor="#fff" minHeight="512px" padding={2} borderRadius={3}>
          <MemberList
            members={members}
            setMembers={setMembers}
            handleRemoveMember={handleRemoveMember}
            driveUrl={driveUrl}
            setDriveUrl={setDriveUrl}
          />
        </Stack>

        <Stack>
          <Stack width={200} marginLeft="auto" marginRight="auto" p={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ marginBottom: 10 }}
              onClick={() => {
                handleProjectMembersSave(projectId);
              }}
            >
              Save & Close
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}

// projects/view/l0ZSogSJWPgK2cQ5
// projects/view/l0ZSogSJWPgK2cQ5zRoP

// l0ZSogSJWPgK2cQ5zRoP
// l0ZSogSJWPgK2cQ5zRoP
// l0ZSogSJWPgK2cQ5zRoP
