import { sum } from "lodash";
import PropTypes from "prop-types";
import {
  Page,
  View,
  Text,
  Font,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
// utils
import { fCurrency, fNumber } from "../../utils/formatNumber";
import Oswald from "./fonts/Oswald-Light.ttf";
import LatoBlack from "./fonts/Lato-Regular.ttf";
import { style } from "@mui/system";
// ----------------------------------------------------------------------

Font.register({
  family: "Oswald",
  fonts: [{ src: Oswald }],
});

Font.register({
  family: "Lato",
  fonts: [{ src: LatoBlack }],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%" },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: "uppercase",
  },
  bigText: {
    fontSize: 16,
    marginBottom: 0,
    fontWeight: 100,
    letterSpacing: 0,
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 10 },
  subtitle2: { fontSize: 9, fontWeight: 700, fontFamily: "Lato" },
  alignRight: { textAlign: "right" },
  divider: { marginVertical: 10 },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: "Lato",
    backgroundColor: "#fff",
  },
  header: {
    left: 24,
    right: 24,
    top: 0,
    paddingVertical: 10,
    margin: "auto",
    borderBottomWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    borderColor: "#DFE3E8",
  },
  image: {
    width: "25%",
  },
  footer: {
    left: 24,
    right: 24,
    bottom: 24,
    paddingVertical: 10,
    margin: "auto",
    borderTopWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    borderColor: "#DFE3E8",
  },
  otherText: {
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  gridContainer: { flexDirection: "row", justifyContent: "space-between" },
  table: { display: "flex", width: "auto" },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: "8px 0",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#DFE3E8",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "10%" },
  tableCell_2: { width: "70%", paddingRight: 16 },
  tableCell_3: { width: "15%" },
  tableCell_4: { width: "33%" },
});

export default function InvoicePDF({ data }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.gridContainer, ...styles.header }}>
          <View style={{ ...styles.col6, ...styles.alignRight }} />
          <View style={{ ...styles.col6, ...styles.alignRight }}></View>
        </View>

        <View style={styles.divider} />

        <View style={{ ...styles.gridContainer, ...styles.mb40 }}>
          <View style={styles.col8}>
            <Image style={styles.image} src="/logo.jpeg" />
            <Text style={{ ...styles.bigText }}>
              EDGE CONSULTING {"\n"} SERVICES{" "}
            </Text>
            <View style={styles.divider} />
          </View>
          <View style={styles.col4}>
            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              CLIENT : {data?.client}
            </Text>
            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              SOLID-{data?.quote_id}
            </Text>

            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              {data?.project} PROJECT
            </Text>
            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              {data?.email}
            </Text>
            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              {data?.createdAt}
            </Text>
            <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight,
              }}
            >
              {data?.phone}
            </Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableBody}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>Input</Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data?.type === "Office" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Gross Floor Area : {data?.gfa}m²
                  </Text>
                )}
                {data?.type === "Warehouse" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Gross Floor Area : {data?.gfa}m²
                  </Text>
                )}
                {data?.type === "Residential" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Typology : {data?.typology}, Units : {data?.units}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}></Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, backgroundColor: "#009ad7" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    color: "white",
                    ...styles.otherText,
                  }}
                >
                  Deliverable 1: EDGE Expert Services
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ color: "white", paddingRight: 10 }}>
                  R {data && fCurrency(data?.ExpertServices)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={{ ...styles.otherText, color: "white" }}>
                  Invoicing Schedule
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Preliminary Assessment
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.SC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.SC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.IA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>On commencement</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Documentation Preliminary Certification
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DCPCC)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On preliminary submission to certifying body
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Documentation Post Construction Certification
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPCC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPCC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On final submission to certifying body
                </Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, backgroundColor: "#009ad7" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    color: "white",
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Deliverable 2: EDGE Auditor Services
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ paddingRight: 10, color: "white" }}>
                  R {data && fCurrency(data?.AuditorServices)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={{ ...styles.otherText, color: "white" }}>
                  Invoicing Schedule
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Preliminary Design Audit
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PDA)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PDA)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On preliminary submission to certifying body
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Post Construction Audit
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCSA)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCSA)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On final submission to certifying body
                </Text>
              </View>
            </View>

            {data.PMS_Enabled && (
              <>
                <View
                  style={{ ...styles.tableRow, backgroundColor: "#009ad7" }}
                >
                  <View style={styles.tableCell_4}>
                    <Text
                      style={{
                        ...styles.subtitle2,
                        paddingLeft: 10,
                        color: "white",
                        ...styles.otherText,
                      }}
                    >
                      Deliverable 3 (optional): Performance Modeling and
                      Simulation
                    </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.alignRight]}>
                    <Text style={{ color: "white", paddingRight: 10 }}>
                      R {data && fCurrency(data?.AuditorServices)}
                    </Text>
                  </View>
                  <View style={styles.tableCell_4}>
                    <Text style={{ ...styles.otherText, color: "white" }}>
                      Invoicing Schedule
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View style={styles.tableCell_4}>
                    <Text
                      style={{
                        ...styles.subtitle2,
                        paddingLeft: 10,
                        ...styles.otherText,
                      }}
                    >
                      Performance modelling and simulation
                    </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.alignRight]}>
                    <Text style={{ paddingRight: 10 }}>
                      R {fCurrency(data.PMS)}
                    </Text>
                  </View>
                  <View style={styles.tableCell_4}>
                    <Text style={{ ...styles.otherText }}>
                      50% on commencement of project
                    </Text>
                  </View>
                </View>
              </>
            )}

            <View style={{ ...styles.tableRow, backgroundColor: "#009ad7" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 10,
                    color: "white",
                  }}
                >
                  Total Fees Excluding VAT
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ paddingRight: 10, color: "white" }}>
                  R {fCurrency(data?.TotalFees)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.subtitle2} />
                <Text />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.divider} />

        <View style={[styles.gridContainer]}></View>

        <View style={styles.divider} />

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col12}>
            <Text style={styles.otherText}>NOTES</Text>
            <Text style={styles.otherText}>
              Please use the email address edge@solidgreen.co.za for all
              correspondence relating to this project.
            </Text>
            <Text style={styles.otherText}>
              Please upload the relevant project information at
              edge@solidgreen.co.za to ensure that your project in completed as
              quickly and efficiently as possible.
            </Text>
          </View>
        </View>
      </Page>
      {/* end of page 1 */}
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.gridContainer, ...styles.header }}>
          <View style={{ ...styles.col6, ...styles.alignRight }} />
          <View style={{ ...styles.col6, ...styles.alignRight }}>
            <Text style={{ ...styles.subtitle2, ...styles.alignRight }}></Text>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={[styles.gridContainer]}>
          <View style={styles.col6}>
            <Text style={styles.otherText}>BANK DETAILS</Text>
            <Text>Solid Modeling (PTY) Ltd.</Text>
            <Text>Bank Name : FNB</Text>
            <Text>Branch Code : 253305</Text>
            <Text>Account Number : 62923276837</Text>
          </View>
          <View style={styles.col6}>
            <Text>
              Invoice payment is due 30 days from Invoice date and overdue
              invoices shall be subject to interest charges at the prevailing
              rate.
            </Text>

            <Text style={styles.otherText}>All amounts excl. VAT</Text>
          </View>
        </View>

        <View style={styles.divider} />

        <Text style={styles.otherText}>INVOICE DETAILS</Text>
        <View style={styles.tableRow}>
          <View style={styles.tableCell_4}>
            <Text>Name</Text>
          </View>
          <View style={[styles.tableCell_2]}>
            <Text>{data.client}</Text>
          </View>
          <View style={styles.tableCell_4}>
            <Text>Address</Text>
          </View>
          <View style={[styles.tableCell_2]} />
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCell_4}>
            <Text>Contact Number</Text>
          </View>
          <View style={[styles.tableCell_2]}>
            <Text>{data.phone}</Text>
          </View>
          <View style={styles.tableCell_4}>
            <Text>Email Address</Text>
          </View>
          <View style={[styles.tableCell_2]}>
            <Text>{data.email}</Text>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View style={styles.tableCell_4}>
            <Text>VAT # (optional)</Text>
          </View>
          <View style={[styles.tableCell_2]}></View>
          <View style={styles.tableCell_4}>
            <Text>Signature</Text>
          </View>
          <View style={[styles.tableCell_2]}>
            <Text></Text>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.col12}>
          <Text style={styles.otherText}>GENERAL TERMS AND CONDITIONS</Text>
          <Text>
            1. The fee proposal is subject to full and timeous cooperation of
            the professional teams and any 3rd party vendors and external
            consultants, as applicable and ready availability of data and
            information
          </Text>
          <Text>
            2. All fees and hourly rates are quoted exclusive of VAT, which will
            be charged at the prevailing rate.
          </Text>
          <Text>
            3. Invoice payment is due 15 days from Invoice date and overdue
            invoices shall be subject to interest charges at the prevailing
            rate.
          </Text>
        </View>

        <View style={styles.divider} />

        <View style={styles.col12}>
          <Text style={styles.otherText}>HOW ITS WORKS</Text>
          <Text>EDGE EXPERT </Text>
          <Text>
            An EDGE Expert is a professional that helps a developer, owner, and
            larger project team understand the EDGE standard, software and
            certification system. The scope of the EDGE Expert’s work includes
            advice on green design and assistance with preparation of an
            application for EDGE certification.{" "}
          </Text>
        </View>

        <View style={styles.divider} />

        <View style={styles.col12}>
          <Text>EDGE AUDITOR</Text>
          <Text>
            An individual who determines by investigation, and evaluation of
            objective evidence whether a building project meets the requirements
            of the EDGE standard. The EDGE Auditor completes this assessment by
            reviewing project documentation (Design Audit) and conducting visits
            to the project site (Site Audit), and making Recommendations for
            certification with the EDGE Standard to GBCI.
          </Text>
        </View>

        <View style={styles.divider} />

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col12}>
            <Text style={styles.otherText}>NOTES</Text>
            <Text style={styles.otherText}>
              Please use the email address edge@solidgreen.co.za for all
              correspondence relating to this project.
            </Text>
            <Text style={styles.otherText}>
              Please upload the relevant project information at
              edge@solidgreen.co.za to ensure that your project in completed as
              quickly and efficiently as possible.
            </Text>
          </View>
        </View>
      </Page>
      {/* end of page 2  */}

      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.gridContainer, ...styles.header }}>
          <View style={{ ...styles.col6, ...styles.alignRight }} />
          <View style={{ ...styles.col6, ...styles.alignRight }}>
            <Text style={{ ...styles.subtitle2, ...styles.alignRight }}></Text>
          </View>
        </View>
        <View style={styles.divider} />

        <View style={styles.col12}>
          <Text style={styles.otherText}>HOW IT WORKS</Text>
          <Text>
            The basics of our green building consulting services includes the
            following{" "}
          </Text>

          <Text>
            1. Understand the current project design and carry out an initial
            EDGE assessment.{" "}
          </Text>

          <Text>2. Solid Green will create the project in the EDGE App. </Text>

          <Text>
            3. Ensure the design is meeting the EDGE criteria with the help of
            the EDGE Expert.{" "}
          </Text>

          <Text>
            4. Register your project with the certification body (GBCI) – Client
            to Pay registration fees.{" "}
          </Text>

          <Text>5. Select an EDGE auditor. </Text>
          <Text>
            6. EDGE Expert will submit your EDGE project - Client to Pay
            certification fees.{" "}
          </Text>

          <Text>
            7. Review – the project is reviewed by the auditor and send to
            certification body.{" "}
          </Text>

          <Text>
            8. The auditor might request changes needed to ensure Preliminary
            Certification. These changes are done by the EDGE Expert.{" "}
          </Text>

          <Text>
            9. The auditor might request changes needed to ensure Preliminary
            Certification. These changes are done by the EDGE Expert.{" "}
          </Text>

          <Text>
            10. Certification – receive your Preliminary Certificate.{" "}
          </Text>

          <Text>
            11. At project completion the EDGE auditor will come and inspect the
            site.{" "}
          </Text>

          <Text>
            12. EDGE auditor will recommend the project to certification body
            for final certification.{" "}
          </Text>

          <Text>13. Review – certification body will review the audit. </Text>

          <Text>
            14. Certification – receive your final EDGE certification{" "}
          </Text>
        </View>

        <View style={styles.divider} />

        <View style={styles.col12}>
          <Text style={styles.otherText}>TIMELINES</Text>
          <Text>Step 1 - EDGE Expert - Baseline Assessment</Text>
          <Text>
            Baseline assessment conducted to understand the project’s compliance
            with the EDGE criteria.
          </Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 1 week
          </Text>

          <Text>
            Step 2 - Project team - Adjustment to documentation / design
          </Text>
          <Text>
            Depending on the outcome of the baseline assessment the project team
            will be required to provide updated documentation demonstrating
            compliance with EDGE criteria.
          </Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 4 week
          </Text>

          <Text>
            Step 3 - EDGE Expert - Compilation and submission preparation
          </Text>
          <Text>
            As soon as the updates have been made the EDGE expert will compile
            and prepare the project documentation for submission to the EDGE
            auditor & certification body.
          </Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 1 week
          </Text>

          <Text> Step 4 - EDGE Auditor & GBCSA - Round 1 Submission</Text>
          <Text>Assessment period by EDGE auditor & certification body.</Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 4 week
          </Text>

          <Text>Step 5 - Project team to address Round 1 comments</Text>
          <Text>
            Period taken for the project team to address the comments received
            through the Round 1 assessment. This is an estimated timeline and is
            dependent on comments received.
          </Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 5 week
          </Text>

          <Text>Step 6 - EDGE Auditor & GBCSA - Round 2 Submission</Text>
          <Text>Assessment period by EDGE auditor & certification body.</Text>
          <Text style={{ color: "#78a856", fontWeight: "bold" }}>
            Duration 5 week
          </Text>

          <Text>Step 7 </Text>
          <Text>Certification</Text>
          <Text> Project achieves EDGE preliminary certification.</Text>
        </View>

        <View style={styles.divider} />

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col12}>
            <Text style={styles.otherText}>NOTES</Text>
            <Text style={styles.otherText}>
              Please use the email address edge@solidgreen.co.za for all
              correspondence relating to this project.
            </Text>
            <Text style={styles.otherText}>
              Please upload the relevant project information at
              edge@solidgreen.co.za to ensure that your project in completed as
              quickly and efficiently as possible.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
