import { useState, useEffect } from "react";

export function useExternalAuth() {
  const [externalAuth, setExternalAuth] = useState(null);

  useEffect(() => {
    const storedAuth = localStorage.getItem("externalAuth");
    console.log(storedAuth, "stored auth");
    if (storedAuth) {
      setExternalAuth(JSON.parse(storedAuth));
    }
  }, []);

  useEffect(() => {
    if (externalAuth) {
      localStorage.setItem("externalAuth", JSON.stringify(externalAuth));
    }
  }, [externalAuth]);

  return [externalAuth, setExternalAuth];
}
