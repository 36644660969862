import { useState, useEffect } from "react";
// material
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  Grid,
  LinearProgress,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import firebase from "src/firebase";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import emailjs from "@emailjs/browser";
import { baseUrl } from "src/config";
import { LoadingButton } from "@mui/lab";
import FormQuestionsTranferList from "src/pages/pages/projects/components/SelectQuestions";
import { PrelimDataForms } from "src/pages/mapperFuncs/InitialAssess";
import { INITIAL_ASSESSEMENT_STATUS } from "src/config/constants";
// ----------------------------------------------------------------------

export default function ManageFormTransfers({
  open,
  setOpen,
  cleintEmail,
  projectId,
  projectCode,
  fromAdmin = false,
}) {
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let publicKey = "WffsPR6cSS6IwClRB";

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveForms = (e) => {
    setIsLoading(true);
    e.preventDefault();
    // save to firebase collection PrelimDataForm with id of projectCode and send email to the user
    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .set({
        projectCode,
        projectId,
        status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
        questions: right,
      })
      .then(
        () => {
          // send email to the user
          const loginURL = `${baseUrl()}/authenticate/client`;
          const messageBody = `Hi, you have been assigned to fill out the preliminary data form for the project ${projectCode}. Please login to your account to fill out the form with this link: ${loginURL} . Thank you`;
          const obj = {
            to_email: cleintEmail,
            projectCode,
            message: messageBody,
            subject: "Preliminary Data Form",
            projectLink: `${baseUrl}/projects/${projectId}`,
          };

          emailjs
            .send("service_6a716og", "template_56brr6e", obj, publicKey)
            .then(
              (result) => {
                console.log(result.text);
                setIsLoading(false);
                setOpen(false);
                // reload the page
                window.location.reload();
              },
              (error) => {
                console.log(error.text);
                setIsLoading(false);
                setOpen(false);
              }
            );
        }
        // , (error) => {
        //   console.log(error);
        //   setIsLoading(false);
        //   setOpen(false);
        // }
      );
  };

  // use useEffect to rerender the component when the users array changes
  useEffect(() => {
    // get the questions from the PrelimDataForms collection with the id of projectCode

    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .get()
      .then(
        (doc) => {
          if (doc.exists) {
            let data = doc.data();
            let questions = data.questions;

            if (questions?.length > 0) {
              let questionsArray = [];
              questions.forEach((question) => {
                questionsArray.push(question);
              });

              // set the left array to the questions in the PrelimDataForms collection remove the questions that are already in the right array
              // the question in the prelimDataForm are objects with label, etc

              let leftArray = PrelimDataForms.filter((question) => {
                let found = false;
                questionsArray.forEach((q) => {
                  if (q.label === question.label) {
                    found = true;
                  }
                });
                return !found;
              });

              setLeft(leftArray);
              setRight(questionsArray);
            } else {
              setLeft(PrelimDataForms);
              setOpen(true);
            }
          } else {
            // doc.data() will be undefined in this case
            setLeft(PrelimDataForms);
            console.log("No such document!");
          }
        }
        // , (error) => {
        //   console.log("Error getting document:", error);
        // }
      );
  }, []);

  return (
    <div
      style={{
        backgroundColor: "red",
      }}
    >
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>Prelim Data</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the questions you want to be asked in the form
          </DialogContentText>

          {/* {isLoading ? (
            <div
              style={{
                width: "100%",
                height: 184,
              }}
            >
              <LinearProgress color="inherit" />
            </div>
          ) : ( */}
          <FormQuestionsTranferList
            left={left}
            right={right}
            setRight={setRight}
            setLeft={setLeft}
          />
          {/* )} */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            close
          </Button>

          <LoadingButton
            loading={isLoading}
            onClick={handleSaveForms}
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
