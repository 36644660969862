import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Label from "src/components/Label";
import { PROJECT_STATUS } from "src/config/constants";
import firebase from "src/firebase";

import { useNavigate } from "react-router-dom";

export const EdgeRFI = ({ projectId, fromClient = false }) => {
  const [project, setProject] = useState(null);
  const [progress, setprogress] = useState(false);
  const navigate = useNavigate();

  const handleNavigateToData = () => {
    if (project?.status === PROJECT_STATUS.NEW && !fromClient) return;
    const clientUrl = `/client/dashboard/rfi/${project?.code}`;
    const adminUrl = `/projects/submitted-data/${project?.code}`;
    const url = fromClient ? clientUrl : adminUrl;

    navigate(url);
  };
  useEffect(() => {
    setprogress(true);
    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setprogress(false);
          console.log(doc.data(), "documnet found");
        } else {
          console.log("No such document!", projectId);
          setprogress(false);
        }
      });
  }, [projectId]);

  return (
    <Stack
      sx={{
        width: "auto",
        maxWidth: "300px",
        minWidth: "250px",
        py: 1,
        px: 3,
        bgcolor: "background.paper",
        borderRadius: 1,
        boxShadow: 1,
        border: 1,
        borderColor: PROJECT_STATUS.CLIENT_APPROVED
          ? "success.main"
          : "info.main",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography variant="body2" sx={{ mr: 2 }}>
        Edge RFI
      </Typography>
      {fromClient ? (
        <LoadingButton
          onClick={handleNavigateToData}
          size="small"
          loading={progress}
          sx={{
            bgcolor: `${
              project?.status === PROJECT_STATUS.NEW ? "#00AB55" : null
            }`,
            color: `${project?.status === PROJECT_STATUS.NEW ? "white" : null}`,
            borderColor: `${
              project?.status === PROJECT_STATUS.NEW ? "#00AB55" : null
            }`,
            "&:hover": {
              bgcolor: `${
                project?.status === PROJECT_STATUS.NEW ? "white" : null
              }`,
              color: `${
                project?.status === PROJECT_STATUS.NEW ? "#00AB55" : null
              }`,
              borderColor: `${
                project?.status === PROJECT_STATUS.NEW ? "#00AB55" : null
              }`,
            },
          }}
          variant="outlined"
          color={
            project?.status === PROJECT_STATUS.CLIENT_APPROVED
              ? "success"
              : project?.status === PROJECT_STATUS.IN_PROGRESS
              ? "warning"
              : "info"
          }
        >
          {project?.status === PROJECT_STATUS.CLIENT_APPROVED
            ? "Completed"
            : project?.status === PROJECT_STATUS.NEW
            ? "Get started"
            : "In progress"}
        </LoadingButton>
      ) : (
        <LoadingButton
          onClick={handleNavigateToData}
          disabled={project?.status === PROJECT_STATUS.NEW}
          size="small"
          loading={progress}
          variant="outlined"
        >
          {project?.status === PROJECT_STATUS.CLIENT_APPROVED
            ? "Completed"
            : project?.status === PROJECT_STATUS.NEW
            ? "waiting for client"
            : "In progress"}
        </LoadingButton>
      )}

      {/* {progress ? (
        <CircularProgress size={12} />
      ) : (
        <Label color="info">
          {project?.status === PROJECT_STATUS.CLIENT_APPROVED
            ? "Completed"
            : project?.status}
        </Label>
      )} */}
    </Stack>
  );
};
