import React from "react";
import useGetUser from "../../hooks/useGetUser";

function UserView({ data }) {
  const user = useGetUser(data).docs;

  return <>{user && user.firstName}</>;
}

export default UserView;
