import { sum } from "lodash";
import PropTypes from "prop-types";
import {
  Page,
  View,
  Text,
  Font,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
// utils
import { fCurrency, fNumber } from "../../utils/formatNumber";
import Oswald from "./fonts/Oswald-Light.ttf";
import LatoBlack from "./fonts/Lato-Regular.ttf";
import LatoBlack_ from "./fonts/Lato-Black.ttf";
import { style } from "@mui/system";
// ----------------------------------------------------------------------

Font.register({
  family: "Oswald",
  fonts: [{ src: Oswald }],
});

Font.register({
  family: "Lato",
  fonts: [{ src: LatoBlack }],
});

Font.register({
  family: "LatoBlack",
  fonts: [{ src: LatoBlack_ }],
});

const styles = StyleSheet.create({
  col4: { width: "25%" },
  col8: { width: "75%" },
  col6: { width: "50%" },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 800,
    letterSpacing: 1.2,
    // textTransform: "uppercase",
  },
  bigText: {
    fontSize: 16,
    marginBottom: 0,
    fontWeight: 100,
    letterSpacing: 0,
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  body1: { fontSize: 10 },
  subtitle2: { fontSize: 9, fontWeight: 700, fontFamily: "Lato" },
  alignRight: { textAlign: "right" },
  divider: { marginVertical: 10 },
  page: {
    padding: "40px 24px 0 24px",
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: "Lato",
    backgroundColor: "#fff",
  },
  header: {
    left: 24,
    right: 24,
    top: 0,
    paddingVertical: 10,
    margin: "auto",
    borderBottomWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    borderColor: "#DFE3E8",
  },
  image: {
    width: "25%",
  },
  bigImage: {
    width: "100%",
  },
  footer: {
    left: 24,
    right: 24,
    bottom: 24,
    paddingVertical: 10,
    margin: "auto",
    borderTopWidth: 1,
    borderStyle: "solid",
    position: "absolute",
    borderColor: "#DFE3E8",
  },
  otherText: {
    fontFamily: "Lato",
    fontWeight: "bold",
  },
  gridContainer: { flexDirection: "row", justifyContent: "space-between" },
  table: { display: "flex", width: "auto" },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: "8px 0",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: "#DFE3E8",
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: "10%" },
  tableCell_2: { width: "70%", paddingRight: 16 },
  tableCell_3: { width: "15%" },
  tableCell_4: { width: "33%" },
});

export default function FullPDF({ data }) {
  return (
    <Document>
     
   
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/1.png" />
        <View style={{backgroundColor:"#6DA743", height:50, width:205, position:"absolute", right:65, top:115}}>
        <Text
              style={{
                ...styles.overline,
                ...styles.alignRight, color:"#ffffff"
              }}
            >Project ID {data.quote_id}</Text>
           <Text
              style={{
                ...styles.overline,
                ...styles.mb8,
                ...styles.alignRight, color:"#ffffff"
              }}
            >EDGE Certification</Text>
        </View>

        <View style={{backgroundColor:"#F0F0F0", height:280, width:410, position:"absolute", right:65, top:230}}>
        <Text
              style={{
                ...styles.overline, fontFamily:"LatoBlack",
                ...styles.bigText, fontSize:28, fontWeight:900,  color:"#5C5D5D"
              }}
            >{data.project}</Text>
            <View style={styles.divider} />
            <View style={styles.divider} />

        <Text style={{...styles.overline,...styles.mb8,fontFamily:"LatoBlack", fontSize:12, fontWeight:900,  color:"#5C5D5D"}}>Prepared for:</Text>
        
        <View style={[styles.gridContainer]}>
        <View style={styles.col5}>
        
  
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>Name:</Text>
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>Email:</Text>
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>Phone:</Text>
        <View style={styles.divider} />
        <Text style={{...styles.overline,...styles.mb8, fontSize:12,color:"#5C5D5D"}}>Proposal issued:</Text>
        {/* <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>Proposal valid to: </Text> */}
        </View>
        
        <View style={styles.col6}>
        
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>{data.client}</Text> 
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>{data.email}</Text>
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>{data.phone}</Text>
        <View style={styles.divider} />
        <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>{data.createdAt}</Text>
        {/* <Text style={{...styles.overline,...styles.mb8,fontSize:12, color:"#5C5D5D"}}>13/01/2023 (3 Months)</Text> */}
        </View>
        </View>
        </View>

      </Page>
     <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/2.png" />
      </Page>
       <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/3.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/4.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/5.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/6.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/7.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/8.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/9.png" />
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.gridContainer, ...styles.header }}>
          <View style={{ ...styles.col6, ...styles.alignRight }} />
          <View style={{ ...styles.col6, ...styles.alignRight }}></View>
        </View>

        <View style={styles.divider} />
        
        <View>
          <Text style={{...styles.otherText, fontSize:40, fontWeight:900, color:"#6DA743"}}>Proposed Fee</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableBody}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>Input</Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data?.type === "Office" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Gross Floor Area : {data?.gfa}m²
                  </Text>
                )}
                {data?.type === "Warehouse" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Gross Floor Area : {data?.gfa}m²
                  </Text>
                )}
                {data?.type === "Residential" && (
                  <Text
                    style={{
                      ...styles.subtitle2,
                      paddingLeft: 10,
                      ...styles.otherText,
                    }}
                  >
                    Typology : {data?.typology}, Units : {data?.units}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}></Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, backgroundColor: "#6DA743" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    color: "white",
                    ...styles.otherText,
                  }}
                >
                  Deliverable 1: EDGE Expert Services
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ color: "white", paddingRight: 10 }}>
                  R {data && fCurrency(data?.ExpertServices)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={{ ...styles.otherText, color: "white" }}>
                  Invoicing Schedule
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Preliminary Assessment
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.SC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.SC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.IA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>On commencement</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Documentation Preliminary Certification
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DCPCC)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On preliminary submission to certifying body
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Documentation Post Construction Certification
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPCC)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPCC)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.DPC)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On final submission to certifying body
                </Text>
              </View>
            </View>

            <View style={{ ...styles.tableRow, backgroundColor: "#6DA743" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    color: "white",
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Deliverable 2: EDGE Auditor Services
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ paddingRight: 10, color: "white" }}>
                  R {data && fCurrency(data?.AuditorServices)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={{ ...styles.otherText, color: "white" }}>
                  Invoicing Schedule
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Preliminary Design Audit
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PDA)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PDA)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On preliminary submission to certifying body
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    ...styles.subtitle2,
                    paddingLeft: 10,
                    ...styles.otherText,
                  }}
                >
                  Post Construction Audit
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                {data && data.type === "Office" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCSA)}
                  </Text>
                )}
                {data && data.type === "Warehouse" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCSA)}
                  </Text>
                )}
                {data && data.type === "Residential" && (
                  <Text style={{ paddingRight: 10 }}>
                    R {fCurrency(data?.PCA)}
                  </Text>
                )}
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.otherText}>
                  On final submission to certifying body
                </Text>
              </View>
            </View>

            {data.PMS_Enabled && (
              <>
                <View
                  style={{ ...styles.tableRow, backgroundColor: "#6DA743" }}
                >
                  <View style={styles.tableCell_4}>
                    <Text
                      style={{
                        ...styles.subtitle2,
                        paddingLeft: 10,
                        color: "white",
                        ...styles.otherText,
                      }}
                    >
                      Deliverable 3 (optional): Performance Modeling and
                      Simulation
                    </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.alignRight]}>
                    <Text style={{ color: "white", paddingRight: 10 }}>
                      R {data && fCurrency(data?.AuditorServices)}
                    </Text>
                  </View>
                  <View style={styles.tableCell_4}>
                    <Text style={{ ...styles.otherText, color: "white" }}>
                      Invoicing Schedule
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.tableRow }}>
                  <View style={styles.tableCell_4}>
                    <Text
                      style={{
                        ...styles.subtitle2,
                        paddingLeft: 10,
                        ...styles.otherText,
                      }}
                    >
                      Performance modelling and simulation
                    </Text>
                  </View>
                  <View style={[styles.tableCell_4, styles.alignRight]}>
                    <Text style={{ paddingRight: 10 }}>
                      R {fCurrency(data.PMS)}
                    </Text>
                  </View>
                  <View style={styles.tableCell_4}>
                    <Text style={{ ...styles.otherText }}>
                      50% on commencement of project
                    </Text>
                  </View>
                </View>
              </>
            )}

            <View style={{ ...styles.tableRow, backgroundColor: "#6DA743" }}>
              <View style={styles.tableCell_4}>
                <Text
                  style={{
                    fontWeight: "bold",
                    paddingLeft: 10,
                    color: "white",
                  }}
                >
                  Total Fees Excluding VAT
                </Text>
              </View>
              <View style={[styles.tableCell_4, styles.alignRight]}>
                <Text style={{ paddingRight: 10, color: "white" }}>
                  R {fCurrency(data?.TotalFees)}
                </Text>
              </View>
              <View style={styles.tableCell_4}>
                <Text style={styles.subtitle2} />
                <Text />
              </View>
            </View>
          </View>
        </View>

        <View style={styles.divider} />

        <View style={styles.divider} />

        <View style={[styles.gridContainer]}></View>

        <View style={styles.divider} />

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col12}>
            <Text style={styles.otherText}>NOTES</Text>
            <Text style={styles.otherText}>
              Please use the email address edge@solidgreen.co.za for all
              correspondence relating to this project.
            </Text>
            <Text style={styles.otherText}>
              Please upload the relevant project information at
              edge@solidgreen.co.za to ensure that your project in completed as
              quickly and efficiently as possible.
            </Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/11.png" />
      </Page>
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/12.png" />
      </Page>

     
      <Page size="A4" style={styles.page}>
      <Image style={styles.bigImage} src="/pdf/14.png" />
      </Page> 
    </Document>
  );
}
