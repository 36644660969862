import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "src/layouts/AuthLayout";
// components
import Page from "src/components/Page";
import { RegisterForm } from "src/sections/authentication/register";
import AuthSocial from "src/sections/authentication/AuthSocial";
import firebase from "src/firebase";
import AdminActivationForm from "src/sections/authentication/Activation";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AdminActivate() {
  const navigate = useNavigate();
  // get the project id from the url
  const location = useLocation();
  const [admin, setAdmin] = useState(null);
  const adminId = location.pathname.split("/")[3];

  useEffect(() => {
    console.log("AdminActivate", adminId);
    // get admin details
    firebase
      .firestore()
      .collection("users")
      .doc(adminId)
      .get()
      .then(
        (doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            setAdmin(doc.data());
            // set the admin details in the state
            // setAdmin(doc.data());
            // set the admin details in the local storage
            // localStorage.setItem("admin", JSON.stringify(doc.data()));
            // navigate to the dashboard
            // navigate("/dashboard", { replace: true });
          } else {
            // doc.data() will be undefined in this case
            alert(
              "This link is invalid. Please contact the admin or check your email."
            );
            navigate("/login", { replace: true });
            console.log("No such document!");
          }
        }
        // .catch((error) => {
        //   console.log("Error getting document:", error);
        // });
      );
  }, []);

  return (
    <RootStyle title="Register">
      <AuthLayout></AuthLayout>

      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Welcome {admin?.firstName} {admin?.lastName} 👋
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Please set your password to activate your account.
            </Typography>
          </Box>

          {admin && <AdminActivationForm admin={admin} adminId={adminId} />}

          <Typography
            variant="body2"
            align="center"
            sx={{ color: "text.secondary", mt: 3 }}
          >
            By registering, I agree to Solid Edge&nbsp;
            <Link underline="always" color="textPrimary">
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" color="textPrimary">
              Privacy Policy
            </Link>
            .
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{
              mt: 3,
              textAlign: "center",
              display: {},
            }}
          >
            Already have an account?&nbsp;
            <Link underline="hover" to="/" component={RouterLink}>
              Login
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
