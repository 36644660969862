import { Navigate, useRoutes } from "react-router-dom";
// layouts
import NotFound from "./pages/Page404";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";

import ResetPasswordForm from "./pages/ResetPasswordForm";
import Welcome from "./pages/Welcome";
import AuthenticateClient from "./pages/external/Authentication";
import { DataSubmission } from "./pages/external/DataSubmission";
import InvoiceSubmit from "./pages/external/invoice-submit";
import AdminActivate from "./pages/pages/admins/activate";
import { InitialAssessmentForm } from "./pages/external/InitialAssessment";
import HomeDashboard from "./pages/external/Home";

// ----------------------------------------------------------------------

export default function LoginRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Welcome /> },
        { path: "/login", element: <Login /> },
        { path: "/register", element: <Register /> },
        { path: "/admins/activate/:id", element: <AdminActivate /> },
        { path: "/invoice/:quoteId", element: <InvoiceSubmit /> },
        { path: "/reset_password", element: <ResetPasswordForm /> },
        { path: "/authenticate/client", element: <AuthenticateClient /> },
        { path: "/client/dashboard/:id", element: <HomeDashboard /> },
        { path: "/client/dashboard/rfi/:id", element: <DataSubmission /> },
        {
          path: "/client/dashboard/initial-assesment/:projectCode",
          element: <InitialAssessmentForm />,
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },
  ]);
}
