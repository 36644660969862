
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
// material
import { Grid, Stack, Typography, Card, TextField } from '@mui/material';
// components

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from '../../../firebase' 

import UserView from '../UserView';

export default function Office({data}) {
  const notify = (msg) => toast(msg);
    const officeSchema = Yup.object().shape({
        SC : Yup.string().required("Enter a value"),
        PMS : Yup.string().required("Enter a value"),
        PDA : Yup.string().required("Enter a value"),
        PCSA : Yup.string().required("Enter a value"),
        DPCC : Yup.string().required("Enter a value"),
        PC : Yup.string().required("Enter a value")
    })

   

    const officeformik = useFormik({
        initialValues:{
            SC: data.SC,
            PMS: data.PMS,
            PDA: data?.PDA || "",
            PCSA: data?.PCSA || "",
            DPCC: data?.DPCC || "",
            PC: data?.PC || "",
            lastUpdated : new Date(Date.now()).toLocaleDateString(),
            user_id : firebase.auth().currentUser.uid
        },
        validationSchema: officeSchema,
        onSubmit : async (values, {setSubmitting, resetForm})=>{
            try{
                console.log(values)
                await updateValues(values)
                setSubmitting(false)
            } catch (error){
                console.log(error)
                setSubmitting(false)
            }
        }
    })

   

    const {errors, values, touched, handleSubmit, isSubmitting, getFieldProps} = officeformik
      
  const updateValues = (values) =>{
        firebase.firestore().collection("settings").doc("office").set(values).then(()=>{
          notify("Values updated")
        })
  }
 

  return (
   
      
      <Grid item xs={12} sm={12} md={6}>
         <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <FormikProvider value={officeformik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
  
      <Card sx={{p:3}}>
        <Stack spacing={3}>  
        <Typography variant="body1" gutterBottom>
          Office
        </Typography> 

        <TextField
        fullWidth type="number"
         label="Preliminary Certification" 
        {...getFieldProps('PC')}
        error={Boolean(touched.PC && errors.PC)}
        helperText={touched.PC && errors.PC}
        />  

        <TextField
        fullWidth type="number"
        label="Sustainability Consulting"
        {...getFieldProps('SC')}
        error={Boolean(touched.SC && errors.SC)}
        helperText={touched.SC && errors.SC} /> 
      
        <TextField
        fullWidth
        type="number"
          label="Documentation Post Construction Certification"
        {...getFieldProps('DPCC')}
        error={Boolean(touched.DPCC && errors.DPCC)}
        helperText={touched.DPCC && errors.DPCC} /> 
        
        <TextField
        fullWidth type="number"
         label="Preliminary Design Audit"
        {...getFieldProps('PDA')}
        error={Boolean(touched.PDA && errors.PDA)}
        helperText={touched.PDA && errors.PDA}
        /> 
        
        <TextField
        fullWidth type="number"
          label="Post Construction Site Audit"
        {...getFieldProps('PCSA')}
        error={Boolean(touched.PCSA && errors.PCSA)}
        helperText={touched.PCSA && errors.PCSA}
         /> 
        
        <TextField
        fullWidth type="number"
         label="Performance modeling and simulation" 
        {...getFieldProps('PMS')}
        error={Boolean(touched.PMS && errors.PMS)}
        helperText={touched.PMS && errors.PMS}
        />         


        <Typography>
          Last Updated {data.lastUpdated} by <UserView data={data.user_id} />
        </Typography>

<LoadingButton  type="submit" variant="contained" size="large" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
        </Stack>
      </Card>
      </Form>
      </FormikProvider> </Grid>
     
  )
}
