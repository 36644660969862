// component
import Iconify from "../../components/Iconify";
import SvgIconStyle from "../../components/SvgIconStyle";

// ----------------------------------------------------------------------

// const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
};
const sidebarConfig = [
  // {
  //   subheader: "general",
  //   items: [
  //     {
  //       title: "app",
  //       path: "/",
  //       icon: ICONS.dashboard,
  //     },
  //     { title: "e-commerce", path: "/quotes", icon: ICONS.ecommerce },
  //     { title: "analytics", path: "/clients", icon: ICONS.analytics },
  //     { title: "banking", path: "/projects", icon: ICONS.banking },
  //     { title: "booking", path: "/settings", icon: ICONS.booking },
  //     { title: "booking", path: "/admins", icon: ICONS.booking },
  //     { title: "booking", path: "/profile", icon: ICONS.booking },
  //   ],
  // },
  {
    title: "dashboard",
    path: "/",
    icon: ICONS.dashboard,
  },
  {
    title: "Quotes",
    path: "/quotes",
    icon: ICONS.booking,
  },
  {
    title: "Clients",
    path: "/clients",
  },
  // {
  //   title: 'Create Quote',
  //   path: '/send_a_quote',
  // },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "settings",
    path: "/settings",
  },
  {
    title: "admins",
    path: "/admins",
  },
  {
    title: "profile",
    path: "/profile",
  },
];

export default sidebarConfig;
