import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
// material
import { useTheme } from "@mui/material/styles";
import Label from "src/components/Label";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
import firebase from "../../../firebase";
//
import useGetClientsNew from "../../../hooks/useGetClientsNew";
import AlertWithAction from "src/components/reuse/AlertWithAction";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  // { id: "Status", label: "", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ClientList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [proptDelete, setPromptDelete] = useState(false);
  const [clientId, setClientId] = useState(null);

  const theme = useTheme();
  const groupBy = (key) => (array) =>
    array.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
      }),
      {}
    );
  let clients = groupBy("email");
  let USERLIST = Object.keys(clients(useGetClientsNew().docs));

  const UserList = useGetClientsNew().docs;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [].map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - [].length) : 0;

  const filteredUsers = applySortFilter(
    UserList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // return (
  //   <di>
  //     <h1>Hi</h1>
  //   </di>
  // );

  const handleDelete = () => {
    console.log("function running!");
    firebase
      .firestore()
      .collection("clients")
      .doc(clientId)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        alert("client deleted");
        setPromptDelete(false);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
        alert("delete failed ");
        setPromptDelete(false);
      });
  };
  return (
    <Page title="Users">
      <AlertWithAction
        open={proptDelete}
        setOpen={setPromptDelete}
        title="Delete User"
        description="Deleting a user will remove the user from the system. this action can't be undone!"
        actions={[
          {
            typeAction: "secondary",
            label: "Cancel",
            action: () => {
              setPromptDelete(false);
            },
          },
          {
            typeAction: "primary",
            label: "Delete",
            action: handleDelete,
          },
        ]}
      />
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Clients
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            style={{ marginBottom: 10 }}
          >
            <Link
              to={"/clients/create"}
              style={{
                display: "grid",
                color: "inherit",
                textDecoration: "none",
                justifyContent: "center",
              }}
            >
              Create a client
            </Link>
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, padding: 5 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={UserList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {UserList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row) => {
                    const {
                      id,
                      client,
                      createdAt,
                      firstName,
                      lastName,
                      email,
                      phone,
                      project,
                    } = row;
                    const isItemSelected = selected.indexOf(client) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            // onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell> */}
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar alt={client} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {firstName + " " + lastName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{phone}</TableCell>

                        {/* <TableCell align="left">
                          {client ? "Yes" : "No"}
                        </TableCell>
                        
                        {/* <TableCell align="left">
                          <Label
                            variant={
                              theme.palette.mode === "light"
                                ? "ghost"
                                : "filled"
                            }
                            color={
                              (client === "banned" && "error") || "success"
                            }
                          >
                  
                            {client}
                          </Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <UserMoreMenu
                            onDelete={() => {
                              setPromptDelete(true);
                              setClientId(id);
                            }}
                            userId={client}
                            email={email}
                            userName={client}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={UserList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
