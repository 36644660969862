import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetUser = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        setdocs(doc.data());
      });
  }, []);
  return { docs };
};

export default useGetUser;
