// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
 
import React,{useState, useEffect} from 'react'
 
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const TOTAL = 234;

export default function AppTotal({data}) {
 
 

  return (
    <RootStyle>
 
      <Typography variant="h1">{fShortenNumber(data.length)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
     Overall Total Quotes
      </Typography>
    </RootStyle>
  );
}
