import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// material
import { Stack, Container, Button } from "@mui/material";
// components
import Page from "../../../components/Page";

import firebase from "../../../firebase";
import ProjectTable from "./components/ProjectTable";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProjectList() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjects = () => {
    firebase
      .firestore()
      .collection("projects")
      .onSnapshot((snapshot) => {
        let projects = [];
        snapshot.forEach((doc) => {
          projects.push({ ...doc.data(), id: doc.id });
        });

        // map through the projects and get quotes with project quote id
        projects = projects.map((project) => {
          firebase
            .firestore()
            .collection("quotes")
            .doc(project.quoteId)
            .get()
            .then((doc) => {
              project.quote = doc.data();
            });

          return project;
        });

        // console.log(">>>>> ", projects[0], " check project with quodte");
        setProjects(projects);

        setLoading(false);
      });
  };

  // const handleNavigateProject = (id) => {
  //   navigate(`/projects/view/${id}`);
  // };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Page title="Projects">
      <Container maxWidth>
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            size="large"
            style={{
              marginBottom: 10,
              width: 200,
              // marginRight: "auto",
              marginLeft: "auto",
              justifySelf: "flex-end",
              float: "right",
            }}
          >
            <Link
              to={"/quote/create/"}
              style={{
                display: "grid",
                color: "inherit",
                textDecoration: "none",
                justifyContent: "center",
              }}
            >
              Create a project
            </Link>
          </Button>
        </Stack>

        <ProjectTable projects={projects} loading={loading} />
      </Container>
    </Page>
  );
}
