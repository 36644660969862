import { useState, useEffect } from "react";
import { sanitiseFieldName } from "src/utils/smartUtils";
import firebase from "src/firebase";
import InputLabel from "@mui/material/InputLabel";
import { Typography, Stack, Grid } from "@mui/material";
import { useSnackbar } from "notistack";

export const UploadElectricalFiles = (props) => {
  // console.log(props, "props here");
  const {
    onChange,
    setStageFile,
    disabled,
    required,
    name,
    projectCode,
    mainLabel,
    stageFile,
  } = props;

  // console.log(required, "props required");
  const label = sanitiseFieldName(mainLabel);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (e) => {
    // handle file upload and return the url
    setLoading(true);
    const file = e.target.files[0];

    // get file type
    const fileType = file.type;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${projectCode}/${file.name}`);
    fileRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          // update the database by pushing the url to the database
          firebase
            .firestore()
            .collection("edge_project_data")
            .doc(projectCode)
            .get()
            .then((doc) => {
              const existingDevelopmentStage =
                doc.data()?.development_stage || {};
              console.log(existingDevelopmentStage, "existingDevelopmentStage");
              const spreadExistingIfObject = (existingDevelopmentStage) => {
                if (typeof existingDevelopmentStage === "object") {
                  return { ...existingDevelopmentStage };
                }
                return existingDevelopmentStage;
              };
              const updatedDevelopmentStage = {
                ...spreadExistingIfObject(existingDevelopmentStage),
                electric: {
                  type: "file",
                  fileName: file.name,
                  fileType: fileType,
                  url,
                },
              };
              return firebase
                .firestore()
                .collection("edge_project_data")
                .doc(projectCode)
                .set(
                  { development_stage: updatedDevelopmentStage },
                  { merge: true }
                );
            })
            .then(() => {
              console.log("Data saved successfully");
            })
            .then(() => {
              enqueueSnackbar("File Deleted Successfully", {
                variant: "success",
              });

              setFile(null);
              setLoading(false);
              setFileUrl(null);
            });

          onChange(e);
          setStageFile(url);
          setFileUrl(url);
          setLoading(false);
        });
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const handleDeleteFile = () => {
    // delete the file from the database
    setLoading(true);

    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        const existingDevelopmentStage = doc.data()?.development_stage || {};
        const updatedDevelopmentStage = {
          ...existingDevelopmentStage,
          electric: null,
        };
        return firebase
          .firestore()
          .collection("edge_project_data")
          .doc(projectCode)
          .set({ development_stage: updatedDevelopmentStage }, { merge: true });
      })
      .then(() => {
        // delete file from storage
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(
          `edge_project_data/${projectCode}/development_stage/${file.fileName}`
        );

        return fileRef.delete();
      })
      .then(() => {
        enqueueSnackbar("File Deleted Successfully", {
          variant: "success",
        });
        console.log("file deleted successfully");
        setFile(null);
        setLoading(false);
        setFileUrl(null);
        console.log("Data saved successfully");
      });

    setFileUrl(null);
  };

  useEffect(() => {
    // check if there is a file in the database for this project
    // if there is, then get the url and set it to the fileUrl state
    // else set the fileUrl state to null
    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          // console.log(data, "data here for sample");
          const file = data?.development_stage?.electric;
          if (file) {
            setFile(file);
          } else {
            setFileUrl(null);
          }
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      });
  }, [projectCode, name, label]);

  if (loading) {
    return <p>Processing...</p>;
  }

  if (file) {
    // console.log({ file }, "file here after upload");
    return (
      <FilePreview
        file={file}
        disabled={disabled}
        mainLabel={mainLabel}
        onDelete={() => {
          // onChange(null);
          handleDeleteFile();
        }}
      />
    );
  }
  return (
    <Grid>
      <Stack>
        <Typography variant="body1" gutterBottom>
          {mainLabel}
        </Typography>
        {required && (
          <InputLabel id="demo-simple-select-label">
            <Typography variant="body2" gutterBottom>
              Required *
            </Typography>
          </InputLabel>
        )}
      </Stack>

      <Stack>
        <input
          // disabled={disabled}
          type="file"
          name={sanitiseFieldName(name)}
          disabled={disabled}
          onChange={(e) => {
            onChange(e);
            // handleFileUpload(e);
          }}
        />
      </Stack>
    </Grid>
  );
};

const FilePreview = (props) => {
  // console.log(props, "props here is");
  return (
    <div className="file-preview">
      <div className="file-preview__icon">
        <i className="fas fa-file"></i>
      </div>
      <Typography variant="body2" gutterBottom>
        {props.mainLabel || "File"}
      </Typography>
      <div className="file-preview__text">
        <a href={props?.file?.url} target="_blank" rel="noreferrer">
          {props?.file?.fileName}
        </a>
        {/* <p className="file-preview__text--title">{props?.file?.fileName}</p> */}

        {/* <p className="file-preview__text--subtitle">{props.file?.size} bytes</p> */}
      </div>

      <div className="file-preview__actions">
        {props.disabled ? null : (
          <button
            className="file-preview__actions--btn"
            onClick={() => {
              props.onDelete();
            }}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
};
