import { useState, useRef, useEffect } from "react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { sanitiseFieldName } from "src/utils/smartUtils";
import firebase from "src/firebase";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export function ChangeAdminType({ open, setOpen, admin }) {
  const [scroll, setScroll] = useState("paper");
  const [adminType, setAdminType] = useState(admin?.type);
  const [selectionTriggered, setSelectionTriggered] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);

  const handleChangeAdminType = (newType) => {
    setAdminType(newType);
    setSelectionTriggered(true);
  };

  const handleSave = () => {
    setLoading(true);
    firebase
      .firestore()
      .collection("users")
      .doc(admin?.id)
      .update({
        type: adminType,
      })
      .then(() => {
        setLoading(false);
        setOpen(false);
        window.location.reload();
        console.log("Admin type changed successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error changing admin type", err);
      });
  };

  useEffect(() => {
    if (admin) {
      setAdminType(admin?.type);
    }
  }, [admin]);

  return (
    <Dialog open={open} onClose={handleClose} scroll={scroll}>
      <DialogTitle sx={{ pb: 2 }}>
        Change admin type
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Current admin type for{" "}
          <spam style={{ color: "#111", fontWeight: "bold" }}>
            {admin?.email}
          </spam>
          :
        </Typography>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"} sx={{ minWidth: 512 }}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {/* <Typography variant="subtitle2">
            
          </Typography> */}
          <Typography>
            User has is currently {admin?.type === "root" ? "a" : "an"}{" "}
            <spam style={{ color: "#111", fontWeight: "bold" }}>
              {admin?.type === "root"
                ? "Super Admin"
                : admin?.type === "auditor"
                ? "Auditor"
                : "Admin"}
            </spam>
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 3 }}>
            Select admin type: {adminType}
          </Typography>
          <Button
            variant={adminType === "auditor" ? "contained" : "outlined"}
            disabled={adminType === "auditor"}
            sx={{ mr: 2, mt: 2 }}
            onClick={() => handleChangeAdminType("auditor")}
          >
            Auditor
          </Button>
          <Button
            variant={adminType === "admin" ? "contained" : "outlined"}
            disabled={adminType === "admin"}
            sx={{ mr: 2, mt: 2 }}
            onClick={() => handleChangeAdminType("admin")}
          >
            Admin
          </Button>
          <Button
            variant={adminType === "root" ? "contained" : "outlined"}
            disabled={adminType === "root"}
            sx={{ mt: 2 }}
            onClick={() => handleChangeAdminType("root")}
          >
            Super admin
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={!selectionTriggered}
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

// import { useState, useRef, useEffect } from "react";
// // material
// import {
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   DialogContentText,
//   Typography,
//   TextField,
//   TextareaAutosize,
// } from "@mui/material";
// import { sanitiseFieldName } from "src/utils/smartUtils";
// import firebase from "src/firebase";

// // ----------------------------------------------------------------------

// export function ChangeAdminType({ open, setOpen, email }) {
//   const [scroll, setScroll] = useState("paper");

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const descriptionElementRef = useRef(null);

//   const handleChangeAdminType = () => {
//     console.log("check check", email);
//   };
//   return (
//     <Dialog open={open} onClose={handleClose} scroll={scroll}>
//       <DialogTitle sx={{ pb: 2 }}>
//         Change admin type
//         <Typography variant="body2" sx={{ color: "text.secondary" }}>
//           Standard admin or Super admin
//         </Typography>
//       </DialogTitle>
//       <DialogContent dividers={scroll === "paper"} sx={{ minWidth: 512 }}>
//         <DialogContentText
//           id="scroll-dialog-description"
//           ref={descriptionElementRef}
//           tabIndex={-1}
//         ></DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>

//         <Button
//           variant="contained"
//           onClick={() => {
//             console.log("check check");
//           }}
//         >
//           Change
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }
