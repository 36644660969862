// create a code generate function that will generete a code with a given length and a given set of characters to prepend to the code
//
// the function will take in two parameters, the length of the code and the characters to prepend to the code
//
// the function will return a string of the generated code
import { nanoid } from "nanoid";

export const codeGenerate = (length, prepend) => {
  // get current year last two digits
  const year = new Date().getFullYear().toString().slice(-2);
  // get current month
  const month = new Date().getMonth() + 1;

  // generate 2 random characters either a number or a letter only
  const nanoid = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  return `${prepend}${year}-${month}-${nanoid(2)}`;
};
