import { useState, useEffect } from "react";
// material
import {
  Container,
  Box,
  Typography,
  Button,
  Stack,
  Card,
  LinearProgress,
} from "@mui/material";
// routes
import { Link, useNavigate, useLocation } from "react-router-dom";

// import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
// import useSettings from "../../hooks/useSettings";
// components
import firebase from "../../firebase";

import Page from "../../components/Page";
// import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// import { BlogNewPostForm } from "../../components/_dashboard/blog";

import TeamDialogue from "./Forms/InitialSetupForm/PopDialogue";

import { PROJECT_STATUS } from "src/config/constants";
import { InitialAssessmentFormContainer } from "../pages/projects/components/InitialAssessmentDisplayer";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------

export function InitialAssessmentForm() {
  // const { themeStretch } = useSettings();
  // get the project id from the url

  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [progress, setprogress] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [quote_id, setQuoteId] = useState("");
  const [hasInitialSubmission, setHasInitialSubmission] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [approvedCount, setApprovedCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [professionals, setProfessionals] = useState([]);
  const [projectId, setProjectId] = useState();
  const [hasAtleastOneProfessional, setHasAtleastOneProfessional] =
    useState(false);

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [submitedResponses, setSubmitedResponses] = useState([]);
  const [submissionsLoading, setSubmissionsLoading] = useState(true);

  const projectCode = location.pathname.split("/")[4];
  const handlSetOpenDialogue = () => {
    // prevent flashing dia

    setOpenDialogue(false);
  };

  const setEdgeCollectionDataToComplete = (projectCode) => {
    // prompt user to comfirm the action
    const confirm = window.confirm(
      "Are you sure you want to complete this project?"
    );
    if (!confirm) {
      return;
    }

    // set the project to complete
    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          const id = doc.id;
          // set the project to complete
          firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .update({
              status: PROJECT_STATUS.CLIENT_APPROVED,
            })
            .then(() => {
              // redirect to the dashboard
              navigate("/client/dashboard");
            });
        });
      });
  };

  const handleClientSignOut = () => {
    // delete local storage
    localStorage.removeItem("externalAuth");
    // redirect to login page
    navigate("/authenticate/client");
  };
  useEffect(() => {
    // get the project data from the database
    // set the project data to the state

    setprogress(true);

    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          setProject(data);
          setProfessionals(data?.professionals);
          setQuoteId(data.quoteId);
          // if has atleast one professional
          if (data?.professionals?.length > 0) {
            setHasAtleastOneProfessional(true);
          } else {
            setHasAtleastOneProfessional(false);
            // setOpenDialogue(true);
          }
          setProjectId(doc.id);

          firebase
            .firestore()
            .collection("quotes")
            .doc(data.quoteId)
            .get()
            .then((doc) => {
              const data = doc.data();
              setQuote(data);
              setprogress(false);
              firebase
                .firestore()
                .collection("edge_project_data")
                .doc(projectCode)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    setHasInitialSubmission(true);
                  } else {
                    setHasInitialSubmission(false);
                  }
                });
            });
        });
      });
  }, [projectCode]);

  // useEffect(() => {
  //   firebase
  //     .firestore()
  //     .collection("edge_project_data")
  //     .doc(projectCode)
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         const data = doc.data();
  //         setSubmitedResponses(data);
  //         setSubmissionsLoading(false);
  //         const approvedCount = Object.keys(data).filter((item) => {
  //           return data[item].status === "approved";
  //         }).length;

  //         const submittedCount = Object.keys(data).filter((item) => {
  //           return data[item].status === "submitted";
  //         }).length;

  //         setApprovedCount(approvedCount);
  //         setSubmittedCount(submittedCount);
  //       } else {
  //         console.log("no data");
  //       }
  //     });
  // }, [projectCode]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setSubmitedResponses(data);
          setSubmissionsLoading(false);
          const approvedCount = Object.keys(data).filter((item) => {
            return data[item].status === "approved";
          }).length;

          const submittedCount = Object.keys(data).filter((item) => {
            return data[item].status === "submitted";
          }).length;

          setApprovedCount(approvedCount);
          setSubmittedCount(submittedCount);
        } else {
          console.log("no data");
        }
      });

    // unsubscribe on component unmount
    return unsubscribe;
  }, [projectCode]);

  return (
    <Page title={`${quote?.project} | Initial submission portal`}>
      <TeamDialogue
        open={openDialogue}
        setOpen={handlSetOpenDialogue}
        users={professionals}
        setUsers={setProfessionals}
        projectCode={projectCode}
        projectId={projectId}
      />
      <Container style={{ paddingBottom: 100 }}>
        <Button
          color="primary"
          size="medium"
          variant="text"
          onClick={() => navigate(`/client/dashboard/${projectId}`)}
        >
          Go to project Dashboard
        </Button>
        <Box
          sx={{
            marginTop: "50px",

            display: "flex",
            justifyContent: "flex-end",
            marginRight: "auto",
          }}
        >
          <Box
            sx={{
              textAlign: "right",
              marginTop: "50px",
              width: 240,

              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={setOpenDialogue}
            >
              Working team
            </Button>
            <Button variant="outlined" onClick={handleClientSignOut}>
              Sign out
            </Button>
          </Box>
        </Box>
        <img
          src="/static/SolidGreen-Logo.svg"
          alt="login"
          style={{ width: 128, marginBottom: 20 }}
        />
        {progress ? (
          <LinearProgress color="inherit" />
        ) : (
          <>
            <Stack spacing={1}>
              <Typography variant="h4" gutterBottom>
                {`${quote?.project}`}
              </Typography>

              <Typography variant="subtitle2" gutterBottom>
                This is the data submission portal for your project. You can
                submit data here.
              </Typography>

              {/* float action to sign out button to right */}
            </Stack>

            {/* <Grid container spacing={3}>
          {Categories.map((category) => (
            <CategoryStats {...category} />
          ))}
        </Grid> */}

            {/* <SubmissionStepper
              projectId={projectId}
              projectCode={project?.code}
              readOnly={false}
            /> */}
          </>
        )}
        <InitialAssessmentFormContainer
          client={true}
          projectCode={project?.code}
        />
      </Container>
    </Page>
  );
}
