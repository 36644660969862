import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Label from "src/components/Label";
import { CERTIFICATION_STATUS, PROJECT_STATUS } from "src/config/constants";
import firebase from "src/firebase";

export const Certification = ({ projectCode, setOpen, fromClient = false }) => {
  const [project, setProject] = useState(null);
  const [progress, setprogress] = useState(false);

  useEffect(() => {
    // get status from firebase certification collections and set it to the project
    const unsubscribe = firebase
      .firestore()
      .collection("Certification")
      .doc(projectCode)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            setProject(doc.data());
          } else {
            setProject({
              status: CERTIFICATION_STATUS.NOT_STARTED,
            });
          }
        }
        // .catch((error) => {
        //   console.log("Error getting document:", error);
        // })
      );

    return () => unsubscribe();
  }, [projectCode]);

  console.log(project?.status);

  return (
    <Stack
      sx={{
        width: "auto",
        minWidth: "300px",
        py: 1,
        px: 3,
        bgcolor:
          project?.status === CERTIFICATION_STATUS.NOT_STARTED
            ? null
            : "background.paper",
        borderRadius: 1,
        boxShadow: project?.status === CERTIFICATION_STATUS.NOT_STARTED ? 0 : 1,
        border: project?.status === CERTIFICATION_STATUS.NOT_STARTED ? 0 : 1,
        borderColor:
          project?.status === CERTIFICATION_STATUS.GET_STARTED
            ? "success.main"
            : project?.status === CERTIFICATION_STATUS.COMPLETED
            ? "success.main"
            : "info.main",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mr: 2,
          color:
            project?.status === CERTIFICATION_STATUS.NOT_STARTED
              ? "gray"
              : project?.status === CERTIFICATION_STATUS.GET_STARTED
              ? "warning.main"
              : "success.main",
        }}
      >
        Preliminary certification
      </Typography>

      <LoadingButton
        disabled={project?.status === CERTIFICATION_STATUS.NOT_STARTED}
        color={
          project?.status === CERTIFICATION_STATUS.COMPLETED
            ? "success"
            : project?.status === CERTIFICATION_STATUS.GET_STARTED
            ? "warning"
            : "info"
        }
        size="small"
        loading={progress}
        variant="outlined"
        onClick={() => {
          if (!fromClient) {
            console.log("projectCode", projectCode);
            setOpen(true);
          }
        }}
      >
        {project?.status === CERTIFICATION_STATUS.COMPLETED
          ? "Completed"
          : project?.status === CERTIFICATION_STATUS.GET_STARTED
          ? fromClient
            ? "In Progress"
            : "Continue"
          : "Not Started"}
      </LoadingButton>
    </Stack>
  );
};
