import React from 'react'
import useGetQuote from '../../hooks/useGetQuote'


function QuoteView({data}) {

  const user = useGetQuote(data).docs

  return (
    <>{user && user.project}</>
  )
}

export default QuoteView