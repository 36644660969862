import { useState, useEffect } from "react";
// material
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  Card,
} from "@mui/material";
// routes
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
} from "src/config/constants";
import emailjs from "@emailjs/browser";

// import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
// import useSettings from "../../hooks/useSettings";
// components
import firebase from "../../../firebase";

import Page from "../../../components/Page";
import { useSnackbar } from "notistack";
// import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// import { BlogNewPostForm } from "../../components/_dashboard/blog";
import {
  SubmissionStepper,
  calculateProgress,
} from "src/pages/external/stepper";
import LoadingScreen from "src/components/LoadingScreen";
import TeamDialogue from "src/pages/external/Forms/InitialSetupForm/PopDialogue";
import { totalQuestions } from "src/pages/mapperFuncs/questions";
import Label from "src/components/Label";
import { Email } from "@mui/icons-material";
// ----------------------------------------------------------------------

let publicKey = "WffsPR6cSS6IwClRB";
export default function ProjectSubmittedData() {
  // const { themeStretch } = useSettings();
  // get the project id from the url

  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [progress, setprogress] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [quote_id, setQuoteId] = useState("");
  const [hasInitialSubmission, setHasInitialSubmission] = useState(true);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [projectId, setProjectId] = useState();
  const [approvedCount, setApprovedCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [readOnly, setReadOnly] = useState(true);
  const [adminCanSubmit, setAdminCanSubmit] = useState(false);
  const [ownerCanVerify, setOwneranVerify] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [answeredQuestions, setAnsweredQuestions] = useState(0);

  const [emailLoading, setEmailLoading] = useState(false);
  const location = useLocation();
  const projectCode = location.pathname.split("/")[3];
  const setEdgeCollectionDataToComplete = (projectCode) => {
    // prompt user to comfirm the action
    const confirm = window.confirm(
      "Are you sure you want to complete this project?"
    );
    if (!confirm) {
      return;
    }

    // set the project to complete
    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          const id = doc.id;
          // set the project to complete
          firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .update({
              status: PROJECT_STATUS.CLIENT_APPROVED,
            })
            .then(() => {
              firebase
                .firestore()
                .collection("PrelimDataForms")
                .doc(projectCode)
                .update({
                  status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
                })
                .then(() => {
                  console.log("updated");
                  enqueueSnackbar("Rfi completed", {
                    variant: "success",
                  });
                });
              // redirect to the dashboard
            });
        });
      });
  };
  useEffect(() => {
    // get the project data from the database
    // set the project data to the state

    setprogress(true);

    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();

          const id = doc.id;

          setProjectId(id);
          setProject(data);
          setQuoteId(data.quoteId);

          firebase
            .firestore()
            .collection("quotes")
            .doc(data.quoteId)
            .get()
            .then(
              (doc) => {
                const data = doc.data();
                console.log(data, "umbrella");
                setProjectType(data?.type);
                if (data?.development_stage?.allFilesUploaded) {
                  setAdminCanSubmit(true);
                }

                setProfessionals(data.professionals);
                setQuote(data);
                setprogress(false);
              }
              // .catch((error) => {
              //   console.log("Error getting document:", error);
              // });
            );
        });
      });
  }, [projectCode]);

  useEffect(() => {
    // count how many submissions have been approved for this project using the edge_project_data collection
    // if the count is 0, then set the hasInitialSubmission to false
    // if the count is greater than 0, then set the hasInitialSubmission to true
    // this will be used to determine whether to show the stepper or not

    const docRef = firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode);

    const unsubscribe = docRef.onSnapshot(
      (doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data, "check for data however");
          if (data?.development_stage?.allFilesUploaded) {
            setAdminCanSubmit(true);
          }

          if (data?.development_stage?.stage === "Concept") {
            setOwneranVerify(true);
          }
          console.log(data, "data");
          const approvedCount = Object.keys(data).filter((item) => {
            return data[item].status === "approved";
          }).length;

          const submittedCountQ = Object.keys(data).filter((item) => {
            return data[item].status === "submitted";
          }).length;
          setApprovedCount(approvedCount);
          setSubmittedCount(submittedCountQ);

          const answeredQuestions = Object.keys(data).length;
          setAnsweredQuestions(answeredQuestions);
        }
      },
      (error) => {
        console.log("Error getting document:", error);
      }
    );

    return () => unsubscribe();
  }, [projectCode, submittedCount, approvedCount]);

  const sendFormToClient = () => {
    setEmailLoading(true);

    // send an email to the client to notify them that the project has been completed
    const message = `Your project submission for ${quote?.project} has been a 
      approved. Please click on the link below to view the project and confirm that it is complete. Use the following login credential, email: ${project?.email} and code: ${projectCode}
      ${window.location.origin}/authenticate/client`;

    const subject = `Project completed - ${quote?.project}}`;
    const email = project?.email;
    console.log(email, "email");
    emailjs
      .send(
        "service_6a716og",
        "template_56brr6e",
        {
          message: message,
          subject,
          reply_to: "wardah@solidgreen.co.za",
          to_email: email,
        },
        publicKey
      )
      .then((res) => {
        enqueueSnackbar(`Email sent to client to ${email}`, {
          variant: "success",
        });
        setEmailLoading(false);
        // reload the page after 3 seconds

        console.log("res", res);
      })
      .catch((err) => {
        enqueueSnackbar("Email not sent", {
          variant: "error",
        });
        setEmailLoading(false);

        console.log("err", err);
      });
  };

  console.log(project, "project");
  return (
    <Page
      sx={{
        overflow: "hidden",
        position: "relative",
      }}
      title={`${quote?.project} | Data submission portal`}
    >
      <TeamDialogue
        open={openDialogue}
        setOpen={setOpenDialogue}
        users={professionals}
        setUsers={setProfessionals}
        projectCode={projectCode}
        projectId={projectId}
        fromAdmin={true}
      />

      {progress && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      )}

      <Stack
        sx={{
          position: "fixed",
          left: "16%",
          width: "80%",
          // ml: "4%",
          // mr: "4%",
          right: 0,
          top: 5,
          borderBottom: "1px solid #e0e0e0",
          zIndex: 50,
          margin: "auto",
          bgcolor: "background.default",
        }}
      >
        <Container>
          <Stack
            sx={{
              mt: 2,
              mb: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{}}>
              <Typography variant="h4">{quote?.project}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mr: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Answered :
                </Typography>
                <Label
                  color="default"
                  sx={{
                    padding: 2,
                    ml: 1,
                  }}
                >
                  {calculateProgress(answeredQuestions, totalQuestions)}
                </Label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  ml: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Approved :{" "}
                </Typography>
                <Label
                  color="default"
                  sx={{
                    padding: 2,
                    ml: 1,
                  }}
                >
                  {calculateProgress(approvedCount, totalQuestions - 2)}
                </Label>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="primary"
                onClick={setOpenDialogue}
              >
                professionals
              </Button>

              {ownerCanVerify &&
              project?.status !== PROJECT_STATUS.CLIENT_APPROVED ? (
                <LoadingButton
                  loading={emailLoading}
                  disabled={approvedCount >= totalQuestions - 3 ? false : true}
                  variant="outlined"
                  onClick={() => {
                    sendFormToClient();
                  }}
                >
                  Send to client
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={emailLoading}
                  disabled
                  variant="outlined"
                  onClick={() => {
                    sendFormToClient();
                  }}
                >
                  Client approved
                </LoadingButton>
              )}
            </Box>
          </Stack>
        </Container>
      </Stack>

      <Container
        sx={{
          position: "relative",
        }}
      >
        {/* back button */}
        <Stack
          sx={{
            mt: -4,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "fixed",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={`/projects/view/${projectId}`}
          >
            Back
          </Button>
        </Stack>
        <Stack>
          <SubmissionStepper
            projectType={projectType}
            projectCode={project?.code}
            isAdmin={true}
            adminCanSubmit={adminCanSubmit}
          />
        </Stack>
      </Container>
    </Page>
  );
}
