import { useState, useEffect } from "react";
import firebase from "../firebase";

// [TODO: restructure this to filter only users with admin role]
const useGetAdmins = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("users")
      .get()
      .then((doc) => {
        setdocs(doc.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    return unsub;
  }, []);
  return { docs };
};

export default useGetAdmins;
