import { useState, useRef, useEffect } from "react";
// material
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { sanitiseFieldName } from "src/utils/smartUtils";
import firebase from "src/firebase";
import FilePreviewer from "../FilePreviewer";

// ----------------------------------------------------------------------
const whatToRemoveFromDialogueDataValue = [
  "approved",
  "approved_by",
  "approved_on",
  "status",
  "updated_on",
  "comments",
  "type",
];

export function InitialAssessmentOverlay({
  open,
  setOpen,
  dialogueData,
  label,
}) {
  // return dialogueData;
  const [scroll, setScroll] = useState("paper");
  const [value, setValue] = useState(null);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  // const [objectValueWithData, setObjectValueWithData] = useState({});

  // const removeUnwantedFields = (data) => {
  //   const newData = { ...data };
  //   whatToRemoveFromDialogueDataValue.forEach((field) => {
  //     delete newData[field];
  //   });
  //   return newData;
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (value, fieldName, isFile, projectCode) => {
    const sanitisedFieldName = sanitiseFieldName(fieldName);
    firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(projectCode)
      .set(
        {
          [sanitisedFieldName]: {
            ...value,
            approved: true,
            approved_by: "admin",
            approved_on: new Date(),
            status: "approved",
            updated_on: new Date(),
          },
        },
        { merge: true }
      )
      .then(() => {
        setOpen(false);
        console.log("Data saved successfully");
      });
  };
  const handleRequestChanges = (value, fieldName, isFile, projectCode) => {
    if (comment === "") {
      alert("Please add a comment");
      return;
    }
    const sanitisedFieldName = sanitiseFieldName(fieldName);
    firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")

      .doc(projectCode)
      .set(
        {
          [sanitisedFieldName]: {
            ...value,
            approved: false,
            approved_by: null,
            approved_on: null,
            comments: comment,
            status: "rejected",
            updated_on: new Date(),
          },
        },
        { merge: true }
      )
      .then(() => {
        // update the status of
        console.log("Data saved successfully");
        setOpen(false);
      });
  };

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      console.log("dialogueData", dialogueData, "<<<<");

      // const val = dialogueData;
      // console.log("val", val, "<<<<");
      // setValue(val);

      // // get object values from val
      // const objectValue = Object.values(val);
      // // const objectKey = Object.keys(val);

      // setObjectValueWithData(objectValue);

      // if (dialogueData?.type === "file") {
      //   // remove the unwanted fields from the value
      //   // const newValue = removeUnwantedFields(dialogueData?.value);
      //   // reduce new value to array of files
      //   // const files = Object.values(newValue);
      //   // setFiles(files);
      // }
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} scroll={scroll}>
      <DialogTitle sx={{ pb: 2 }}>
        {dialogueData?.title}
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {dialogueData?.description}
        </Typography>
      </DialogTitle>
      <DialogContent dividers={scroll === "paper"} sx={{ minWidth: 512 }}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {dialogueData?.type === "file" && <FilePreviewer files={files} />}

          <Typography
            variant="h4"
            // sx={{ color: "text.secondary", textDecorationLine: "underline" }}
          >
            {/* <span style={{ fontWeight: "bold" }}>response: </span> */}
            {/* {value?.[sanitiseFieldName(label)]} */}

            {/* {objectValueWithData?.map((item, index) => {
              return (
                <div key={index}>
                  <span style={{ fontWeight: "bold" }}>response: </span>
                  {item}
                </div>
              );
            })} */}
            {/* Javascript loop through an object and return all values */}
            {value &&
              Object?.entries(value)?.map(([key, value], index) => {
                if (key === "type") {
                  return null; // Skip rendering the key with type
                }
                return (
                  <div key={index}>
                    <span style={{ fontWeight: "bold" }}>{key}: </span>
                    {value}
                  </div>
                );
              })}
          </Typography>
        </DialogContentText>
        <section style={{ display: "flex", marginTop: 50 }}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Comment"
          />
          <br />
          <Button
            variant="contained"
            color="warning"
            size="small"
            onClick={() => {
              handleRequestChanges(
                dialogueData?.value,
                label,
                false,
                dialogueData?.projectCode
              );
            }}
          >
            Request changes
          </Button>
        </section>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>

        <Button
          variant="contained"
          onClick={() => {
            handleApprove(
              dialogueData?.value,
              label,
              false,
              dialogueData?.projectCode
            );
          }}
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}
