export const QUOTE_STATUS = {
  PENDING_CLIENT_ACCEPTANCE: "PENDING_CLIENT_ACCEPTANCE",
  CLIENT_ACCEPTED: "CLIENT_ACCEPTED",
  CLIENT_REJECTED: "CLIENT_REJECTED",
  PENDING_ADMIN_ACCEPTANCE: "PENDING_ADMIN_ACCEPTANCE",
  ADMIN_ACCEPTED: "ADMIN_ACCEPTED",
  ADMIN_REJECTED: "ADMIN_REJECTED",
  PENDING_PAYMENT: "PENDING_PAYMENT",
  PAID: "PAID",
  INVOICED: "INVOICED",
};

export const PROJECT_STATUS = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  WAITING_VERIFICATION: "WAITING_VERIFICATION",
  REQUESTED_REVISION: "REQUESTED_REVISION",
  REVISION_IN_PROGRESS: "REVISION_IN_PROGRESS",
  REVISION_COMPLETED: "REVISION_COMPLETED",
  CLIENT_APPROVED: "CLIENT_APPROVED",
  ROUND1_SUBMITTED: "ROUND1_SUBMITTED",
};

export const INITIAL_ASSESSEMENT_STATUS = {
  GET_STARTED: "GET_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  NOT_STARTED: "NOT_STARTED",
  PENDING_AUDIT: "PENDING_AUDIT",
  CLIENT_ACCEPTED: "CLIENT_ACCEPTED",
  PENDING_CLIENT_ACCEPTANCE: "PENDING_CLIENT_ACCEPTANCE",
};

export const ROUND1_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  GET_STARTED: "GET_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  NOT_CONFIRMED: "NOT_CONFIRMED",
};

export const CERTIFICATION_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  GET_STARTED: "GET_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};
