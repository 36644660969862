import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetQuoteLogs = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("logs")
      .where("item_id", "==", id)
      .onSnapshot((doc) => {
        const items = [];
        doc.forEach((item) => {
          items.push({ ...item.data(), id: item.id });
        });
        setdocs(items);
      });
  }, []);
  return { docs };
};

export default useGetQuoteLogs;
