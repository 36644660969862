import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
export const InfoCols1 = ({ func, firstDatasetInRibbon }) => {
  // console.log(firstDatasetInRibbon, "firstDatasetInRibbon");
  const { client, payment, currentDuration } = firstDatasetInRibbon;

  console.log(firstDatasetInRibbon, "firstDatasetInRibbon");
  // return null;
  const data = [
    { key: "Client Name", value: client },
    { key: "Payment", value: payment || "Pending" },
    { key: "Current Duration", value: currentDuration || "0 Months" },
  ];
  return (
    <Grid container mb={12} marginBottom={1} alignItems="center">
      {/* 3 columns */}

      {data.map((item) => Col(item))}
      <ColFunc
        label="Working Team"
        func={(x) => {
          func(true);
        }}
      />
    </Grid>
  );
};

export const InfoCols2 = ({ func, linkTo, secondDatasetInRibbon }) => {
  const { gfa, buildingType, performance_modeling } = secondDatasetInRibbon;
  const data = [
    { key: "Gross Floor Area", value: `${gfa}m2` },
    { key: "Building Type", value: buildingType || "Not Required" },
    { key: "Perfomance Modeling", value: performance_modeling ? "Yes" : "No" },
  ];
  return (
    <Grid container mb={12} marginBottom={1} alignItems="center">
      {/* 3 columns */}
      {data.map((item) => Col(item))}
      <ColFunc
        linkTo={linkTo}
        label="Quotation"
        func={(x) => {
          func();
        }}
      />
    </Grid>
  );
};

const Col = ({ key, value }) => (
  <Grid item xs={3}>
    <Typography variant="subtitle1" gutterBottom>
      {key}
    </Typography>

    <Typography variant="h6" gutterBottom color="#fff">
      {value}
    </Typography>
  </Grid>
);

const ColFunc = ({ label, func, linkTo }) => {
  console.log(linkTo, "linkTo");
  return (
    <Grid item xs={3}>
      {linkTo ? (
        <Button
          type="submit"
          variant="outlined"
          size="small"
          color="primary"
          style={{
            marginBottom: 10,
            backgroundColor: "#fff",
            width: "80%",
            height: 50,
          }}
          component={Link}
          to={`/quote/${linkTo}`}
        >
          {label}
        </Button>
      ) : (
        <Button
          type="submit"
          variant="outlined"
          size="small"
          color="primary"
          style={{
            marginBottom: 10,
            backgroundColor: "#fff",
            width: "80%",
            height: 50,
          }}
          onClick={() => {
            func(label);
          }}
        >
          {label}
        </Button>
      )}
    </Grid>
  );
};
