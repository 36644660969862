import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Container,
  Button,
  Typography,
} from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { ClientAuthForm } from "./Forms/ClientAuthForm";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AuthenticateClient() {
  return (
    <RootStyle title="Login">
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <nav
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
            padding: 20,
          }}
        >
          <Link href="/" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: 20 }}
            >
              Back
            </Button>
          </Link>
        </nav>
      </div>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <img
              src="/static/SolidGreen-Logo.svg"
              alt="login"
              style={{ width: 128, marginBottom: 20 }}
            />

            <Typography variant="h4" gutterBottom>
              Sign in to your project portal
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter the client and project Id provided by Solid Edge. check your
              email for the code.
            </Typography>
          </Stack>
          {/* <LoginForm /> */}
          <ClientAuthForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
