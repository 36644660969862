import { useEffect, useState } from "react";
// material
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
// components
import Scrollbar from "../../../../components/Scrollbar";
import Label from "../../../../components/Label";
import { Container } from "@mui/system";
import { TextField, Autocomplete, Stack } from "@mui/material";
import firebase from "../../../../firebase";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export default function BasicTable({
  members,
  setMembers,
  handleRemoveMember,
  driveUrl,
  setDriveUrl,
}) {
  const [options, setOptions] = useState([]);

  const [adminUsers, setAdminUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filteredOptions = adminUsers.filter(
      (option) => !members.includes(option)
    );
    setOptions(filteredOptions);
  }, [members]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("users")
      .onSnapshot((snapshot) => {
        let users = [];
        snapshot.forEach((doc) => {
          users.push({ ...doc.data(), id: doc.id });
        });

        const filteredOptions = users.filter((option) =>
          members.every((selectedUser) => selectedUser.email !== option.email)
        );
        setOptions(filteredOptions);
        setAdminUsers(users);
        setIsLoading(false);
        console.log(users);
      });
    return () => unsub();
  }, []);

  if (isLoading) {
    return <LinearProgress color="inherit" />;
  }
  return (
    <Scrollbar>
      {/* Grid with 2 columns */}
      <br />
      <Grid container alignItems="center">
        {/* First column */}
        <Grid container xs={12} md={6} justifyContent="flex-start">
          <Grid item xs={12} md={10}>
            {options.length > 0 ? (
              <Autocomplete
                fullWidth
                options={options}
                getOptionLabel={(option) => option.email}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setMembers([...members, newValue]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Pick member" margin="dense" />
                )}
              />
            ) : (
              <Typography variant="body2" color="text.secondary">
                No admin users found. Add admins to the system to add members to
              </Typography>
            )}
          </Grid>
        </Grid>

        {/* Second Column */}
        <Grid container xs={12} md={6} justifyContent="flex-end">
          <Grid item xs={12} md={10}>
            <TextField
              fullWidth
              label="Provide google drive link to the folder"
              value={driveUrl}
              onChange={(e) => {
                setDriveUrl(e.target.value);
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Provide google drive link to the folder"
            />
          </Grid> */}
        </Grid>
      </Grid>

      {members.length > 0 ? (
        <TableContainer sx={{ minWidth: 800, mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members?.map((row) => (
                <TableRow key={row.email}>
                  <TableCell component="th" scope="row">
                    {row.firstName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.lastName}
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">
                    {row.status || (
                      <Label
                        color="error"
                        onClick={() => {
                          handleRemoveMember(row.email);
                        }}
                      >
                        Remove
                      </Label>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack
          container
          width={400}
          marginLeft="auto"
          marginRight="auto"
          marginTop={20}
          textAlign="center"
        >
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: "text.secondary" }}
          >
            No members added yet
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            If no members are added, the project will be open for all employees.
          </Typography>
        </Stack>
      )}
    </Scrollbar>
  );
}
