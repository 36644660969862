export const industrialAreaBreakdown = [
  {
    label: "Office space",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Receiving area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Shipping area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Production area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Inventory area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Mechanical and ",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Electrical Room",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Kitchen and Food ",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Preparation",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Indoor Car Parking",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Cold Storage",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Kitchennette",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Inventory Control",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Storage",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Frozen Storage",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Fruits and Vegetable",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Storage",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Packaging",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Restrooms",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Area with exterior",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "lighting",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "External car park area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Irrigated area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Swimming pool",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
];
export const officeAreaBreakdown = [
  {
    label: "Open plan office",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Private / Closed office",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Corridor",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Conference",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Data centre",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Lobby",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Kitchen and food preparation",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Bathrooms",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Indoor car parking",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "M and E rooms, store",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Area eith external lighting",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "External car parking area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
  {
    label: "Irrigated area",

    inputsExpected: [{ label: "Total area", type: "text" }],
    helperText: "",
  },
];

export const architect = [
  {
    label: "WWR",

    inputsExpected: [
      { label: "Dimensioned drawings", type: "file" },
      { label: "Floor plans", type: "file" },
      { label: "Elevations", type: "file" },
      { label: "Window schedules", type: "file" },
    ],
    helperText:
      "Upload Dimensioned drawings, floor plans, elevations, window swchedules",
    // onChange: handleSave,
  },
  {
    label: "Roof Paint",

    inputsExpected: [
      {
        label: "Roof Paint",
        type: "text",
      },
    ],
    helperText: "What colour will the roof be?",
    // onChange: handleSave,
    // hints: "Please provide the RAL number if possible",
  },
  {
    label: "External Shading",
    radio: ["Yes", "No"],
    inputsExpected: [
      // {
      //   label: "External Shading",
      //   type: "text",
      // },
    ],
    helperText: "",
    // onChange: handleSave,
  },
  {
    label: "Insulation of roof",
    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Material",
        type: "text",
      },
      {
        label: "Thickness",
        type: "text",
      },
    ],
    helperText:
      "Is there any roof insulation? If present, please indicate material and thickness",
    // onChange: handleSave,
  },
  {
    label: "Insulation of ground/raised floor slab",

    inputsExpected: [
      {
        label: "Material",
        type: "text",
      },
      {
        label: "Thickness",
        type: "text",
      },
    ],
    radio: ["Yes", "No"],
    helperText:
      "Is there any ground insulation? If present, please indicate material and thickness",
  },
  {
    label: "Insulation of exterior wall",

    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Material",
        type: "text",
      },
      {
        label: "Thickness",
        type: "text",
      },
    ],
    helperText:
      "Is there any wall insulation? If present, please indicate material and thickness",
    // onChange: handleSave,
  },
  {
    label: "Glazing",
    radio: ["Single", "Double"],
    inputsExpected: [
      {
        label: "Thickness",
        type: "text",
      },
      {
        label: "U-value",
        type: "text",
      },
      {
        label: "SHGC",
        type: "text",
      },
    ],
    helperText:
      "What is the current glazing specification? If there is no specification, please indicate if single or double glazing has been budgeted for.",
    // onChange: handleSave,
    dropDownVals: {
      label: "performance",
      options: [
        {
          label: "Low-E",
          value: "low-e",
        },
        {
          label: "Thermal",
          value: "thermal",
        },
        {
          label: "Normal",
          value: "normal",
        },
      ],
    },
  },
  {
    label: "Skylights",

    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Area",
        type: "text",
      },
      {
        label: "Material",
        type: "text",
      },
    ],
    helperText:
      "Does the project have any skylights? If so, please indicate estimated area and material",
    // onChange: handleSave,
  },
  {
    label: "Rainwater harvesting system",
    radio: ["Yes", "No"],
    inputsExpected: [{ label: "% of roof area", type: "text" }],
    helperText:
      "Does the project have any rainwater harvesting? If yes, what % of the roof area is estimated to be used for this?",
    // onChange: handleSave,
  },

  {
    label: "Insulation of cold storage envelope",

    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Material",
        type: "text",
      },
      {
        label: "Thickness",
        type: "text",
      },
    ],
    helperText:
      "Does the project have any insulation or cold storage areas? If yes, please indicate material and thickness",
    // onChange: handleSave,
  },
  {
    label: "Floor finishes",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
    ],
    helperText: "Please indicate material and thickness for each category",
    // onChange: handleSave,
  },
  {
    label: "External wall",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
    ],
    helperText: "Please indicate material and thickness for each category",
    // onChange: handleSave,
  },
  {
    label: "Internal wall",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
    ],
    helperText: "Please indicate material and thickness for each category",
    // onChange: handleSave,
  },
  {
    label: "Window frames",

    inputsExpected: [{ label: "Material", type: "text" }],
    helperText: "Please indicate material and thickness for each category",
    // onChange: handleSave,
  },
];

export const wetServiceEngineer = [
  {
    label: "Domestic hot water system",
    radio: ["Heat pump", "Boiler", "Solar geysers"],
    inputsExpected: [
      {
        label: "Hot water usage (%)",
        type: "text",
      },
      {
        label: "COP",
        type: "text",
      },
    ],
    inputsExpected2: [
      {
        label: "Hot water usage (%)",
        type: "text",
      },
    ],
    inputsExpected3: [
      {
        label: "Hot water usage (%)",
        type: "text",
      },
      {
        label: "Efficiency (%)",
        type: "text",
      },
    ],
    helperText:
      "How is hot water being supplied in the building? e.g. Heat pump, Solar PV, Solar geysers",
    // onChange: handleSave,
  },
  {
    label: "Low flow showerhead",

    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText: "What is the flow rate of the showerhead?",
    // onChange: handleSave,
  },

  {
    label: "Low flow bathroom faucets",

    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText: "What is the flow rate of the bathroom?",
    // onChange: handleSave,
  },

  {
    label: "Water closet systems",
    radio: ["Single flush", "Dual flush"],
    inputsExpected: [
      { label: "High flow rate (L/min)", type: "text" },
      { label: "Low flow rate (L/min)", type: "text" },
    ],
    helperText: "What is the flow rate?",
    // onChange: handleSave,
  },

  {
    label: "Water efficient Urinal",
    radio: ["Yes", "No"],
    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText:
      "Does the project have any water efficient urinals? If yes, what is the flow rate?",
    // onChange: handleSave,
  },

  {
    label: "Low flow faucets for kitchen sink",

    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText: "What is the flow rate?",
    // onChange: handleSave,
  },
  {
    label: "Water efficient bidets",
    radio: ["Yes", "No"],
    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText:
      "Does the project have any water efficient bidets? If yes, what is the flow rate?",
    // onChange: handleSave,
  },

  {
    label: "Landscape Irrigation",

    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText:
      "Does the project have any landscape irrigation? If yes, is there a targetted water use? Please note that the requirements is to stay below 3L/m2/day",
    // onChange: handleSave,
  },
];

export const structuralEngineer = [
  {
    label: "Roof",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
      { label: "Steel rebar in kg/m2", type: "text" },
    ],
    helperText: "Please indicate material, thickness and steel rebar in kg/m2",
    // onChange: handleSave,
  },
  {
    label: "Bottom Floor construction",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
      { label: "Steel rebar in kg/m2", type: "text" },
    ],
    helperText: "Please indicate material, thickness and steel rebar in kg/m2",
    // onChange: handleSave,
  },
  {
    label: "Intermediate floor construction ",

    inputsExpected: [
      { label: "Material", type: "text" },
      { label: "Thickness (mm)", type: "text" },
      { label: "Steel rebar in kg/m2", type: "text" },
    ],
    helperText: "Please indicate material, thickness and steel rebar in kg/m2",
    // onChange: handleSave,
  },
];

export const landscapeArchitect = [
  {
    label: "Landscape Irrigation",

    inputsExpected: [{ label: "Flow rate (L/min)", type: "text" }],
    helperText:
      "Does the project have any landscape irrigation? If yes, is there a targetted water use? Please note that the requirements is to stay below 3L/m2/day",
    // onChange: handleSave,
  },
];

export const electricalEngineer = [
  {
    label: "Efficient lighting (internal and External)",

    inputsExpected: [
      { label: "Internal Lighting power density", type: "text" },
      { label: "External Lighting power density", type: "text" },
    ],
    helperText: "Does the project have any lighting power density?",
    // onChange: handleSave,
  },
  {
    label: "Lighting controls",

    radio: ["Yes", "No"],
    inputsExpected: [
      // {
      //   label: "Lighting controls",
      //   type: "text",
      // },
    ],
    helperText: "Is lighting control included in the project for any areas?",
    // onChange: handleSave,
  },
  {
    label: "CO sensors for Parking Area",
    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Area",
        type: "text",
      },
    ],
    helperText:
      "Does the project have basement car parking area? If yes, does it have any CO sensors",
    // onChange: handleSave,
  },
];
export const pvConsultant = [
  {
    label: "Onsite renewable",

    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Annual output",
        type: "text",
      },
    ],
    helperText:
      "Does the project have any renewables? If yes, please estimate annuall output",
    // onChange: handleSave,
  },
];

export const mechanicalEngineer = [
  {
    label: "Cooling system efficiency",

    radio: ["Yes", "No"],
    inputsExpected: [
      {
        label: "Splits Units",
        type: "text",
      },
      {
        label: "VRF",
        type: "text",
      },
      {
        label: "Chillers",
        type: "text",
      },
    ],
    helperText:
      "Is heating/cooling included in the project? If yes, please indicate technology used (Split units, VRF, Chiller)",
    // onChange: handleSave,
  },
];
export const totalQuestions =
  [
    ...architect,
    ...wetServiceEngineer,
    ...structuralEngineer,
    ...landscapeArchitect,
    ...electricalEngineer,
    ...pvConsultant,
    ...mechanicalEngineer,
  ].length + 2; // +2 for the first two questions which are not in the array

export const returnExpectedNumberOfSubmissions = (profession) => {
  switch (profession) {
    case "Architect":
      return {
        length: architect.length,
        label: "Architect",
        questions: architect,
      };
    case "wetServiceEngineer":
      return {
        length: wetServiceEngineer.length,
        label: "Wet Service Engineer",
        questions: wetServiceEngineer,
      };
    case "structuralEngineer":
      return {
        length: structuralEngineer.length,
        label: "Structural Engineer",
        questions: structuralEngineer,
      };
    case "landscapeArchitect":
      return {
        length: landscapeArchitect.length,
        label: "Landscape Architect",
        questions: landscapeArchitect,
      };
    case "electricalEngineer":
      return {
        length: electricalEngineer.length,
        label: "Electrical Engineer",
        questions: electricalEngineer,
      };
    case "pvConsultant":
      return {
        length: pvConsultant.length,
        label: "PV Consultant",
        questions: pvConsultant,
      };
    case "mechanicalEngineer":
      return {
        length: mechanicalEngineer.length,
        label: "Mechanical Engineer",
        questions: mechanicalEngineer,
      };
    default:
      // return all questions
      return {
        length: totalQuestions,
        label: "All",
        questions: [
          ...architect,
          ...wetServiceEngineer,
          ...structuralEngineer,
          ...landscapeArchitect,
          ...electricalEngineer,
          ...pvConsultant,
          ...mechanicalEngineer,
        ],
      };
  }
};
