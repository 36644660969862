import PropTypes from "prop-types";

import { forwardRef, useState } from "react";
// material
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function AlertWithAction({
  open,
  setOpen,
  title,
  description,
  actions,
}) {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((item, key) => (
          <Button
            key={key}
            variant={`${item.typeAction === "primary" ? "contained" : "text"}`}
            onClick={item.action}
          >
            {item.label}
          </Button>
        ))}
        {/* <Button color="inherit" onClick={handleClose}>
          Disagree
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
