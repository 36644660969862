import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import firebase from "src/firebase";

import { ControlledInputs } from "../../components/controlledInputs";
import { architect } from "src/pages/mapperFuncs/questions";
import { sanitiseFieldName } from "src/utils/smartUtils";
import { FirstQuestion } from "./FirstQuestion";
import { WorkingTeam } from "./WorkingTeam";
import { AreaBreakdownForm } from "../areaBreakdown";

export const InitialSetupForm = (props) => {
  // handle the form submission
  const { projectCode, canGoThroughForm, setCanGoThroughForm, projectType } =
    props;

  const [data, setData] = React.useState({});
  // const [isLoading, setIsLoading] = React.useState(false);

  // const handleSave = () => {
  //   alert("Please fill in the required fields before saving");
  // };

  useEffect(() => {
    // get project where code === projectCode
    if (projectCode) {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("projects")
        .where("code", "==", projectCode)
        .onSnapshot((snapshot) => {
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setData(data[0]);
        });
      return () => unsubscribe();
    }
  }, [projectCode]);

  useEffect(() => {
    // check if there is a file in the database for this project
    // if there is, then get the url and set it to the fileUrl state
    // else set the fileUrl state to null
    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data, "data calculated questions");
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      });
  }, [projectCode]);

  // console.log("data", data, "projectCode");

  return (
    // [TODO: create an object  that will contain the controlledInputs Data and replace below laziness with a map ]
    <Grid container md={12}>
      <Grid md={12}>
        <FirstQuestion
          canGoThroughForm={canGoThroughForm}
          projectCode={projectCode}
          projectId={data?.id}
          setCanGoThroughForm={setCanGoThroughForm}
          projectData={data}
          readOnly={props.readOnly}
        />
      </Grid>

      {projectType?.toLowerCase() === "residential" ? null : (
        <Grid md={12}>
          <AreaBreakdownForm
            title="Area Breakdown"
            projectCode={projectCode}
            readOnly={props.readOnly}
            projectData={data}
            canGoThroughForm={canGoThroughForm}
            setCanGoThroughForm={setCanGoThroughForm}
          />
        </Grid>
      )}
    </Grid>
  );
};
