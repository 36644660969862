import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetQuotes = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("quotes")
      .where("status", "!=", "DELETED")
      .get()
      .then((doc) => {
        const quotes = [];
        doc.docs.forEach((document) => {
          const nb = {
            id: document.id,
            ...document.data(),
          };
          quotes.push(nb);
        });
        console.log({ quotes }, "quotes");
        // sort by date using createAt

        const sortedArr = sortByCreatedAtDescending(quotes);

        console.log(sortedArr, "sorted array");
        setdocs(quotes);
      });
    return unsub;
  }, []);
  return { docs };
};

function sortByCreatedAtDescending(arr) {
  return arr.sort(
    (a, b) =>
      new Date(b.createdAt.split("/").reverse().join("-")) -
      new Date(a.createdAt.split("/").reverse().join("-"))
  );
}

export default useGetQuotes;
