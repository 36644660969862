import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import firebase from "src/firebase";

import { ControlledInputs } from "../../components/controlledInputs";
import { architect } from "src/pages/mapperFuncs/questions";
import { sanitiseFieldName } from "src/utils/smartUtils";
// notistack
import { useSnackbar } from "notistack";
export const GeneralForm = (props) => {
  // handle the form submission
  const {
    projectCode,
    projectData,
    title,
    dataToMap,
    questions,
    adminCanSubmit,
    isAdmin,
    page,
  } = props;

  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = (value, fieldName, type) => {
    let fileUrl = null;

    // prevent the use from saving an empty value object
    if (Object?.keys(value)?.length === 0) {
      enqueueSnackbar("Please fill in the required fields before saving", {
        variant: "error",
        autoHideDuration: 1000,
      });

      return;
    }
    const sanitisedFieldName = fieldName.replace(/\s/g, "_")?.toLowerCase();

    // if the field is a file, then upload the file to the storage and get the url
    // and update the database with the url
    // else update the database with the value
    if (value?.type === "file") {
      value = {
        value,
        approved: false,
        approved_by: null,
        comments: null,
        status: "submitted",
        updated_on: null,
        type: "file",
      };

      firebase
        .firestore()
        .collection("edge_project_data")
        .doc(props.projectCode)
        .set(
          {
            [sanitisedFieldName]: value,
          },
          { merge: true }
        )
        .then(() => {
          console.log("file saved successfully");
        });

      return;
    }
    // if (Object?.keys(value)?.length === 0) {
    //   alert("Please fill in the required fields before saving", fieldName);
    //   return;
    // }
    // to the value add approved: false, approved_by: null, approved_on: null, comments: null, status: "pending" updated_on: null

    value = {
      value,
      approved: false,
      approved_by: null,
      comments: null,
      status: "submitted",
      updated_on: null,
      type,
    };

    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(props.projectCode)
      .set(
        {
          [sanitisedFieldName]: value,
        },
        { merge: true }
      )
      .then(() => {
        console.log("Data saved successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    // [TODO: create an object  that will contain the controlledInputs Data and replace below laziness with a map ]
    <>
      <Typography variant="h6" color={"primary"}>
        {title || ""}
      </Typography>
      <Grid container md={12}>
        {questions?.map((question) => {
          const {
            label,
            readOnly,
            radio,
            inputsExpected,
            inputsExpected2,
            inputsExpected3,
            helperText,
            hints,
            dropDownVals,
            onChange,
          } = question;
          return (
            <Grid md={6}>
              <ControlledInputs
                isAdmin={isAdmin}
                readOnly={props.readOnly}
                label={label}
                radio={radio}
                data={projectData?.[sanitiseFieldName(label)]}
                inputsExpected={inputsExpected}
                helperText={helperText}
                projectCode={props.projectCode}
                onChange={handleSave}
                adminCanSubmit={adminCanSubmit}
                page={page}
                hints={hints}
                inputsExpected2={inputsExpected2}
                inputsExpected3={inputsExpected3}
                dropDownVals={dropDownVals}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
