import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import firebase from "src/firebase";
import { Typography } from "@mui/material";

import { ControlledInputs } from "../../components/controlledInputs";
import {
  officeAreaBreakdown,
  industrialAreaBreakdown as warehouseAreaBreakdown,
} from "src/pages/mapperFuncs/questions";
import { sanitiseFieldName } from "src/utils/smartUtils";

// import { WorkingTeam } from "../InitialSetupForm/WorkingTeam";

export const AreaBreakdownForm = (props) => {
  // handle the form submission
  const { projectCode, canGoThroughForm, setCanGoThroughForm } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [data, setData] = React.useState({});
  const [title, setTitle] = React.useState("");

  const handleSave = (incomingvalue, fieldName, type) => {
    const sanitisedFieldName = fieldName.replace(/\s/g, "_")?.toLowerCase();

    const value = {
      value: incomingvalue,
      approved: false,
      approved_by: null,
      comments: null,
      status: "submitted",
      updated_on: null,
      type,
    };
    firebase
      .firestore()
      .collection("project_area_breakdown")
      .doc(props.projectCode)
      .set(
        {
          [sanitisedFieldName]: value,
        },
        { merge: true }
      )
      .then(() => {
        console.log("Data saved successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (projectCode) {
      const unsub = firebase
        .firestore()
        .collection("quotes")
        .where("projectCode", "==", projectCode)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            switch (data.type.toLowerCase()) {
              case "office":
                setQuestions(officeAreaBreakdown);
                break;
              case "warehouse":
                setTitle("Warehouse Area Breakdown");
                setQuestions(warehouseAreaBreakdown);
              default:
                break;
            }
          });
        });

      return () => unsub();
    }
  }, [projectCode]);

  // useEffect(() => {
  //   if (projectCode) {
  //     const unsub = firebase
  //       .firestore()
  //       .collection("project_area_breakdown")
  //       .doc(projectCode)
  //       .onSnapshot((doc) => {
  //         if (doc.exists) {
  //           const data = doc.data();

  //           console.log(data, "che che");
  //           setProjectData(data);
  //         } else {
  //         }
  //       });

  //     return () => unsub();
  //   }
  // }, [projectCode, canGoThroughForm]);

  useEffect(() => {
    // get the data from the database and update the state
    // use firebase to get the data from the edge_project_data collection

    const fetchData = async () => {
      // use the onSnapshot method to get the data
      // this will update the data in real time

      setIsLoading(true);
      const docRef = firebase
        .firestore()
        .collection("project_area_breakdown")
        .doc(projectCode);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data, "data calculated questions");
          setData(data);
        } else {
          // setCanGoThroughForm(false);
          setCanGoThroughForm(true);
        }
      });
      setIsLoading(false);
    };
    fetchData();
    // console.log(edgeProjectStatus, "edge project status");
  }, [projectCode, projectCode]);

  return (
    <>
      <Typography variant="h6" color="primary">
        {props?.title}
      </Typography>
      <Grid container md={12}>
        {questions?.map((question) => {
          const {
            label,
            readOnly,
            radio,
            inputsExpected,
            inputsExpected2,
            inputsExpected3,
            helperText,
            hints,
            dropDownVals,
            onChange,
          } = question;
          return (
            <Grid md={6}>
              <ControlledInputs
                isAdmin
                hideVerifyButton={true}
                readOnly={props.readOnly}
                label={label}
                radio={radio}
                data={data?.[sanitiseFieldName(label)]}
                inputsExpected={inputsExpected}
                helperText={helperText}
                projectCode={props.projectCode}
                onChange={handleSave}
                // adminCanSubmit={adminCanSubmit}
                // page={page}
                hints={hints}
                inputsExpected2={inputsExpected2}
                inputsExpected3={inputsExpected3}
                dropDownVals={dropDownVals}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
