import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// material
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
  Divider,
} from "@mui/material";
// components
import Page from "../../components/Page";
import firebase from "../../firebase";
import emailjs from "@emailjs/browser";
import { fNumber } from "src/utils/formatNumber";
import useGetQuote from "../../hooks/useGetQuote";
import useGetQuoteLogs from "src/hooks/useGetQuoteLogs";
import UserView from "./UserView";
import QuoteView from "./QuoteView";

import { PDFViewer } from "@react-pdf/renderer";

// import  DialogAnimate  from '../../components/DialogAnimate';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoicePDF from "./InvoicePDF";
import {
  formatDateWithMoment,
  makeQuoteStatusLikeConstants,
  makeStatusColor,
  makeStatusReadable,
  putDateDayFirst,
  renderStatusConsideringNewAndOldStatus,
} from "src/utils/smartUtils";
import { QUOTE_STATUS } from "src/config/constants";
import Label from "src/components/Label";
import moment from "moment";
export default function Quote() {
  // get the project id from the url
  const location = useLocation();
  const navigate = useNavigate();
  const quoteId = location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(true);
  const [data, setdocs] = useState(null);

  // if (!quoteId) {
  //   navigate("/404");
  // }

  // const data = useGetQuote(quoteId).docs;
  const quoteStatus =
    data && renderStatusConsideringNewAndOldStatus(data.status);
  const logs = useGetQuoteLogs(quoteId).docs;
  const [projectId, setProjectId] = useState(null);
  let publicKey = "54lQzVPslTf0AlGlL";
console.log("DATA", data)
  const [values, setValues] = useState(null);

  const [status, setstatus] = useState(null);

  const notify = (msg) => toast(msg);

  const updateStatus = () => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(quoteId || location.state.data)
      .update({ status: status })
      .then(() => {
        firebase
          .firestore()
          .collection("logs")
          .add({
            user_id: firebase.auth().currentUser.uid,
            log: status,
            item_id: location.state.data,
            task: "QUOTE_UPDATE",
            createdAt: new Date(Date.now()).toLocaleString(),
          })
          .then(() => {
            notify("Quote updated");
            setstatus(null);
          });
      });

    setstatus(null);
  };

  const deleteItem = () => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(location.state.data)
      .delete()
      .then(() => {
        alert("Quote was deleted successfully");
        navigate(-1);
      })
      .catch((e) => {
        alert(e);
      });
  };

  // const dayPutFirst = putDateDayFirst(data?.createdAt);
  // console.log(logs[0], "DATA");
  // console.log(logs[0].createdAt, "CREATE AT");
  // console.log(
  //   new Date(logs[0].createdAt).toLocaleDateString("en-US"),
  //   "PARSE to locale date"
  // );
  // console.log(new Date(logs[0].createdAt), "unreversed not to locale date");
  // console.log(new Date(dayPutFirst), "reversed not to locale date");

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("quotes")
      .doc(quoteId || location.state.data)
      .onSnapshot((doc) => {
        setdocs({ ...doc.data(), id: doc.id });

        console.log(">>> ", data, "check for data");
      });
      console.log(">>> ", data, "check for data");
    return () => unsub();
  }, [quoteId]);
  return (
    <Page title="Dashboard">
      {data && (
        <Container maxWidth="xxl">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            {/* Add a back button */}
            <Button
              color="primary"
              size="medium"
              variant="text"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            {makeQuoteStatusLikeConstants(quoteStatus) ===
              QUOTE_STATUS.PENDING_PAYMENT ||
            makeQuoteStatusLikeConstants(quoteStatus) ===
              QUOTE_STATUS.PAID ? null : (
              <Button
                color="error"
                size="medium"
                onClick={() => deleteItem()}
                variant="contained"
              >
                Delete Quote
              </Button>
            )}
          </div>

          <Grid container maxWidth spacing={1}>
            <Grid item xs={12} sm={12} md={3}>
              <Stack spacing={3}>
                {data?.status === QUOTE_STATUS.PAID ? null : (
                  <Card sx={{ p: 3 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" gutterBottom>
                        {makeQuoteStatusLikeConstants(quoteStatus) ===
                        QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE ? (
                          <>
                            <Typography variant="h6" gutterBottom>
                              {quoteStatus}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              You can update the status to "Invoiced" once the
                              client accepts the quote.
                            </Typography>
                          </>
                        ) : (
                          "Update Status"
                        )}
                      </Typography>

                      {status && (
                        <Button
                          style={{
                            marginTop: -10,
                          }}
                          onClick={() => setstatus(null)}
                        >
                          Close
                        </Button>
                      )}
                    </div>

                    <Stack>
                      {status && (
                        <Typography variant="body2" gutterBottom>
                          Are you sure you want to update the status of this
                          Quote to{" "}
                          <b>
                            <u>{makeStatusReadable(status)}</u>
                          </b>
                          ?
                        </Typography>
                      )}
                      {!status && (
                        <>
                          {makeQuoteStatusLikeConstants(quoteStatus) ===
                            QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE && (
                            <Button
                              onClick={() => setstatus(QUOTE_STATUS.INVOICED)}
                              disabled
                              variant={
                                status === QUOTE_STATUS.INVOICED
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              {makeStatusReadable(QUOTE_STATUS.INVOICED)}
                            </Button>
                          )}
                          {makeQuoteStatusLikeConstants(quoteStatus) ===
                            QUOTE_STATUS.CLIENT_ACCEPTED && (
                            <Button
                              onClick={() => setstatus(QUOTE_STATUS.INVOICED)}
                              variant={
                                status === QUOTE_STATUS.INVOICED
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              Invoice client
                            </Button>
                          )}
                          {(makeQuoteStatusLikeConstants(quoteStatus) ===
                            QUOTE_STATUS.INVOICED ||
                            makeQuoteStatusLikeConstants(quoteStatus) ===
                              "CLIENT_INVOICED") && (
                            <>
                              <Button
                                onClick={() => setstatus(QUOTE_STATUS.PAID)}
                                variant={
                                  status === QUOTE_STATUS.PAID
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                {makeStatusReadable(QUOTE_STATUS.PAID)}
                              </Button>

                              <br />
                              {/* <br /> */}

                              <Button
                                onClick={() => {
                                  setstatus(QUOTE_STATUS.PENDING_PAYMENT);
                                }}
                                variant={
                                  status === QUOTE_STATUS.PENDING_PAYMENT
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                {makeStatusReadable(
                                  QUOTE_STATUS.PENDING_PAYMENT
                                )}
                              </Button>
                            </>
                          )}
                          {makeQuoteStatusLikeConstants(quoteStatus) ===
                            QUOTE_STATUS.PENDING_PAYMENT && (
                            <>
                              <Button
                                onClick={() => setstatus(QUOTE_STATUS.PAID)}
                                variant={
                                  status === QUOTE_STATUS.PAID
                                    ? "contained"
                                    : "outlined"
                                }
                              >
                                {makeStatusReadable(QUOTE_STATUS.PAID)}
                              </Button>
                            </>
                          )}
                        </>
                      )}
                      {/* <br /> */}

                      {status && (
                        <Button
                          variant="contained"
                          onClick={() => updateStatus()}
                        >
                          Update
                        </Button>
                      )}
                    </Stack>
                  </Card>
                )}

                <Card sx={{ p: 3 }}>
                  <Stack spacing={1}>
                    <Typography variant="body2" gutterBottom>
                      Status
                    </Typography>
                    <Label color={makeStatusColor(quoteStatus)}>
                      {makeStatusReadable(quoteStatus)}
                    </Label>
                    <Stack
                      spacing={3}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography variant="body2" gutterBottom>
                        Date
                        <Typography variant="h6" gutterBottom>
                          {data?.createdAt}
                        </Typography>
                      </Typography>
                    </Stack>
                    <Typography variant="body2" gutterBottom>
                      Client Name
                      <Typography variant="h6" gutterBottom>
                        {data?.client}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Project Name
                      <Typography variant="h6" gutterBottom>
                        {data?.project}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Input
                      <Typography variant="h6" gutterBottom>
                        {data.type === "Office" || data.type === "Warehouse"
                          ? `${data.gfa}m² Gross Floor Area`
                          : `${data.typology} Typology, ${data.units} Units`}
                      </Typography>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Email
                      <Link
                        to={"/clients/"}
                        state={{ data: data.email }}
                        style={{
                          display: "grid",
                          color: "inherit",
                          textDecoration: "underline",
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {data.email}
                        </Typography>
                      </Link>
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      Performance Modeling
                      <Typography variant="h6" gutterBottom>
                        {data.PMS_Enabled
                          ? "Enabled"
                          : "Off"}
                      </Typography>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Building Type
                      <Typography variant="h6" gutterBottom>
                        {data.type}
                      </Typography>
                    </Typography>
                  </Stack>
                </Card>

                <Card sx={{ p: 3 }}>
                  <Typography variant="body2" gutterBottom>
                    Download PDF Quote
                  </Typography>
                  {data.quote_file && (
                    <Button color="info" size="medium" variant="contained">
                      <a
                        href={data.quote_file}
                        rel="noreferrer"
                        target="_Blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Download Quote
                      </a>
                    </Button>
                  )}
                  <Stack></Stack>
                </Card>

                {/* <Card sx={{p:3}}>
<Typography variant="body2" gutterBottom>
            Attach Documents            
        </Typography> 
        {data.quote_file && <UploadFile />}
    <Stack></Stack></Card> */}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" gutterBottom>
                      Expert Services
                      <br />
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      R {fNumber(data.ExpertServices)}
                    </Typography>
                  </Stack>
                  {data.type === "Residential" ? (
                     <Typography variant="body1" gutterBottom>
                     Sustainability Consulting
                     <br />
                     <Typography variant="h6" gutterBottom>
                       R {fNumber(data.SC)}
                     </Typography>
                   </Typography>
                  ) : (
                    <Typography variant="body1" gutterBottom>
                      Preliminary Assessment
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.PA)}
                      </Typography>
                    </Typography>
                   
                  )}
                  <Typography variant="body1" gutterBottom>
                    Documentation Preliminary Certification
                    <br />
                    <Typography variant="h6" gutterBottom>
                      R {fNumber(data.DPC)}
                    </Typography>
                  </Typography>
               
                    <Typography variant="body1" gutterBottom>
                      Documentation Post Construction Certification
                      <br />
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.DPCC)}
                      </Typography>
                    </Typography>
               
                </Card>

                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h6" gutterBottom>
                        Auditor Services
                        <br />
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.AuditorServices)}
                      </Typography>
                    </Stack>

                  
                      <Typography variant="body1" gutterBottom>
                        Preliminary Design Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PDA)}
                        </Typography>
                      </Typography>
                  
                      <Typography variant="body1" gutterBottom>
                        Post Construction Site Audit
                        <br />
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PCSA)}
                        </Typography>
                      </Typography>
    
              
                  </Stack>
                </Card>

                {data.PMS_Enabled && (
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Typography variant="body1" gutterBottom>
                        Performance Modeling Simulation
                        <Typography variant="h6" gutterBottom>
                          R {fNumber(data.PMS)}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Card>
                )}

                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h6" gutterBottom>
                        Total Consulting Fees
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        R {fNumber(data.TotalFees)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Stack spacing={1}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Activity
                  </Typography>
                  {logs &&
                    logs.map((log, index) => (
                      <Typography key={index} variant="body2">
                        <br />
                        {log.user_id ? (
                          <>
                            <Typography
                              variant="h6"
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              <UserView data={log?.user_id} />
                            </Typography>{" "}
                            {" Changed Status to "}
                          </>
                        ) : null}
                        {log.user_id ? null : null}{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {renderStatusConsideringNewAndOldStatus(log.log)}
                        </span>
                        {log.user_id ? null : " Quote"}.{/* add date */}
                        <span style={{ color: "grey" }}>
                          {" "}
                          - {formatDateWithMoment(log.createdAt)}
                        </span>
                        <br />
                        <br />
                        <Divider />
                      </Typography>
                    ))}
                </Card>
              </Stack>
              <br />
              {/* [TODO:] UI Refactors */}
              {data?.projectInitiated === true ? (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  component={Link}
                  style={{ marginBottom: 10, width: "100%" }}
                  to={`/projects/view/${data?.projectId}`}
                >
                  View project
                </Button>
              ) : (
                (makeQuoteStatusLikeConstants(quoteStatus) ===
                  QUOTE_STATUS.PENDING_PAYMENT ||
                  makeQuoteStatusLikeConstants(quoteStatus) ===
                    QUOTE_STATUS.PAID) && (
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    style={{ marginBottom: 10, width: "100%" }}
                    onClick={() => {
                      // console.log("Initiate project", data.id);
                      navigate(`/projects/initiate/${data.id}`);
                    }}
                  >
                    Initiate project
                  </Button>
                )
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
