import { useState, useEffect } from "react";
// material
import { Grid, Button, Container, Stack, Typography, Box } from "@mui/material";
import Page from "../../../components/Page";
import { useNavigate, useLocation } from "react-router-dom";
import firebase from "../../../firebase";

import { InfoCols1, InfoCols2 } from "./components/ProjectInfoCols";

import { formatDateWithMoment, makeStatusReadable } from "src/utils/smartUtils";

import LoadingScreen from "src/components/LoadingScreen";
import { EdgeRFI } from "src/pages/central/ProjectStatus/EdgeRfi";
import { InitialAssessMent } from "src/pages/central/ProjectStatus/InitialAssessment";
import { RoundOneSubmission } from "src/pages/central/ProjectStatus/RoundOne";
import { Certification } from "src/pages/central/ProjectStatus/Certification";
import Round1SubmissionDialogue from "./components/Round1Dialogue";
import CertificationSubmissionDialogue from "./components/CertificateSubmitDialogue";

export default function ProjectView() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.pathname.split("/")[3];
  const [quoteId, setQuoteId] = useState(null);
  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [openRoundOne, setOpenRoundOne] = useState(false);
  const [openCertification, setOpenCertification] = useState(false);

  if (!projectId) {
    navigate("/404");
  }
  const [progress, setprogress] = useState(false);

  const handleNavigateToQuote = (quoteId) => {
    // return;
    navigate(`/quote/${quoteId}`);
  };

  const handleNavigateToWorkingTeam = () => {
    navigate(`/projects/working-team/${projectId}`);
  };
console.log("Project",project)
console.log("Quote",quote)
  useEffect(() => {
    setprogress(true);
    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setQuoteId(doc.data().quoteId);
          firebase
            .firestore()
            .collection("quotes")
            .doc(doc.data().quoteId)
            .get()
            .then((quoteDoc) => {
              if (quoteDoc.exists) {
                setQuote(quoteDoc.data());
                setprogress(false);
              }
            });
        } else {
          setprogress(false);
          navigate("/404");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        setprogress(false);
        navigate("/404");
      });
  }, [navigate, projectId]);

  return (
    <Page title="View Project | Dashboard">
      <Round1SubmissionDialogue
        projectCode={project?.code}
        open={openRoundOne}
        setOpen={setOpenRoundOne}
      />
      <CertificationSubmissionDialogue
        projectCode={project?.code}
        open={openCertification}
        setOpen={setOpenCertification}
      />
      {progress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <>
          {/* back button */}

          <Container
            sx={{
              justifyContent: "space-between",
              p: 4,
            }}
          >
            <Button
              color="primary"
              size="medium"
              variant="text"
              sx={{ padding: 0 }}
              onClick={() => navigate("/projects")}
            >
              Back to Projects
            </Button>
            <Typography variant="h4" color="GrayText">
              {quote?.project}
            </Typography>
          </Container>

          <Container
            style={{
              backgroundColor: "#79838D",
              padding: "20px",
              paddingLeft: "40px",
              paddingRight: "40px",
              borderRadius: "12px",
            }}
          >
            <InfoCols1
              func={handleNavigateToWorkingTeam}
              firstDatasetInRibbon={{
                client: quote?.client || quote?.email,
                payment: makeStatusReadable(quote?.status),
                currentDuration: formatDateWithMoment(project?.createdAt),
              }}
            />
            <InfoCols2
              secondDatasetInRibbon={{
                gfa: quote?.gfa,
                buildingType: quote?.type,
                performance_modeling: quote?.PMS_Enabled,
              }}
              linkTo={quoteId}
              func={() => {
                handleNavigateToQuote(quoteId);
              }}
            />
            {/* add link to drive that opens a new tab */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2, width: "300px" }}
            >
              <Stack>
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Project code : <Typography>{project?.code}</Typography>
                </Typography>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  cursor: project?.driveUrl ? "pointer" : "not-allowed",
                }}
                disabled={project?.driveUrl ? false : true}
              >
                {project?.driveUrl ? (
                  <a href={`${project.driveUrl}`}>
                    <Typography variant="button" color="white">
                      Drive Link
                    </Typography>
                  </a>
                ) : (
                  <Typography variant="button" color="white">
                    Drive Link
                  </Typography>
                )}
              </Button>
            </Stack>
          </Container>

          {/* <Box sx={{ mt: 3, pl: 4 }}>
            <Typography variant="subtitle2" color="GrayText" gutterBottom>
              Recent client activity -
            </Typography>
          </Box> */}
          <Container>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                md={9}
                justifyContent="space-between"
                p={2}
              >
                <Stack xs={12} md={6}>
                  <Typography variant="h4" color="GrayText" gutterBottom>
                    Expert Services
                  </Typography>
                  <EdgeRFI projectId={projectId} />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <InitialAssessMent projectCode={project?.code} />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <RoundOneSubmission
                    setOpen={setOpenRoundOne}
                    open={openRoundOne}
                    projectCode={project?.code}
                  />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <Certification
                    projectCode={project?.code}
                    setOpen={setOpenCertification}
                  />
                </Stack>

                <Stack xs={12} md={6}>
                  <Typography variant="h4" color="GrayText" gutterBottom>
                    Auditor Services
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3} p={2}>
                <Typography variant="h4" color="GrayText" gutterBottom>
                  Recent Activity
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Page>
  );
}
