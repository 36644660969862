import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, LinearProgress, Stack } from "@mui/material";
import firebase from "src/firebase";

import { ControlledInputsInitialAssessment } from "src/pages/external/components/controlledInputs2";
import { PrelimDataForms } from "src/pages/mapperFuncs/InitialAssess";
import { sanitiseFieldName } from "src/utils/smartUtils";
import { LoadingButton } from "@mui/lab";
import {
  INITIAL_ASSESSEMENT_STATUS,
  ROUND1_STATUS,
} from "src/config/constants";
import { Link } from "react-router-dom";

export const InitialAssessmentFormContainer = (props) => {
  // console.log(props, "props");
  // handle the form submission
  const { client = false } = props;
  const {
    projectCode,
    readOnly,
    countAddedDesignSpecs,
    countSavedQuestions,
    setCountAddedDesignSpecs,
    setCountSavedQuestions,
  } = props;

  const [data, setData] = React.useState({});
  const [sentToClientLoading, setSentToClientLoading] = React.useState(false);

  const [noQuestionAdded, setNoQuestionAdded] = React.useState(false);
  const [formsAdded, setFormsAdded] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [countApproved, setCountApproved] = React.useState(0);
  const [clientApproveLoading, setAuditorApproveLoading] =
    React.useState(false);

  const [file, setFile] = useState(null); // state variable to hold the uploaded file

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]); // set the uploaded file
  };

  const handleSave = (label) => {
    setIsSubmitting(true);
    const value = {
      approved: false,
      approved_by: null,
      comments: null,
      status: "submitted",
      updated_on: null,
    };

    firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(props.projectCode)
      .set(
        {
          [sanitiseFieldName(label)]: value,
        },
        { merge: true }
      )
      .then(() => {
        setIsSubmitting(false);
        console.log("Data saved successfully", "findings");
      });
    setIsSubmitting(false);
  };

  const determineDisabled = () => {
    if (countSavedQuestions === 0) {
      return true;
    }
    if (countApproved === 0) {
      return true;
    }

    if (countApproved < countSavedQuestions) {
      return true;
    }

    // if (!file) {
    //   // if no file is uploaded, disable the button
    //   return true;
    // }

    return false;
  };

  const handleSendToClientConfirmation = () => {
    // SET INITIAL ASSESSMENT FORM TO SENT TO CLIENT
    setSentToClientLoading(true);
    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .update({
        status: INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE,
      })
      .then(() => {
        alert("Form sent to client");
        setSentToClientLoading(false);
      });
  };

  const handleClientApprove = () => {
    // SET INITIAL ASSESSMENT FORM TO SENT TO CLIENT
    setAuditorApproveLoading(true);
    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .update({
        status: INITIAL_ASSESSEMENT_STATUS.COMPLETED,
      })
      .then(() => {
        // update or create round1 collection status to IN_PROGRESS
        firebase
          .firestore()
          .collection("Round1")
          .doc(projectCode)
          .set(
            {
              status: ROUND1_STATUS.GET_STARTED,
            },
            { merge: true }
          )
          .then(() => {
            alert("Form approved");
            // reload the page
            setAuditorApproveLoading(false);
            // window.location.reload();
          });
      });
  };
  // useEffect(() => {
  //   setIsLoading(true);
  //   // get the questions from the PrelimDataForms collection with the id of projectCode

  //   firebase
  //     .firestore()
  //     .collection("PrelimDataForms")
  //     .doc(projectCode)
  //     .get()
  //     .then(
  //       (doc) => {
  //         if (doc.exists) {
  //           let data = doc.data();
  //           let questions = data.questions;

  //           console.log(questions, "mufwaya limo");
  //           let questionsArray = [];
  //           questions.forEach((question) => {
  //             questionsArray.push(question);
  //           });
  //           setQuestions(questionsArray);
  //           setIsLoading(false);
  //         } else {
  //           setIsLoading(false);
  //           // doc.data() will be undefined in this case
  //           console.log("No such document! mufwaya limo");
  //         }
  //       }
  //       // , (error) => {
  //       //   console.log("Error getting document:", error);
  //       // }
  //     );
  // }, [projectCode]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(projectCode)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          const id = doc.id;

          const dataObj = {
            id: id,
            ...data,
          };
          setData(dataObj);

          const getApprovedCount = (dataObj) => {
            let count = 0;
            for (const key in dataObj) {
              if (dataObj[key].approved) {
                count++;
              }
            }
            return count;
          };
          const totalApproved = getApprovedCount(dataObj);
          console.log(totalApproved, "totalApproved");
          setCountApproved(totalApproved);
        } else {
          console.log("No such document!");
          setNoQuestionAdded(true);
        }
      });

    return () => unsub();
  }, [projectCode, isSubmitting]);

  // [TODO: remove if reqriite works] useEffect(() => {
  //   // get submitted responses and assign to the data object
  //   function getQuestionsWithDesignSpecification(PrelimDataForms = []) {
  //     const questions = [];
  //     for (let i = 0; i < PrelimDataForms.length; i++) {
  //       const form = PrelimDataForms[i];
  //       if (form.designSpecification !== undefined) {
  //         questions.push(form.label);
  //       }
  //     }
  //     return questions;
  //   }
  //   firebase
  //     .firestore()
  //     .collection("PrelimDataForms")
  //     .doc(projectCode)
  //     .get()
  //     .then(
  //       (doc) => {
  //         if (doc.exists) {
  //           let data = doc.data();
  //           const id = doc.id;

  //           const dataObj = {
  //             id: id,
  //             ...data,
  //           };

  //           setFormsAdded(dataObj);
  //           const questionsWithDesignSpec = getQuestionsWithDesignSpecification(
  //             dataObj?.questions
  //           );
  //           // console.log(questionsWithDesignSpec);

  //           setCountSavedQuestions(dataObj?.questions.length);
  //           setCountAddedDesignSpecs(questionsWithDesignSpec.length);
  //         } else {
  //           // doc.data() will be undefined in this case
  //           console.log("No such document!");
  //         }
  //       }
  //       // , (error) => {
  //       //   console.log("Error getting document:", error);
  //       // }
  //     );
  // }, [
  //   projectCode,
  //   countAddedDesignSpecs,
  //   isSubmitting,
  //   setCountSavedQuestions,
  //   setCountAddedDesignSpecs,
  // ]);

  function getQuestionsWithDesignSpecification(PrelimDataForms = []) {
    const questions = [];
    for (let i = 0; i < PrelimDataForms.length; i++) {
      const form = PrelimDataForms[i];
      if (form.designSpecification !== undefined) {
        questions.push(form.label);
      }
    }
    return questions;
  }
  useEffect(() => {
    const db = firebase.firestore();
    const docRef = db.collection("PrelimDataForms").doc(projectCode);

    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        let data = doc.data();
        const id = doc.id;

        const dataObj = {
          id: id,
          ...data,
        };

        setFormsAdded(dataObj);
        const questionsWithDesignSpec = getQuestionsWithDesignSpecification(
          dataObj?.questions
        );
        setCountSavedQuestions(dataObj?.questions.length);
        setCountAddedDesignSpecs(questionsWithDesignSpec.length);
      } else {
        console.log("No such document!");
      }
    });

    return unsubscribe;
  }, [
    projectCode,
    countAddedDesignSpecs,
    isSubmitting,
    setCountSavedQuestions,
    setCountAddedDesignSpecs,
  ]);

  console.log(formsAdded?.status, "formsAdded");
  return (
    // [TODO: create an object  that will contain the controlledInputs Data and replace below laziness with a map ]

    <>
      <Typography variant="h6" color={"primary"}>
        Initial Assessment
      </Typography>
      <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
        Please submit the following required information.
      </Typography>

      {/* file upload input */}
      {/* {readOnly && (
        <Stack sx={{ width: 200, mt: 2 }}>
          {formsAdded?.status === INITIAL_ASSESSEMENT_STATUS.PENDING_AUDIT ||
          formsAdded?.status ===
            INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE ? (
            <LoadingButton disabled={true} variant="contained" color="primary">
              Sent to auditor
            </LoadingButton>
          ) : (
            <LoadingButton
              disabled={determineDisabled()}
              variant="contained"
              color="primary"
              loading={sentToClientLoading}
              onClick={handleSendToClientConfirmation}
            >
              Send to auditor
            </LoadingButton>
          )}
          <Typography variant="caption" component="h1" gutterBottom>
            {countApproved} Approved / {countSavedQuestions - countApproved}{" "}
            pending approval
          </Typography>
        </Stack>
      )} */}
      {!readOnly && (
        <Stack
          sx={{
            width: 200,
            mt: 2,
            display: "flex",
            justifyContent: "end",
          }}
        >
          {formsAdded?.status === INITIAL_ASSESSEMENT_STATUS.PENDING_AUDIT ||
            (formsAdded?.status ===
              INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE && (
              <>
                <LoadingButton
                  disabled={determineDisabled()} // disable the button if no file is uploaded
                  variant="contained"
                  color="primary"
                  loading={clientApproveLoading}
                  onClick={handleClientApprove}
                >
                  Confirm verification
                </LoadingButton>
                {/* <Typography variant="caption" component="h1" gutterBottom>
                  cofirm the data submited below is correct
                </Typography> */}
              </>
            ))}

          {formsAdded?.status === INITIAL_ASSESSEMENT_STATUS.COMPLETED && (
            <Typography variant="caption" component="h1" gutterBottom>
              This submission was approved ✅
            </Typography>
          )}
        </Stack>
      )}

      <Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
        {client ? (
          <a href="#!" target="_blank" rel="noreferrer">
            Uploaded brief
          </a>
        ) : (
          <>
            <Typography variant="h6" component="h1" gutterBottom>
              Upload brief
            </Typography>
            <input type="file" onChange={handleFileUpload} />
          </>
        )}
      </Stack>
      {isLoading ? null : (
        <Grid container md={12}>
          {formsAdded?.questions?.map((question) => {
            const { label, designSpecification, documentsRequired } = question;

            return (
              <Grid md={6}>
                <ControlledInputsInitialAssessment
                  label={label}
                  isLoading={isLoading}
                  designSpecification={question?.designSpecification}
                  hasDesignSpecs={question?.designSpecification ? true : false}
                  documentsRequired={documentsRequired}
                  onChange={handleSave}
                  isSubmitting={isSubmitting}
                  projectCode={projectCode}
                  questions={formsAdded?.questions}
                  readOnly={readOnly}
                  setCountAddedDesignSpecs={setCountAddedDesignSpecs}
                  data={data?.[sanitiseFieldName(label)]}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};
