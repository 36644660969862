import React, { useEffect } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  // Block,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import { InputBox } from "../input";

import Label from "src/components/Label";
import { FileUploadInitialAssess } from "./components/FileUpload";
import { InitialAssessmentOverlay } from "../InitialAssessmentOverlay";
import { LoadingButton } from "@mui/lab";
import firebase from "src/firebase";
const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  "& > *": { mx: 1 },
};
export const ControlledInputsInitialAssessment = (props) => {
  const {
    label,
    designSpecification,
    documentsRequired,
    onChange,
    projectCode,
    data,
    mainLabel,
    readOnly = false,
    inputType = "file",
    questions,
    hasDesignSpecs,
    setCountAddedDesignSpecs,
    isSubmitting,
  } = props;

  const [openInputDialogue, setOpenInputDialogue] = React.useState(false);
  const [dialogueData, setDialogueData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // const [hasDesignSpecs, setHasDesignSpecs] = React.useState(false);
  const [specValue, setSpecValue] = React.useState("");
  const [hasSaved, setHasSaved] = React.useState(false);

  const handleSaveDesignSpecs = (label, spec) => {
    // save design specs for the question element with containing label

    const question = questions.find((question) => {
      return question.label === label;
    });
    const questionIndex = questions.findIndex((question) => {
      return question.label === label;
    });

    const updatedQuestion = {
      ...question,
      designSpecification: spec,
    };

    // replace the question with the updated question using the index
    const updatedQuestions = questions.map((question, index) => {
      if (index === questionIndex) {
        return updatedQuestion;
      }
      return question;
    });

    // update firebase PrelimDataForms collection
    const db = firebase.firestore();
    const docRef = db.collection("PrelimDataForms").doc(projectCode);
    docRef
      .update({
        questions: updatedQuestions,
      })
      .then(() => {
        console.log("updated");
        setCountAddedDesignSpecs((prev) => prev + 1);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  // useEffect(() => {
  //   // force a re-render when save or submit button is clicked
  // }, [hasSaved]);

  return (
    <div>
      <InitialAssessmentOverlay
        open={openInputDialogue}
        setOpen={setOpenInputDialogue}
        dialogueData={dialogueData}
        label={label}
      />
      <Box
        sx={{
          width: "80%",
          paddingTop: "2rem",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "primary.main",
            },
          },
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {label}
            </Typography>

            {hasDesignSpecs ? (
              <QuestionStatus status={data?.status || "pending"} />
            ) : null}

            {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {status || "Pending"}
            </Typography> */}
          </Stack>
        </Stack>

        {/* {radio?.length > 0 && (
          <RadioGroup row defaultValue={handleWhichRadioSelected}>
            {radio.map((radio) => (
              <FormControlLabel
                value={radio}
                onChange={(e) => {
                  handleShowInputs(radio);
                  handleInputChange(e, label, "radio");
                }}
                name={sanitiseFieldName(label)}
                control={<Radio color="info" />}
                label={radio}
                disabled={readOnly}
              />
            ))}
          </RadioGroup>
        )} */}
        {documentsRequired.map((input, index) => (
          <Stack sx={{ mb: 4 }}>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {input}
            </Typography>
            {isLoading ? (
              <div style={style}>
                <CircularProgress />
              </div>
            ) : (
              <FileUploadInitialAssess
                readOnly={readOnly}
                label={input}
                mainLabel={label}
                index={index}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                projectCode={projectCode}
              />
            )}
          </Stack>
        ))}
        {hasDesignSpecs && (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Design specification:{" "}
            <span sx={{ color: "#000" }}>{designSpecification}</span>
          </Typography>
        )}

        {!hasDesignSpecs ? (
          <Stack>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {mainLabel}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <TextField
                  label="Design specification"
                  value={specValue}
                  onChange={(e) => setSpecValue(e.target.value)}
                />
              </Stack>

              <Stack>
                <LoadingButton
                  variant="contained"
                  onClick={() => handleSaveDesignSpecs(label, specValue)}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
              {
                data?.comments ? (
                  <Label color="error" variant="filled">
                    {data?.comments}
                  </Label>
                ) : data?.status === "approved" ? null : null // </Typography> //   <a href="#!">Request changes ?</a> //   response approved and cannot be edited{" "} // <Typography variant="body2" sx={{ color: "text.secondary" }}>
              }
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {readOnly ? (
                data?.status === "submitted" || data?.status === "approved" ? (
                  <Button
                    onClick={() => {
                      setOpenInputDialogue(true);
                      setDialogueData({
                        title: label,
                        description: "helperText",
                        value: data,
                        projectCode: projectCode,
                        type: "file",
                      });
                    }}
                    variant="text"
                    color="info"
                    size="small"
                  >
                    {data?.status === "approved" ? "Update" : "Verify"}
                  </Button>
                ) : (
                  <Button
                    title="No data has been submitted"
                    variant="text"
                    color="info"
                    size="small"
                    disabled
                  >
                    Verify
                  </Button>
                )
              ) : data?.status === "approved" ? null : (
                <LoadingButton
                  loading={isSubmitting}
                  onClick={() => {
                    setHasSaved(true);
                    onChange(label);
                  }}
                  variant="text"
                  color="info"
                  size="small"
                >
                  {data?.status === "rejected"
                    ? "Update"
                    : data?.status === "submitted"
                    ? "Update"
                    : "Submit"}
                </LoadingButton>
              )}
            </Typography>
          </Stack>
        )}
      </Box>
    </div>
  );
};

const QuestionStatus = ({ status }) => {
  switch (status) {
    case "approved":
      return (
        <Label
          title="This reponse has been approved and can't be edited"
          color="success"
        >
          {status}
        </Label>
      );
    case "rejected":
      return (
        <Label title="This response requires some changes" color="error">
          {status}
        </Label>
      );
    case "submitted":
      return (
        <Label
          title="The response has been submitted, pending approval"
          color="info"
        >
          {status}
        </Label>
      );
    default:
      return (
        <Label title="question pending response" color="warning">
          {status}
        </Label>
      );
  }
};
