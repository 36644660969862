// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
 
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));


// ----------------------------------------------------------------------
 
export default function AppWeeklySales({data}) {
  let _data = data.filter(function (e) {
    return e.status === "NEW";
  });

  return (
    <RootStyle> 
    <Typography variant="h1">{fShortenNumber(_data.length)}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        New Quotes
      </Typography>
    </RootStyle>
  );
}
