import React from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { sanitiseFieldName } from "src/utils/smartUtils";
import { FileUpload } from "../fileuploads";

export const InputBox = ({
  input,
  readOnly,
  onChange,
  handleInputChange,
  inputValues,
  data,
  projectCode,
  mainLabel,
  ...props
}) => {
  // const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "primary.main",
          },
        },
      }}
    >
      {input.type === "file" ? (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
            {input?.label || "Upload file"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            <FileUpload
              disabled={readOnly}
              type="file"
              mainLabel={mainLabel}
              projectCode={projectCode}
              name={sanitiseFieldName(input?.label)}
              onChange={(e) => {
                handleInputChange(e, input?.label, "file");
              }}
            />
          </Typography>
        </Stack>
      ) : (
        <TextField
          label={input?.label || "Input"}
          value={inputValues?.[sanitiseFieldName(input?.label)]}
          onChange={(e) => {
            handleInputChange(e, input?.label, "text");
          }}
          type={input?.type || "text"}
          placeholder={input?.label || "Input"}
          error={error}
          required={true}
          helperText={helperText}
          variant="outlined"
          fullWidth
          name={sanitiseFieldName(input?.label)}
          disabled={readOnly}
          InputLabelProps={{
            shrink:
              inputValues?.[sanitiseFieldName(input?.label)]?.length > 0
                ? true
                : false,
          }}
          {...props}
        />
      )}
    </Box>
  );
};
