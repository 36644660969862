import { useState, useEffect } from "react";
import { sanitiseFieldName } from "src/utils/smartUtils";
import firebase from "src/firebase";
import { CircularProgress, Typography } from "@mui/material";

export const FileUploadInitialAssess = (props) => {
  const {
    onChange,
    input,
    index,
    disabled,
    name,
    isLoading,
    setIsLoading,
    projectCode,
    mainLabel,
    readOnly,
  } = props;
  const label = sanitiseFieldName(mainLabel);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const handleFileUpload = (e) => {
    // handle file upload and return the url
    const file = e.target.files[0];

    // get file type
    const fileType = file.type;
    const cleanMainLabel = sanitiseFieldName(mainLabel);

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`prelimData/${projectCode}/${file.name}`);
    fileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        // update the database by pushing the url to the database
        firebase
          .firestore()
          .collection("PrelimDataFormsSubmissions")
          .doc(projectCode)
          .set(
            {
              [cleanMainLabel]: {
                [index]: {
                  type: "file",
                  fileName: file.name,
                  fileType: fileType,
                  url,
                },
              },
            },
            { merge: true }
          )
          .then(() => {
            console.log("Data saved successfully");
          });
        onChange(e);
        setFileUrl(url);
      });
    });
  };

  const handleFileDelete = () => {
    setIsLoading(true);
    const cleanMainLabel = sanitiseFieldName(mainLabel);
    firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(projectCode)
      .set(
        {
          [cleanMainLabel]: {
            [index]: null,
          },
        },
        { merge: true }
      )
      .then(() => {
        setIsLoading(false);
        console.log("Data saved successfully");
      });
    setFile(null);
    setIsLoading(false);
  };

  useEffect(() => {
    // check if there is a file in the database for this project
    // if there is, then get the url and set it to the fileUrl state
    // else set the fileUrl state to null
    setIsLoading(true);
    const cleanMainLabel = sanitiseFieldName(mainLabel);
    firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const file = data[cleanMainLabel][index];
          if (file) {
            setFile(file);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setFileUrl(null);
          }
        } else {
          setIsLoading(false);
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      });

    setIsLoading(false);
  }, [projectCode, name, label, mainLabel, index]);

  if (file) {
    return (
      <FilePreview
        file={file}
        disabled={readOnly || disabled}
        onDelete={() => {
          handleFileDelete();
        }}
      />
    );
  }
  if (!readOnly)
    return (
      <input
        disabled={disabled || readOnly}
        type="file"
        name={sanitiseFieldName(name)}
        onChange={(e) => {
          handleFileUpload(e);
        }}
      />
    );

  return null;
  // return (
  //   <Typography variant="body2" color="text.secondary">
  //     No file uploaded
  //   </Typography>
  // );
};

const FilePreview = (props) => {
  return (
    <div className="file-preview">
      <div className="file-preview__icon">
        <i className="fas fa-file"></i>
      </div>

      <div className="file-preview__text">
        <p className="file-preview__text--title">
          <a href={props.file?.url} target="_blank" rel="noreferrer">
            {props.file?.fileName}
          </a>
        </p>
        {/* <p className="file-preview__text--subtitle">{props.file?.size} bytes</p> */}
      </div>

      <div className="file-preview__actions">
        {props.disabled ? null : (
          <button
            className="file-preview__actions--btn"
            onClick={() => {
              props.onDelete();
            }}
          >
            remove
          </button>
        )}
      </div>
    </div>
  );
};
