import { useState, useEffect } from "react";
import { sanitiseFieldName } from "src/utils/smartUtils";
import firebase from "src/firebase";

export const FileUpload = (props) => {
  const { onChange, input, disabled, name, projectCode, mainLabel } = props;
  const label = sanitiseFieldName(mainLabel);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const handleFileUpload = (e) => {
    // handle file upload and return the url

    const file = e.target.files[0];

    // get file type
    const fileType = file.type;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${projectCode}/${file.name}`);
    fileRef.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => {
        // update the database by pushing the url to the database
        firebase
          .firestore()
          .collection("edge_project_data")
          .doc(projectCode)
          .set(
            {
              [label]: {
                [name]: {
                  type: "file",
                  fileName: file.name,
                  fileType: fileType,
                  url,
                },
              },
            },
            { merge: true }
          )
          .then(
            () => {
              console.log("Data saved successfully");
            },
            (error) => {
              console.log("Firebase error:",error);
            }
          );
        onChange(e);
        setFileUrl(url);
      });
    });
  };

  useEffect(() => {
    // check if there is a file in the database for this project
    // if there is, then get the url and set it to the fileUrl state
    // else set the fileUrl state to null
    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const file = data[label][name];
          if (file) {
            setFile(file);
          } else {
            setFileUrl(null);
          }
        } else {
          // doc.data() will be undefined in this case
          // console.log("No such document!");
        }
      });
  }, [projectCode, name, label]);

  if (file) {
    console.log({ file }, "file here");
    return (
      <FilePreview
        file={file}
        disabled={disabled}
        onDelete={() => {
          setFile(null);
        }}
      />
    );
  }
  return (
    <input
      disabled={disabled}
      type="file"
      name={sanitiseFieldName(name)}
      onChange={(e) => {
        handleFileUpload(e);
      }}
    />
  );
};

const FilePreview = (props) => {
  return (
    <div className="file-preview">
      <div className="file-preview__icon">
        <i className="fas fa-file"></i>
      </div>

      <div className="file-preview__text">
        <p className="file-preview__text--title">
          <a href={props.file?.url} target="_blank" rel="noreferrer">
            {props.file?.fileName}
          </a>
        </p>
        {/* <p className="file-preview__text--subtitle">{props.file?.size} bytes</p> */}
      </div>

      <div className="file-preview__actions">
        {props.disabled ? null : (
          <button
            className="file-preview__actions--btn"
            onClick={() => {
              props.onDelete();
            }}
          >
            remove
          </button>
        )}
      </div>
    </div>
  );
};
