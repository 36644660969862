import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Label from "src/components/Label";
import {
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
} from "src/config/constants";
import firebase from "src/firebase";
import { useNavigate } from "react-router-dom";

export const InitialAssessMent = ({ projectCode, fromClient = false }) => {
  const [project, setProject] = useState(null);
  const [progress, setprogress] = useState(false);
  const navigate = useNavigate();

  const handleNavigateToData = () => {
    console.log(project?.status, "project?.status");
    if (
      (project?.status === PROJECT_STATUS.NEW && fromClient) ||
      (project?.status === INITIAL_ASSESSEMENT_STATUS.GET_STARTED &&
        fromClient) ||
      (project?.status === INITIAL_ASSESSEMENT_STATUS.PENDING_AUDIT &&
        fromClient)
    )
      return;
    const clientUrl = `/client/dashboard/initial-assesment/${projectCode}`;
    const adminUrl = `/projects/initial-assesment/${projectCode}`;
    const url = fromClient ? clientUrl : adminUrl;

    navigate(url);
  };

  const determineDisabled = (status) => {
    if (
      (status === INITIAL_ASSESSEMENT_STATUS.GET_STARTED && fromClient) ||
      status === INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
    )
      return true;
    return false;
  };
  // useEffect(() => {
  //   setprogress(true);
  //   firebase
  //     .firestore()
  //     .collection("PrelimDataForms")
  //     .doc(projectCode)
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         setProject(doc.data());
  //         setprogress(false);

  //         console.log(doc.data(), "documnet found InitialAssessMent");
  //       } else {
  //         // check if project is comleted and update prelimary data form to start of get started
  //         firebase
  //           .firestore()
  //           .collection("projects")
  //           .where("code", "==", projectCode)
  //           .get()
  //           .then((querySnapshot) => {
  //             querySnapshot.forEach((doc) => {
  //               console.log(doc.data(), "documnet found");
  //               const projectId = doc.id;
  //               if (
  //                 doc.data().status === "Completed" ||
  //                 doc.data().status === PROJECT_STATUS.CLIENT_APPROVED ||
  //                 doc.data().status === PROJECT_STATUS.COMPLETED
  //               ) {
  //                 firebase
  //                   .firestore()
  //                   .collection("PrelimDataForms")
  //                   .doc(projectCode)
  //                   .set({
  //                     status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
  //                     projectCode: projectCode,
  //                     projectId: projectId,
  //                   })
  //                   .then(() => {
  //                     console.log("Document successfully written!");
  //                     setProject({
  //                       status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
  //                     });
  //                     setprogress(false);
  //                   })
  //                   .catch((error) => {
  //                     console.error("Error writing document: ", error);
  //                     setprogress(false);
  //                   });
  //               } else {
  //                 firebase
  //                   .firestore()
  //                   .collection("PrelimDataForms")
  //                   .doc(projectCode)
  //                   .set({
  //                     status: INITIAL_ASSESSEMENT_STATUS.NOT_STARTED,
  //                     projectCode: projectCode,
  //                     projectId: projectId,
  //                   })
  //                   .then(() => {
  //                     console.log("Document successfully written!");
  //                     setProject({
  //                       status: INITIAL_ASSESSEMENT_STATUS.NOT_STARTED,
  //                     });
  //                     setprogress(false);
  //                   })
  //                   .catch((error) => {
  //                     console.error("Error writing document: ", error);
  //                     setprogress(false);
  //                   });
  //               }
  //             });
  //           });
  //       }
  //     });
  // }, [projectCode]);

  useEffect(() => {
    setprogress(true);

    const unsub = firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setprogress(false);
          console.log(doc.data(), "documnet found InitialAssessMent");
        } else {
          // check if project is comleted and update prelimary data form to start of get started
          firebase
            .firestore()
            .collection("projects")
            .where("code", "==", projectCode)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                console.log(doc.data(), "documnet found");
                const projectId = doc.id;
                if (
                  doc.data().status === "Completed" ||
                  doc.data().status === PROJECT_STATUS.CLIENT_APPROVED ||
                  doc.data().status === PROJECT_STATUS.COMPLETED
                ) {
                  firebase
                    .firestore()
                    .collection("PrelimDataForms")
                    .doc(projectCode)
                    .set({
                      status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
                      projectCode: projectCode,
                      projectId: projectId,
                    })
                    .then(() => {
                      console.log("Document successfully written!");
                      setProject({
                        status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
                      });
                      setprogress(false);
                    })
                    .catch((error) => {
                      console.error("Error writing document: ", error);
                      setprogress(false);
                    });
                } else {
                  firebase
                    .firestore()
                    .collection("PrelimDataForms")
                    .doc(projectCode)
                    .set({
                      status: INITIAL_ASSESSEMENT_STATUS.NOT_STARTED,
                      projectCode: projectCode,
                      projectId: projectId,
                    })
                    .then(() => {
                      console.log("Document successfully written!");
                      setProject({
                        status: INITIAL_ASSESSEMENT_STATUS.NOT_STARTED,
                      });
                      setprogress(false);
                    })
                    .catch((error) => {
                      console.error("Error writing document: ", error);
                      setprogress(false);
                    });
                }
              });
            });
        }
      });

    return () => {
      unsub();
    };
  }, [projectCode]);

  return (
    <Stack
      sx={{
        width: "auto",
        minWidth: "300px",
        py: 1,
        px: 3,
        bgcolor:
          project?.status === "Not Started" ||
          project?.status === INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
            ? null
            : "background.paper",
        borderRadius: 1,
        boxShadow:
          project?.status === "Not Started" ||
          project?.status === INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
            ? 0
            : 1,
        border:
          project?.status === "Not Started" ||
          project?.status === INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
            ? 0
            : 1,
        borderColor:
          project?.status === PROJECT_STATUS.CLIENT_APPROVED ||
          INITIAL_ASSESSEMENT_STATUS.COMPLETED
            ? "success.main"
            : project?.status === PROJECT_STATUS.IN_PROGRESS
            ? "warning.main"
            : project?.status === "Not Started"
            ? "white"
            : "info.main",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mr: 2,
          color:
            project?.status === "Not Started" ||
            INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
              ? "gray"
              : null,
        }}
      >
        Initial Assessment
      </Typography>

      <LoadingButton
        onClick={handleNavigateToData}
        disabled={determineDisabled(project?.status || "Not Started")}
        color={
          project?.status === PROJECT_STATUS.CLIENT_APPROVED ||
          INITIAL_ASSESSEMENT_STATUS.COMPLETED
            ? "success"
            : project?.status === PROJECT_STATUS.IN_PROGRESS
            ? "warning"
            : "info"
        }
        size="small"
        loading={progress}
        variant="outlined"
      >
        {project?.status === PROJECT_STATUS.CLIENT_APPROVED ||
        project?.status === INITIAL_ASSESSEMENT_STATUS.COMPLETED
          ? "Completed"
          : project?.status === PROJECT_STATUS.IN_PROGRESS
          ? "In Progress"
          : project?.status === "Not Started" ||
            project?.status === INITIAL_ASSESSEMENT_STATUS.NOT_STARTED
          ? "Not Started"
          : project?.status === INITIAL_ASSESSEMENT_STATUS.GET_STARTED &&
            fromClient
          ? "Get Started"
          : project?.status ===
            INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE
          ? !fromClient
            ? "Waiting for Client Approval"
            : "Approve forms"
          : "Pending"}
      </LoadingButton>
    </Stack>
  );
};
