import {
  Link,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";
import emailjs from "@emailjs/browser";
import ReactPDF, { Document, usePDF, Text } from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import { useSnackbar } from "notistack";

export default function ClientCreate() {
  const notify = (msg) => toast(msg);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = useState("");
  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreateClient = () => {
    setprogress(true);
    setmsg("Creating client...");

    // Check if user with email already exists
    firebase
      .firestore()
      .collection("clients")
      .where("email", "==", values.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // User with email already exists
          setprogress(false);
          enqueueSnackbar("User with this email already exists", {
            variant: "error",
          });
        } else {
          // User with email doesn't exist, add it
          firebase
            .firestore()
            .collection("clients")
            .add({
              firstName: values.first_name,
              lastName: values.last_name,
              email: values.email,
              phone: values.phone,
              type: "client",
              createdAt: new Date(Date.now()).toLocaleString(),
            })
            .then((docRef) => {
              setprogress(false);
              enqueueSnackbar("Client created successfully", {
                variant: "success",
              });
              navigate("/clients");
            })
            .catch((error) => {
              setprogress(false);
              enqueueSnackbar("Error creating client", { variant: "error" });
            });
        }
      })
      .catch((error) => {
        setprogress(false);
        notify("Error checking for existing user");
      });
  };

  return (
    <Page title="Dashboard">
      <Container>
        {/* back button */}
        <Button
          color="primary"
          size="medium"
          variant="text"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Stack direction="row" justifyContent="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add client
          </Typography>
        </Stack>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={8}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    onChange={handleChange("first_name")}
                    label="First Name"
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange("last_name")}
                    label="Last Name"
                  />
                  <TextField
                    fullWidth
                    type="email"
                    onChange={handleChange("email")}
                    label="Email Address"
                  />
                  <TextField
                    fullWidth
                    type="phone"
                    onChange={handleChange("phone")}
                    label="Phone number"
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={progress}
                    onClick={handleCreateClient}
                  >
                    Add client
                  </LoadingButton>
                </Stack>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
