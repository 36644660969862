import { Link as RouterLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";

import { baseUrl } from "src/config";

import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";
import emailjs from "@emailjs/browser";
import ReactPDF, { Document, usePDF, Text } from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoicePDF from "../InvoicePDF";
import useConstants from "../../../hooks/useConstants";
import TransitionsDialogs from "src/components/NotifContainer";

export default function ClientCreate() {
  const settings = useConstants().docs;
  const notify = (msg) => toast(msg);
  const [officeValues, setOfficeValues] = useState(null);
  const [residentialValues, setresidentialValues] = useState(null);
  const [warehouseValues, setWarehouseValues] = useState(null);
  const [values, setValues] = useState("");
  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");
  const [triggerNotif, setTriggerNotif] = useState(true);

  const [checked, setChecked] = useState(false);
  let publicKey = "WffsPR6cSS6IwClRB";
  const [options] = useState([null, "Office", "Residential", "Warehouse"]);

  const handleChange = (prop) => (event) => {
    if (prop === "type") {
      setOfficeValues(null);
      setWarehouseValues(null);
      setresidentialValues(null);
    }

    setValues({ ...values, [prop]: event.target.value });
  };

  async function getBlob(doc, data, id) {
    setmsg("Creating PDF");
    const blob = await ReactPDF.pdf(<InvoicePDF data={data} />)
      .toBlob()
      .then((res) => {
        sendEmail(res, doc, id);
      })
      .catch((err) => {
        console.log(err);
      });
    return blob;
  }

  const sendEmail = (blob, quote, id) => {
    setmsg("Sending Email");
    let storageRef = firebase.storage().ref().child(`${id}_SOLID_EDGE_QUOTE`);
    storageRef.put(blob).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        console.log(percentage);
      },
      (err) => {
        notify(err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        var file = await convertBlobToBase64(blob);

        firebase
          .firestore()
          .collection("quotes")
          .doc(quote.id)
          .update({
            quote_file: url,
          })
          .then((doc) => {
            let totals = null;

            if (values.type === "Office") {
              totals = officeValues.TotalFees;
            }
            if (values.type === "Warehouse") {
              totals = warehouseValues.TotalFees;
            }
            if (values.type === "Residential") {
              totals = residentialValues.TotalFees;
            }

            const invoiceUrl = `${baseUrl()}/invoice/${id}`;

            let obj = {
              id: id,
              invoiceUrl,
              email: values.email,
              client: values.client,
              project: values.project,
              total: totals,
              breakdown:
                values.type === "Office" || values.type === "Warehouse"
                  ? `Gross Floor Area ${values.gfa}`
                  : `Topology : ${values.typology}, Units : ${values.units}`,

              link: url,
              content: file,
            };

            console.log(url, "url <<<<<");
            setmsg("Uploading files to server");
            emailjs
              .send("service_6a716og", "template_galhv3r", obj, publicKey)
              .then(
                (result) => {
                  setmsg("Email has been sent.");
                  setValues([]);
                  setresidentialValues(null);
                  setWarehouseValues(null);
                  setOfficeValues(null);
                  console.log(result);

                  setprogress(false);
                  setmsg("");
                  setTriggerNotif(true);
                },
                (error) => {
                  console.log(error.text);
                }
              );
          });

        console.log(url);
      }
    );
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const convertBlobToBase64 = async (blob) => {
    // blob data
    return await blobToBase64(blob);
  };

  // calculate for office
  const calculateOffice = (gfa) => {
    setWarehouseValues(null);
    setresidentialValues(null);
    let officeValues = settings[0];

    let DPC =
      (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
        0.00007 * Math.pow(parseFloat(gfa), 2) +
        2.6441 * parseFloat(gfa) +
        20629) *
      officeValues.PC;

    let data = {
      SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
      DPC: parseFloat(DPC.toFixed(2)),
      DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      ExpertServices:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
      PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      AuditorServices:
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
      PMS_Enabled: checked,
      TotalFees:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
        (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
    };

    setOfficeValues(data);
    return data;
  };

  // calculate for residential
  const calculateResidential = (typology, units) => {
    setOfficeValues(null);
    setWarehouseValues(null);
    let officeValues = settings[1];
    let eight = units / typology;
    let ES =
      16555 +
      officeValues.DPC * parseInt(typology) +
      officeValues.DCPCC * parseInt(typology);

    let AS =
      officeValues.DPC * officeValues.PA * typology +
      officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1));

    let data = {
      ExpertServices: ES,
      AuditUnits: parseFloat(typology * (Math.sqrt(eight) + 1)).toFixed(0),
      IA: 16555,
      DPC: parseFloat(officeValues.DPC * typology).toFixed(2),
      DCPCC: parseFloat(officeValues.DCPCC * typology).toFixed(2),
      AuditorServices: AS,
      PA: parseFloat(officeValues.DPC * officeValues.PA * typology).toFixed(2),
      PCA: parseFloat(
        officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1))
      ).toFixed(2),
      TotalFees: (ES + AS).toFixed(2),
    };

    console.log(data);
    setresidentialValues(data);
    return data;
  };

  // calculate for office
  const calculateWarehouse = (gfa) => {
    setOfficeValues(null);
    setresidentialValues(null);
    let officeValues = settings[2];

    let DPC =
      (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
        0.00007 * Math.pow(parseFloat(gfa), 2) +
        2.6441 * parseFloat(gfa) +
        20629) *
      officeValues.PC;

    let data = {
      SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
      DPC: parseFloat(DPC.toFixed(2)),
      DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      ExpertServices:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
      PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
      PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      AuditorServices:
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
      PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
      PMS_Enabled: checked,
      TotalFees:
        parseFloat((DPC * officeValues?.SC).toFixed(2)) +
        parseFloat(DPC.toFixed(2)) +
        parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
        parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
        parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
        (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
    };

    setWarehouseValues(data);
    return data;
  };

  const calculate = () => {
    if (values.gfa === undefined) {
      alert("Enter a  value");
      return;
    }
    calculateOffice(values.gfa);
  };

  const calculateR = () => {
    if (
      values.typology === undefined ||
      values.units === undefined ||
      values.email === undefined
    ) {
      alert("Enter your typology and units.");
      return;
    }
    calculateResidential(values.typology, values.units);
  };

  const calculateW = () => {
    if (values.gfa === undefined || values.email === undefined) {
      alert("Enter a GFA value");
      return;
    }
    calculateWarehouse(values.gfa);
  };

  const createQuote = () => {
    const id = Date.now();

    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    setprogress(true);
    setmsg("Putting things together");
    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      user_id: firebase.auth().currentUser.uid,
      quote_source: "OUTBOUND",
      ...calculateOffice(values.gfa),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    console.log(asd);
    firebase
      .firestore()
      .collection("quotes")
      .add(asd)
      .then((doc) => {
        setmsg("Sending data to the Database");
        return getBlob(doc, asd, id);
      });
  };

  const createWarehouseQuote = () => {
    const id = Date.now();

    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    setprogress(true);
    setmsg("Putting things together");
    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      quote_source: "OUTBOUND",
      ...calculateWarehouse(values.gfa),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    console.log(asd);
    firebase
      .firestore()
      .collection("quotes")
      .add(asd)
      .then((doc) => {
        setmsg("Sending data to the Database");
        return getBlob(doc, asd, id);
      });
  };

  const createResidentialQuote = () => {
    const id = Date.now();
    setprogress(true);
    setmsg("Putting things together");
    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    let asd = {
      ...values,
      quote_id: id,
      status: "NEW",
      quote_source: "OUTBOUND",
      ...calculateResidential(values.typology, values.units),
      createdAt: new Date(Date.now()).toLocaleDateString(),
    };

    firebase
      .firestore()
      .collection("quotes")
      .add(asd)
      .then((doc) => {
        setmsg("Sending data to the Database");
        return getBlob(doc, asd, id);
      });
  };

  return (
    <Page title="Dashboard">
      <TransitionsDialogs
        open={triggerNotif}
        setOpen={setTriggerNotif}
        title="Quote sent to email"
        description="We have sent a quotation to your email, Kindly check and follow the instruction. Thank you"
      />
      {progress && (
        <div
          style={{
            position: "absolute",
            zIndex: 10000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
          }}
        >
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Typography variant="h4">Processing...</Typography>
            <Typography variant="body1">{msg}</Typography>
          </Stack>
        </div>
      )}

      <Container>
        <Stack direction="row" justifyContent="center" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Project
          </Typography>
        </Stack>

        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={8}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    onChange={handleChange("client")}
                    label="Client Name"
                  />
                  <TextField
                    fullWidth
                    type="email"
                    onChange={handleChange("email")}
                    label="Email Address"
                  />

                  <Button
                    fullWidth={false}
                    variant="contained"
                    onClick={() => createResidentialQuote()}
                  >
                    Submit
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
