import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Label from "src/components/Label";
import { ROUND1_STATUS } from "src/config/constants";
import useLatestRoundStatus from "../../../hooks/useLatestRoundStatus";

import firebase from "src/firebase";

export const RoundOneSubmission = ({
  projectCode,
  setOpen,
  fromClient = false,
}) => {
  const [project, setProject] = useState(null);
  const [status, setStatus] = useState(null);
  const [progress, setprogress] = useState(false);
  const [round, setRound] = useState(1);

  // const {
  //   round,
  //   status: roundStatus,
  //   rejectionReason,
  //   progress: roundStatusProgress,
  // } = useLatestRoundStatus(projectCode);

  const handleComfirmSubmission = () => {
    setOpen(true);
  };
  // [TODO: rewrite and del] useEffect(() => {
  //   firebase
  //     .firestore()
  //     .collection("Round1")
  //     .doc(projectCode)
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         setProject(doc.data());
  //         const status = doc.data()?.status || ROUND1_STATUS.NOT_STARTED;
  //         setStatus(status);
  //         setprogress(false);
  //       } else {
  //         setStatus(ROUND1_STATUS.NOT_STARTED);
  //       }
  //     });
  // }, [projectCode]);

  useEffect(() => {
    const db = firebase.firestore();
    const docRef = db.collection("Round1").doc(projectCode);

    const unsubscribe = docRef.onSnapshot((doc) => {
      if (doc.exists) {
        setProject(doc.data());
        const status = doc.data()?.status || ROUND1_STATUS.NOT_STARTED;
        setStatus(status);
        setprogress(false);
      } else {
        setStatus(ROUND1_STATUS.NOT_STARTED);
      }
    });

    return unsubscribe;
  }, [projectCode]);

  useEffect(() => {
    const db = firebase.firestore();
    let latestRound = 1;
    let latestStatus = ROUND1_STATUS.NOT_STARTED;

    const checkRound = (roundNumber) => {
      return db
        .collection(`Round${roundNumber}`)
        .doc(projectCode)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const status = doc.data()?.status || ROUND1_STATUS.NOT_STARTED;
            if (status !== ROUND1_STATUS.NOT_STARTED) {
              latestRound = roundNumber;
              latestStatus = status;
            }
          }
        });
    };

    Promise.all([checkRound(1), checkRound(2), checkRound(3)])
      .then(() => {
        setRound(latestRound);
        setStatus(latestStatus);
        setprogress(false);
      })
      .catch((error) => {
        console.error("Error checking rounds: ", error);
      });
  }, [projectCode]);

  // if (roundStatusProgress) {
  //   console.log(roundStatus, "round", round, "<<<<<<<<<<");
  //   // console.log({ round }, { roundStatus }, { rejectionReason }, { progress });
  // }
  return (
    <Stack
      sx={{
        width: "auto",
        minWidth: "300px",
        py: 1,
        px: 3,
        bgcolor:
          status === ROUND1_STATUS.NOT_STARTED ? null : "background.paper",
        borderRadius: 1,
        boxShadow: status !== ROUND1_STATUS.GET_STARTED ? 0 : 1,
        border: status !== ROUND1_STATUS.GET_STARTED ? 0 : 1,
        borderColor:
          status === ROUND1_STATUS.GET_STARTED
            ? "warning.main"
            : status === ROUND1_STATUS.COMPLETED
            ? "success.main"
            : "info.main",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mr: 2,
          color:
            status === ROUND1_STATUS.NOT_STARTED
              ? "gray"
              : status === ROUND1_STATUS.GET_STARTED
              ? "warning.main"
              : "success.main",
        }}
      >
        Round submission
      </Typography>

      <LoadingButton
        disabled={status === ROUND1_STATUS.NOT_STARTED || fromClient}
        color={
          status === ROUND1_STATUS.CLIENT_APPROVED
            ? "success"
            : status === ROUND1_STATUS.GET_STARTED
            ? "warning"
            : "info"
        }
        size="small"
        loading={progress}
        variant="outlined"
        onClick={handleComfirmSubmission}
      >
        {status === ROUND1_STATUS.GET_STARTED
          ? "Pending"
          : status === ROUND1_STATUS.COMPLETED
          ? "Completed"
          : "Not Started"}
      </LoadingButton>

      {/* {progress ? (
        <CircularProgress size={12} />
      ) : (
        <Label color="info">
          {project?.status === PROJECT_STATUS.CLIENT_APPROVED
            ? "Completed"
            : project?.status}
        </Label>
      )} */}
    </Stack>
  );
};
