import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import firebase from "../../../firebase";
import {
  Stack,
  Typography,
  TextField,
  Grid,
  Card,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ClientEdit() {
  const navigate = useNavigate();
  const [progress, setprogress] = React.useState(false);
  const [client, setClient] = React.useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);
  const [clientFirstName, setClientFirstName] = useState(null);
  const [clientLastName, setClientLastName] = useState(null);

  const [values, setValues] = useState("");
  // get the project id from the url
  const location = useLocation();
  const email = location.pathname.split("/")[3];

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleUpdateClient = () => {
    setprogress(true);
    firebase
      .firestore()
      .collection("clients")
      .doc(client.id)
      .update({
        firstName: clientFirstName,
        lastName: clientLastName,
        email: clientEmail,
        phone: clientPhone,
        type: "client",
        createdAt: new Date(Date.now()).toLocaleString(),
      });

    setprogress(false);
    navigate("/clients");
  };
  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("clients")
      .where("email", "==", email)
      .onSnapshot((snapshot) => {
        const newClient = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClient(newClient[0]);
        setClientFirstName(newClient[0].firstName || "");
        setClientLastName(newClient[0].lastName || "");
        setClientEmail(newClient[0].email || "");
        setClientPhone(newClient[0].phone || "");
      });

    return () => unsubscribe();
  }, []);

  return (
    <div>
      <Link to="/clients">Go back</Link> <br />
      <Stack direction="row" justifyContent="center" mb={5}>
        {/* <Stack direction="row" justifyContent="center" mb={5}>
          <Button
            variant="contained"
            onClick={() => navigate(`/quote/create?email=${email}`)}
          >
            create quote
          </Button>

          <Button
            variant="contained"
            onClick={() => navigate(`/clients/quotes/${email}`)}
          >
            View quotes
          </Button>
        </Stack> */}
        <br />
      </Stack>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Update client
          </Typography>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setClientFirstName(e.target.value);
                  }}
                  label="First Name"
                  value={clientFirstName}
                  InputLabelProps={{
                    shrink: clientFirstName ? true : false,
                  }}
                />
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setClientLastName(e.target.value);
                  }}
                  label="Last Name"
                  value={clientLastName}
                  InputLabelProps={{
                    shrink: clientLastName ? true : false,
                  }}
                />
                <TextField
                  fullWidth
                  type="email"
                  label="Email Address"
                  value={clientEmail}
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: clientEmail ? true : false,
                  }}
                />

                <TextField
                  fullWidth
                  type="number"
                  value={clientPhone}
                  onChange={(e) => {
                    setClientPhone(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: clientPhone ? true : false,
                  }}
                  label={"Client Phone"}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={progress}
                  onClick={handleUpdateClient}
                >
                  Update client
                </LoadingButton>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
