import { useState, useEffect } from "react";
// material
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Stack,
  Grid,
  LinearProgress,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import firebase from "src/firebase";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import emailjs from "@emailjs/browser";
import { baseUrl } from "src/config";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
const Professionals = [
  "Architect",
  "Mechanical Engineer",
  "Electrical Engineer",
  "Wet Services Engineer",
  "PV Consultant",
  "Project Manager",
  "Landscape Architect/Planner",
  "Structural Engineer",
];
export default function TeamDialogue({
  open,
  setOpen,
  users,
  setUsers,
  projectId,
  projectCode,
  fromAdmin = false,
}) {
  const [professional, setProfessional] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let publicKey = "WffsPR6cSS6IwClRB";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value);
    setProfessional(event.target.value);
  };
  const handleRemoveProfessional = (email) => {
    setIsLoading(true);

    // professional is an array of objects

    const filteredProfessionals = users?.filter(
      (professional) => professional?.email !== email
    );

    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .update({
        professionals: filteredProfessionals,
      })
      .then(() => {
        setIsLoading(false);
        setUsers(filteredProfessionals);
        // alert("Professional removed successfully");
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  const handleAddProfessional = (e) => {
    setIsLoading(true);
    e.preventDefault();
    setIsLoading(true);
    // check if the required fields are filled
    // if not, show an error message

    if (email === "" || !email) {
      alert("Please fill in the email before saving");
      setIsLoading(false);
      return;
    }

    // prevent the user from adding the same professional twice
    const isProfessionalAlreadyAdded = users?.find(
      (professional) => professional?.email === email
    );

    if (isProfessionalAlreadyAdded) {
      alert("This professional has already been added");
      setIsLoading(false);
      return;
    }

    // professional is an array of objects
    // [{name: "Architect", email: "email"}]
    const newProfessional = {
      name,
      email,

      phone,
      profession: professional,
    };

    let professionals;

    // check if users is an array of objects
    if (users?.length > 0) {
      professionals = [...users, newProfessional];
    } else {
      professionals = [newProfessional];
    }

    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .update({
        professionals,
      })
      .then(() => {
        emailjs
          .send(
            "service_6a716og",
            "template_5cn6kxi",
            {
              projectCode,
              to_name: professional,
              link_to_project: `${baseUrl()}/client/dashboard/${projectCode}?email=${email}&name=${professional}`,
              to_email: email,
            },
            publicKey
          )
          .then(
            (result) => {
              setUsers(professionals);
              // setmsg("Email has been sent.");
              // setprogress(false);
              // notify("Email has been sent.");

              // clear the form

              setName("");
              setEmail("");
              setPhone("");
              setProfessional("");
              console.log(result.text);
            },
            (error) => {
              console.log("error thhrown");
              console.log(error);
            }
          );
        setIsLoading(false);
        // alert("Professional added successfully");
      })
      .catch((error) => {
        setIsLoading(false);
        alert(error.message);
      });
  };

  // use useEffect to rerender the component when the users array changes
  useEffect(() => {
    // console.log("users", users);
  }, [users]);

  return (
    <div
      style={{
        backgroundColor: "red",
      }}
    >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {!fromAdmin ? "Add Professionals" : "Professionals"}
        </DialogTitle>
        <DialogContent>
          {!fromAdmin && (
            <DialogContentText>
              Professional will be able to access this project and submit data.
              Add atleast one professional to this project to continue.
            </DialogContentText>
          )}
          {isLoading ? (
            <div
              style={{
                width: "100%",
                height: 184,
              }}
            >
              <LinearProgress color="inherit" />
            </div>
          ) : (
            !fromAdmin && (
              <form
                onSubmit={handleAddProfessional}
                style={{
                  // width: "100%",
                  display: "flex",
                  width: "100%",
                  padding: 20,
                }}
                md={12}
              >
                <Grid container md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    placeholder={`Enter full name`}
                    type="name"
                    sx={{ width: "90%", mt: 2 }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={professional}
                    // label="Profession"
                    onChange={handleChange}
                    sx={{ width: "90%", mt: 2 }}
                    // set a default value

                    // sx={{ width: 300 }}
                  >
                    <br />

                    {Professionals.map((professional) => (
                      <MenuItem value={professional}>{professional}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid container md={6}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    placeholder={`Enter email`}
                    type="email"
                    sx={{ width: "90%", mt: 2 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Phone"
                    variant="outlined"
                    placeholder={`Enter phone number`}
                    type="phone"
                    sx={{ width: "90%", mt: 2 }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <br />
                <br />
              </form>
            )
          )}

          <Stack direction="row" spacing={2}>
            <TableDisplayer
              users={users}
              handleRemoveProfessional={handleRemoveProfessional}
              fromAdmin={fromAdmin}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            close
          </Button>
          {!fromAdmin && (
            <LoadingButton
              loading={isLoading}
              onClick={handleAddProfessional}
              variant="contained"
            >
              Add
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const TableDisplayer = ({ users, handleRemoveProfessional, fromAdmin }) => {
  if (!users) {
    return <div>No users</div>;
  }
  return (
    <TableContainer sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((row) => (
            <TableRow key={row.name}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.profession}</TableCell>
              {!fromAdmin && (
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleRemoveProfessional(row.email)}
                    size="small"
                    color="error"
                  >
                    Remove
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
