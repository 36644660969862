export const PrelimDataForms = [
  {
    label: "EEM01 - Window-to-Wall Ratio",
    documentsRequired: [
      "Calculation of “Glazing Area” and “Gross Exterior Wall Area” for each façade of the building and the average area weighted WWR",
      "All façade elevation drawings showing glazingdimensions and general building dimensions",
    ],
  },
  {
    label: "EEM04 - External Shading",
    documentsRequired: [
      "All façade elevation drawings highlighting the provision of horizontal and vertical shading devices",
      "Window details clearly showing the depth of the shading device and the calculation of the proportion",
    ],
  },
  {
    label: "EEM05 - Insulation of Roof",
    documentsRequired: [
      "Building plans highlighting the area of major roof types if more than one type of roof is present",
      "Detailed drawing(s) showing the layers of roof materials and any U-value specifications",
      "Calculation of overall roof U-value using either the calculator provided in the EDGE measure or external calculations",
      "Manufacturer's data sheets for the specified building materials; or Bill of quantities with the specifications for any roof insulation materials clearly highlighted",
    ],
    // designSpecification: "0.33W/m2.K",
  },
  {
    label: "EEM08 - Insulation of Exterior Walls",
    documentsRequired: [
      "Building plans highlighting the area of major exterior wall types if more than one type of wall is present",
      "Detailed drawing(s) showing the layers of exterior wall materials and any U-value specifications",
      "Calculation of overall exterior wall U-value using either the calculator provided in the EDGE measure or external calculations",
      "Manufacturer's data sheets for the specified building materials showing the brand and product name and insulating properties of any insulation; or Bill of quantities with the specifications for any exterior wall insulation materials clearly highlighted",
    ],
  },
  {
    label: "EEM09 - Efficiency of Glass",
    documentsRequired: [
      "Manufacturer's data sheets showing the seasonal average U-value for the window (including glass and frame) and the solar heat gain coefficient (SHGC) of the glass and frame types",
      "A list of different types of windows included in the design (window schedule).",
    ],
  },
  {
    label: "EEM11 - Natural Ventilation",
    documentsRequired: [
      "Typical floor plans for every floor showing the layouts of the naturally ventilated spaces and the location of openings",
      "Typical sections showing the floor-to-ceiling height for every floor",
      "Calculations within or outside the EDGE App showing that the minimum natural ventilation requirements have been met",
    ],
  },
  {
    label: "EEM12 - Ceiling Fans",
    documentsRequired: [
      "Mechanical and electrical layout drawings showing the location and number of ceiling fans",
      "Manufacturer’s data sheets showing the energy consumption and diameter of ceiling fans selected",
    ],
  },
  {
    label: "EEM13 - Cooling System Efficiency",
    documentsRequired: [
      "Mechanical and electrical layout drawings showing the location of the external and internal components of the space cooling equipment for all floors",
      "Equipment Schedule or Manufacturer’s data sheets (with the project specific info highlighted & noted) for the space cooling system specifying efficiency information ",
      "For systems including more than one type or size of space cooling system, the design team must provide the weighted average efficiency",
    ],
  },
  {
    label: "EEM16 - Space Heating System",
    documentsRequired: [
      "Mechanical and electrical layout drawings showing the location of the external and internal components of the space heating equipment for all floors",
      "Equipment Schedule or Manufacturer’s data sheets (with the project specific info highlighted & noted) for the space heating system specifying efficiency information",
      "For systems including more than one type or size of space heating system, the design team must provide the weighted average efficiency calculations, calculated either within or outside the EDGE App",
    ],
  },
  {
    label: "EEM18 - Domestic Hot Water (DHW) System",
    documentsRequired: [
      "Electrical layout drawings showing the location and type of all interior lighting fixtures",
      "Lighting schedule listing the type and number of lamps specified for all fixtures",
      "Manufacturer’s data sheets or calculations showing that the lamps meet the minimum lumens per watt threshold.",
    ],
  },
  {
    label: "EEM23 Efficient Lighting for External Areas",
    documentsRequired: [
      "Electrical layout drawings showing the location and type of all interior lighting fixtures",
      "Lighting schedule listing the type and number of lamps specified for all fixtures",
      "Manufacturer’s data sheets or calculations showing that the lamps meet the minimum lumens per watt threshold",
    ],
  },
  {
    label: "EEM24 Lighting Controls",
    documentsRequired: [
      "Electrical layout drawings showing the location and type of all lighting controls",
      "Lighting schedule listing the specifications for all controls, if applicable",
      "Manufacturer’s data sheets for the lighting controls",
    ],
  },
  {
    label: "EEM31 Smart Meters for Energy",
    documentsRequired: [
      "Electric drawings/specifications showing the make and model of the smart energy meters and the connection with the electric system",
      "Manufacturer’s data sheets of the meters",
    ],
  },
  {
    label: "Efficient Refrigerators and washing machines",
    documentsRequired: [
      "Summary list of the refrigerators and clothes washing machines to be installed in the building, including quantity, energy use, and proof of certification by Energy Star, EU Energy Efficiency Labeling Scheme, Energy Technology Product List (ETL), or equivalent",
      "Manufacturer's specifications of the refrigerators and clothes washing machines",
    ],
  },
  {
    label: "WEM01 - Water-efficient Showerheads",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model, and flow rate of the showerhead(s)",
      "Manufacturer's data sheets for the specified showerhead(s) confirming the flow rate at a standard pressure of 3 bar.",
    ],
  },
  {
    label: "WEM02 - Water-efficient Faucets for all Bathrooms",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model, and flow rate of the washbasin faucet(s)",
      "Manufacturer's data sheets for the specified faucet(s)/flow aerator(s) confirming the flow rate at a standard pressure of 3 bar",
    ],
  },
  {
    label: "WEM04 - Efficient Water Closets for All Bathrooms",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model and flush volumes of water closet(s)",
      "Manufacturer's data sheets for the specified water closet(s) with information on the flush volume of the main and reduced flushes.",
    ],
  },
  {
    label: "WEM06 - Water Efficient Bidets",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model and flush volumes of water bidet(s)",
      "Manufacturer's data sheets for water bidet(s) with information on the flush volume of the main and reduced flushes.",
    ],
  },
  {
    label: "WEM07 - Water-efficient Urinals",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model and flush volumes of urinal(s)",
      "Manufacturer's data sheets for the specified urinal(s) with information on the flush volume of the main and reduced flushes.",
    ],
  },
  {
    label: "WEM08 - Water-efficient Faucets for Kitchen Sinks",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model and flow rate of kitchen faucet(s) or flow restrictor(s)",
      "Manufacturer's data sheets for faucet(s)/flow restrictor(s) confirming the flow rate at 3 bar.",
    ],
  },
  {
    label: "WEM09 - Water-Efficient Dishwashers",
    documentsRequired: [
      "Summary of the dishwasher(s) to be installed in the building, including quantity and proof of maximum water use",
      "Specifications from manufacturer detailing water use.",
    ],
  },
  {
    label: "WEM11 - Water Efficient Washing Machines",
    documentsRequired: [
      "Plumbing drawings/specifications including make, model, and flow rate of the washing machines",
      "Manufacturer's data sheets for washing machines confirming the water use per cycle",
    ],
  },
  {
    label: "WEM12 - Swimming Pool Covers",
    documentsRequired: [
      "Sizing calculations and manufacturer's data sheets for pool cover(s) to fit the entire pool(s).",
    ],
  },
  {
    label: "WEM13 - Water Efficient Landscape Irrigation",
    documentsRequired: [
      "Landscape plan showing the zoning for plants and the type of plants used, highlighting native species and the irrigation system selected",
      "Description of the water requirements use in landscaped areas",
      "Calculation of the landscape water consumption in liters/m²/day",
    ],
  },
  {
    label: "WEM14 - Rainwater Haarvesting System",
    documentsRequired: [
      "A system schematic showing the collection area, feed pipes and storage tank",
      "Sizing calculations for the rainwater harvesting system",
      "EDGE assumes that the rainwater is being used within the building to replace potable water use. If the harvested rainwater is being used only to irrigate the landscape, the project team must demonstrate that (a) there is need for irrigation with municipal water (in addition to just natural rainwater) and (b) that the plumbing layout shows that the recycled water will be directed to this use.",
    ],
  },
  {
    label: "WEM15 - Wastewater Treatment And Recycling System",
    documentsRequired: [
      "A schematic layout of the system showing the plumbing including the dual plumbing lines",
      "Manufacturer’s data sheets of the specified gray water treatment plant",
      "Calculations including the following: (a) Designed capacity of the gray water treatment system in m3/day. (b) Quantity of gray water available daily to recycle in liters/day. (c) Efficiency of the gray water system to produce treated water in liters/day. (d) Water balance chart.",
    ],
  },
  {
    label: "MEM01 - Bottom Floor Construction",
    documentsRequired: [
      "loor sections showing the materials and thicknesses of the floor(s)",
      " Building plans marking the area of major floor types if more than one type of floor is present.",
    ],
  },
  {
    label: "MEM02 - Intermediate Floor Construction",
    documentsRequired: [
      "Floor sections showing the materials and thicknesses of the floor(s)",
      "Building plans marking the area of major floor types if more than one type of floor is present.",
    ],
  },
  {
    label: "MEM03 - Floor Finish",
    documentsRequired: [
      "Drawings showing the flooring specifications selected",
      " Building plans highlighting the area of major flooring types if more than one type of flooring is present",
      "Manufacturer's data sheets for the specified building materials; or Bill of quantities with the specifications for the flooring materials clearly highlighted.",
    ],
  },
  {
    label: "MEM04 - Roof Construction",
    documentsRequired: [
      "Roof sections showing the materials and thicknesses of the roof(s)",
      "Building plans marking the area of major roof types if more than one type of roof is present",
      "Manufacturer's data sheets for the specified building materials; or Bill of quantities with the roof material specifications clearly highlighted.",
    ],
  },
  {
    label: "MEM05 - Exterior Walls",
    documentsRequired: [
      "Drawings of the external wall sections",
      "Building plans or elevations highlighting the area of major external wall types if more than one type of external wall is present.",
    ],
  },
  {
    label: "MEM06 - Interior Walls",
    documentsRequired: [
      "Drawings of the internal wall sections",
      "Building plans or elevations highlighting the area of major internal wall types if more than one type of internal wall is present",
    ],
  },
  {
    label: "MEM07 - Window Frames",
    documentsRequired: [
      "Building elevations marking the window frame(s) specifications; or A window schedule for the building showing the major window frame types if more than one type of window frame is present.",
    ],
  },
  {
    label: "MEM08 - Window Glazing",
    documentsRequired: [
      "Building elevations marking the window glass specifications; or A window schedule for the building showing the major window glass types if more than one type of glass is present",
      "Manufacturer’s data sheets for the specified glazing.",
    ],
  },
  {
    label: "MEM09 - Roof Insulation",
    documentsRequired: [
      "Drawings marking the type(s) of insulation specified",
      "Building plans marking the area of major insulation types if more than one type of insulation is present",
      "Manufacturer’s data sheets for the specified insulation",
    ],
  },
  {
    label: "MEM09 - Wall Insulation",
    documentsRequired: [
      "Drawings marking the type(s) of insulation specified",
      "Building plans marking the area of major insulation types if more than one type of insulation is present",
      "Manufacturer's data sheets for the specified insulation; or Bill of quantities with the specifications for the insulation materials highlighted.",
    ],
  },
];
