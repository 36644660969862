import {
  Link,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";
import emailjs from "@emailjs/browser";
import ReactPDF, { Document, usePDF, Text } from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "src/config";

export default function AdminCreate() {
  const notify = (msg) => toast(msg);
  const navigate = useNavigate();
  let publicKey = "WffsPR6cSS6IwClRB";

  const [values, setValues] = useState("");
  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCreateClient = () => {
    setprogress(true);
    setmsg("Creating client...");
    // check if the ADMIN already exis
    firebase
      .firestore()
      .collection("users")
      .add({
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        type: "admin",
        isActive: false,
        status: "pending",
        createdBy: firebase.auth().currentUser.email,
        createdAt: new Date(Date.now()).toLocaleString(),
      })
      .then((docRef) => {
        // send email to the admin to activate their account by
        const id = docRef.id;
        const accountActivationLink = `${baseUrl()}/admins/activate/${id}`;
        const messageBody = `Hello ${values.first_name}, We are pleased to inform you that you have been invited to join the Solid Edge platform. Please click on the link to activate your account. ${accountActivationLink} Thanks`;
        let obj = {
          to_name: values.first_name,
          to_email: values.email,
          message: messageBody,
          subject: "Invitation to Join Solid Edge Admins",
        };
        emailjs
          .send("service_6a716og", "template_56brr6e", obj, publicKey)
          .then(
            (result) => {
              console.log(result.text);
              setprogress(false);
              notify("Admin created successfully");
              navigate("/admins");
            },
            (error) => {
              setprogress(false);

              console.log(error.text);
            }
          );
      })
      .catch((error) => {
        setprogress(false);
        notify("Error creating admin");
      });
  };
  return (
    <Page title="Dashboard">
      <Container>
        <Link to="/admins">Back to admins</Link>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h4" gutterBottom>
            Add admin
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" mb={5}>
          <Typography variant="body2" gutterBottom>
            System admins can manage all aspects of the system.
          </Typography>
        </Stack>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={8}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    onChange={handleChange("first_name")}
                    label="First Name"
                  />
                  <TextField
                    fullWidth
                    onChange={handleChange("last_name")}
                    label="Last Name"
                  />
                  <TextField
                    fullWidth
                    type="email"
                    onChange={handleChange("email")}
                    label="Email Address"
                  />

                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={progress}
                    onClick={handleCreateClient}
                  >
                    Create Admin
                  </LoadingButton>
                </Stack>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
