import { isArray } from "lodash";
import moment from "moment";
import { QUOTE_STATUS } from "src/config/constants";

export const sanitiseFieldName = (fieldName) => {
  return fieldName?.replace(/\s/g, "_").toLowerCase();
};

export const renderStatusConsideringNewAndOldStatus = (status) => {
  const passedStatus = status?.toUpperCase();
  let returnStatus;
  if (
    passedStatus === "NEW" ||
    passedStatus === QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE
  ) {
    returnStatus = "Pending Client Acceptance";
  } else if (
    passedStatus === QUOTE_STATUS.CLIENT_ACCEPTED ||
    passedStatus === "WORKING_ON_IT"
  ) {
    returnStatus = "Client Accepted";
  } else if (passedStatus === QUOTE_STATUS.CLIENT_REJECTED) {
    returnStatus = "Client Rejected";
  } else if (passedStatus === "INVOICED" || passedStatus === "INVOICE") {
    returnStatus = "Client Invoiced";
  } else if (
    passedStatus === QUOTE_STATUS.PENDING_PAYMENT ||
    passedStatus === "COMPLETED"
  ) {
    returnStatus = "Pending Payment";
  } else if (passedStatus === QUOTE_STATUS.PAID) {
    returnStatus = "Paid";
  } else {
    returnStatus = "Unknown";
  }

  return returnStatus;
};

export const makeStatusReadable = (status) => {
  return status?.replace(/_/g, " ").toLowerCase();
};

export const makeQuoteStatusLikeConstants = (status) => {
  return status?.toUpperCase().replace(/\s/g, "_");
};

export const makeStatusColor = (status) => {
  const passedStatus = status?.toUpperCase();
  let returnStatus;
  if (
    passedStatus === "NEW" ||
    passedStatus === QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE
  ) {
    returnStatus = "info";
  } else if (
    passedStatus === QUOTE_STATUS.CLIENT_ACCEPTED ||
    passedStatus === "WORKING_ON_IT"
  ) {
    returnStatus = "success";
  } else if (passedStatus === QUOTE_STATUS.CLIENT_REJECTED) {
    returnStatus = "error";
  } else if (passedStatus === "INVOICED" || passedStatus === "INVOICE") {
    returnStatus = "warning";
  } else if (
    passedStatus === QUOTE_STATUS.PENDING_PAYMENT ||
    passedStatus === "COMPLETED"
  ) {
    returnStatus = "warning";
  } else if (passedStatus === QUOTE_STATUS.PAID) {
    returnStatus = "success";
  } else {
    returnStatus = "info";
  }

  return returnStatus;
};

export const putDateDayFirst = (date) => {
  const dateArray = date?.split("/");
  return isArray(dateArray)
    ? `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
    : date;
};

export const formatDateWithMoment = (date) => {
  let parsedDate = date;
  // some dates a firebase format so we need to convert them to a date object
  if (date?.seconds) {
    parsedDate = new Date(date?.seconds * 1000 + date?.nanoseconds / 1000000);

    return moment(parsedDate).fromNow();
  }

  const dayPutFirst = putDateDayFirst(parsedDate);
  const momentDate = moment(dayPutFirst);
  return momentDate.fromNow();
};

// Object functions
export function countObjectElements(obj) {
  if (typeof obj !== "object" || obj === null) {
    return { count: 0, values: [] };
  }

  let count = Object.keys(obj).length;
  let values = Object.values(obj);
  return { count, values };
}
