import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import firebase from "./../../../firebase";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      navigate("/", { replace: true });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const login = () => {
    // check if user isn't banned
    const userRef = firebase.firestore().collection("users");
    userRef
      .where("email", "==", formik.values.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().status === "banned") {
            alert("You are banned from using this platform");
            return;
          } else {
            firebase
              .auth()
              .signInWithEmailAndPassword(
                formik.values.email,
                formik.values.password
              )
              .then((user) => {
                
                console.log(user);
              })
              .catch((e) => alert(e.message));
          }
        });
      });
  };

  return (
    <>
      {/* // <FormikProvider value={formik}>
    //   <Form autoComplete="off" noValidate onSubmit={login}> */}
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          onChange={handleChange("email")}
          {...getFieldProps("email")}
          error={Boolean(touched.email && errors.email)}
          helperText={touched.email && errors.email}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          onChange={handleChange("password")}
          {...getFieldProps("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(touched.password && errors.password)}
          helperText={touched.password && errors.password}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Link
          component={RouterLink}
          variant="subtitle2"
          to="/reset_password"
          underline="hover"
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => login()}
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"center"}
        sx={{ my: 2 }}
      >
        {/* <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/register"
        >
          Don&apos;t have an account? Sign up
        </Link> */}
        {/* <Link
          underline="none"
          variant="subtitle2"
          color="GrayText"
          component={RouterLink}
          to="/authenticate/client"
        >
          Login as client
        </Link> */}
      </Stack>
    </>
    //   </Form>
    // </FormikProvider>
  );
}
