export const baseUrl = () => {
  switch (window.location.hostname) {
    case "localhost":
      return "http://localhost:3000";
    case "solidgreenstage.web.app":
      return "https://solidgreenstage.web.app/";
    case "quote.edgesg.co.za":
      return "https://quote.edgesg.co.za/";
    default:
      return "https://quote.edgesg.co.za/";
  }
};
