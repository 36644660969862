import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import emailjs from "@emailjs/browser";

import Iconify from "src/components/Iconify";
import firebase from "src/firebase";
import { QUOTE_STATUS } from "src/config/constants";
import { useSnackbar } from "notistack";
export default function InvoiceForm({
  quoteId,
  indicateLoading,
  disable,
  hideButton = false,
}) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [vat, setVat] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  let publicKey = "WffsPR6cSS6IwClRB";

  const handleAcceptQuote = (e) => {
    e.preventDefault();

    setLoading(true);
    indicateLoading(true);

    firebase
      .firestore()
      .collection("quotes")
      .doc(quoteId)
      .update({
        InvoiceName: name,
        InvoiceAddress: address,
        InvoicePhone: phone,
        InvoiceVat: vat,
        InvoiceEmail: email,
        status: QUOTE_STATUS.CLIENT_ACCEPTED,
        updatedAt: new Date(Date.now()).toLocaleString(),
      })
      .then(() => {
        // update logs
        firebase
          .firestore()
          .collection("logs")
          .add({
            user_id: null,
            log: QUOTE_STATUS.CLIENT_ACCEPTED,
            item_id: quoteId,
            task: "QUOTE_UPDATE",
            createdAt: new Date(Date.now()).toLocaleString(),
          })
          .then(() => {
            emailjs
              .send(
                "service_6a716og",
                "template_7q00ys7",
                {
                  // to_name: values.name,
                  quote_id: quoteId,
                  project_name: name,
                },
                publicKey
              )
              .then((result) => {
                console.log(result.text);
                enqueueSnackbar("Quote accepted", {
                  variant: "success",
                });
                setLoading(false);
                indicateLoading(false);
                // redirect to dashboard after 3 seconds
                setTimeout(() => {
                  navigate("/", { replace: true });
                }, 3000);
              })
              .catch((error) => {
                console.log(error.text);
                setLoading(false);
                indicateLoading(false);
              });
            // notify("Quote updated");
            // setstatus(null);
          });

        // send email to admin

        // setHasUpdateQuote(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        indicateLoading(false);
      });
  };

  return (
    <form onSubmit={handleAcceptQuote}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="name"
          type="name"
          label="Name"
          value={name}
          required
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          fullWidth
          autoComplete="email"
          type="email"
          required
          label="Email address"
          onChange={(e) => {
            console.log("changed state");
            setEmail(e.target.value);
          }}
        />
        <TextField
          fullWidth
          autoComplete="phone"
          type="phone"
          required
          label="Phone number"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <TextField
          fullWidth
          autoComplete="address"
          type="address"
          label="Address"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
        />

        <TextField
          fullWidth
          autoComplete="vat"
          type="vat"
          label="Vat number"
          onChange={(e) => {
            setVat(e.target.value);
          }}
        />
      </Stack>
      <br />

      {!hideButton && (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={(e) => handleAcceptQuote(e)}
          loading={loading}
          disabled={disable}
        >
          Accept quote
        </LoadingButton>
      )}
    </form>
  );
}
