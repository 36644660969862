import { useState, useEffect } from "react";
// material
import {
  Container,
  Box,
  Typography,
  Button,
  Stack,
  Card,
  LinearProgress,
} from "@mui/material";
// routes
import { Link, useNavigate, useLocation } from "react-router-dom";

// import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
// import useSettings from "../../hooks/useSettings";
// components
import firebase from "../../firebase";

import Page from "../../components/Page";
// import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// import { BlogNewPostForm } from "../../components/_dashboard/blog";
import { SubmissionStepper, calculateProgress } from "./stepper";
import { InitialSetupForm } from "./Forms/InitialSetupForm";
import { FirstQuestion } from "./Forms/InitialSetupForm/FirstQuestion";
import TeamDialogue from "./Forms/InitialSetupForm/PopDialogue";
import { LoadingButton } from "@mui/lab";

import {
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
} from "src/config/constants";
import { useExternalAuth } from "src/context/externalUserContext";
import ProffessionalDialogueBox from "./Forms/InitialSetupForm/ProffessionalsDialogue";
import LoadingScreen from "src/components/LoadingScreen";
import FourSubmited from "./components/prompts/SubmitedFourInputs";
import Label from "src/components/Label";
import { totalQuestions } from "../mapperFuncs/questions";
import TransitionsDialogs from "./components/SubmissionComplete";
// ----------------------------------------------------------------------

export function DataSubmission() {
  // const { themeStretch } = useSettings();
  // get the project id from the url
  const [externalAuth, setExternalAuth] = useExternalAuth();
  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [progress, setprogress] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [quote_id, setQuoteId] = useState("");
  const [hasInitialSubmission, setHasInitialSubmission] = useState(false);
  const [adminCanSubmit, setAdminCanSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [openSubmissionComplete, setOpenSubmissionComplete] = useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [professionals, setProfessionals] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);

  const [projectId, setProjectId] = useState();
  const [hasAtleastOneProfessional, setHasAtleastOneProfessional] =
    useState(false);

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [submitedResponses, setSubmitedResponses] = useState([]);
  const [submissionsLoading, setSubmissionsLoading] = useState(true);

  const [openFourSubmitted, setOpenFourSubmitted] = useState(false);

  const projectCode = location.pathname.split("/")[4];

  const setEdgeCollectionDataToComplete = (projectCode) => {
    // prompt user to comfirm the action
    const confirm = window.confirm(
      "Are you sure you want to complete this project?"
    );
    if (!confirm) {
      return;
    }

    // set the project to complete
    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data, "data for looks");
          const id = doc.id;
          // set the project to complete
          firebase
            .firestore()
            .collection("projects")
            .doc(id)
            .update({
              status: PROJECT_STATUS.CLIENT_APPROVED,
            })
            .then(() => {
              firebase
                .firestore()
                .collection("PrelimDataForms")
                .doc(projectCode)
                .update({
                  status: INITIAL_ASSESSEMENT_STATUS.GET_STARTED,
                })
                .then(() => {
                  console.log("updated");
                  navigate(`/client/dashboard/${id}`);
                });
              // redirect to the dashboard
            });
        });
      });
  };

  const handlSetOpenDialogue = (action) => {
    // don't close the dialogue unless there is atleast one professional
    if (professionals.length >= 1) {
      setOpenDialogue(false);
    } else {
      setOpenDialogue(true);
    }
  };
  const handleClientSignOut = () => {
    // delete local storage
    localStorage.removeItem("externalAuth");
    // redirect to login page
    navigate("/authenticate/client");
  };
  useEffect(() => {
    setprogress(true);

    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          setProject(data);

          setQuoteId(data.quoteId);
          // if has atleast one professional
          setProjectId(doc.id);

          firebase
            .firestore()
            .collection("quotes")
            .doc(data.quoteId)
            .get()
            .then((doc) => {
              const data = doc.data();

              if (data.professionals) {
                setProfessionals(data.professionals);
              } else {
                setProfessionals([]);
              }
              setQuote(data);
              setprogress(false);
              firebase
                .firestore()
                .collection("edge_project_data")
                .doc(projectCode)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    setHasInitialSubmission(true);
                  } else {
                    setHasInitialSubmission(false);
                  }
                });
            });
        });
      });
  }, [projectCode]);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data, "check for admin");
          if (data?.development_stage?.allFilesUploaded) {
            setAdminCanSubmit(true);
          } else if (true) {
            setAdminCanSubmit(true);
          }

          setSubmitedResponses(data);
          setSubmissionsLoading(false);
          const approvedCount = Object.keys(data).filter((item) => {
            return data[item].status === "approved";
          }).length;

          const submittedCount = Object.keys(data).filter((item) => {
            return data[item].status === "submitted";
          }).length;

          setApprovedCount(approvedCount);
          setSubmittedCount(submittedCount);

          const answeredQuestions = Object.keys(data).length;
          setAnsweredQuestions(answeredQuestions);
        } else {
          setSubmissionsLoading(false);
          console.log("no data");
        }
        setSubmissionsLoading(false);
      });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [projectCode]);
  useEffect(() => {}, [externalAuth]);

  // check if answered is 100%

  useEffect(() => {
    if (answeredQuestions === totalQuestions) {
      if (
        project?.edge_project_data_status !==
        PROJECT_STATUS.WAITING_VERIFICATION
      ) {
        setOpenSubmissionComplete(true);
      } else if (project.status !== PROJECT_STATUS.CLIENT_APPROVED) {
        setOpenSubmissionComplete(true);
      }
    }
  }, [answeredQuestions]);

  return (
    <Page
      title={`${quote?.project} | Data submission portal`}
      sx={{
        overflow: "hidden",
      }}
    >
      <ProffessionalDialogueBox
        open={openDialogue}
        setOpen={handlSetOpenDialogue}
        users={professionals}
        setUsers={setProfessionals}
        quoteId={quote_id}
      />

      <TransitionsDialogs
        open={openSubmissionComplete}
        setOpen={setOpenSubmissionComplete}
      />

      {progress && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      )}
      <Stack
        sx={{
          position: "fixed",
          bgcolor: "background.default",
          left: 0,
          right: 0,
          borderBottom: "1px solid #e0e0e0",
          zIndex: 100,
        }}
      >
        <Container
          sx={{
            mt: 3,
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              position: "absolute",
              right: "20%",
            }}
          >
            <Typography variant="body2" color="GrayText">
              Logged in as:{" "}
              <span style={{ color: "#00AB55", fontWeight: "bold" }}>
                {externalAuth?.profession || "Project Owner"}
              </span>
            </Typography>
          </Stack>

          <Stack
            sx={{
              width: "100%",

              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src="/static/SolidGreen-Logo.svg"
                alt="logo"
                style={{ width: 140 }}
              />
              <Stack spacing={1} mt={2} ml={4}>
                <Typography variant="h4">{`${quote?.project}`}</Typography>

                <Typography variant="subtitle2" gutterBottom>
                  This is the data submission portal for your project.
                </Typography>

                {adminCanSubmit && (
                  <Typography variant="body2" color="GrayText">
                    🕣 Admin will be able to submit your project once all files
                    are valid.
                  </Typography>
                )}

                {/* float action to sign out button to right */}
              </Stack>
            </Box>
          </Stack>

          <Stack
            sx={{
              mt: 2,
              mb: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                flex: 0.5,
              }}
            >
              <Typography variant="h4">Edge RFI</Typography>
            </Box>

            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  mr: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Answered :
                </Typography>
                <Label
                  color="default"
                  sx={{
                    padding: 2,
                    ml: 1,
                  }}
                >
                  {calculateProgress(answeredQuestions, totalQuestions)}
                </Label>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  ml: 1,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Approved :{" "}
                </Typography>
                <Label
                  color="default"
                  sx={{
                    padding: 2,
                    ml: 1,
                  }}
                >
                  {calculateProgress(approvedCount, totalQuestions - 2)}
                </Label>
              </Box>
            </Box>

            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                color="primary"
                onClick={setOpenDialogue}
              >
                professionals
              </Button>

              <Button
                variant="outlined"
                LinkComponent={Link}
                to={`/client/dashboard/${projectId}`}
              >
                View Project
              </Button>

              {externalAuth?.isOwner &&
              project?.status !== PROJECT_STATUS.CLIENT_APPROVED ? (
                <LoadingButton
                  loading={submissionsLoading}
                  disabled={
                    approvedCount > 0 &&
                    approvedCount >= submittedCount &&
                    approvedCount >= 30
                      ? false
                      : true
                  }
                  variant="contained"
                  sx={{
                    ml: 1,
                  }}
                  onClick={() => {
                    setEdgeCollectionDataToComplete(projectCode);
                  }}
                >
                  Complete Submission
                </LoadingButton>
              ) : project?.status === PROJECT_STATUS.CLIENT_APPROVED ? (
                <LoadingButton
                  loading={submissionsLoading}
                  disabled
                  variant="contained"
                  sx={{
                    ml: 1,
                  }}
                  onClick={() => {
                    setEdgeCollectionDataToComplete(projectCode);
                  }}
                >
                  Submission Complete
                </LoadingButton>
              ) : null}
            </Box>
          </Stack>
        </Container>
      </Stack>

      <Stack mt="100px" />
      <Container
        sx={{
          position: "relative",
        }}
      >
        <Stack
          sx={{
            mt: 10,
          }}
        >
          {progress ? null : (
            <SubmissionStepper
              projectId={projectId}
              projectCode={project?.code}
              readOnly={false}
              adminCanSubmit={adminCanSubmit}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
}
