import React, { useState, useEffect } from "react";
// routes
import Router from "./routes";
import { SnackbarProvider } from "notistack";

import LoginRoutes from "./loginroutes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import { LoadingFullPage } from "src/components/loadingFullPage";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import firebase from "./firebase";
import { AuthUserProvider, useAuth } from "./context/auth";
// ----------------------------------------------------------------------

export default function App() {
  const messaging = firebase.messaging();
  const [state, setstate] = useState(false);
  const [loading, setLoading] = useState(true);

  //mailgun sendingKey = 009a8bda40ff84f05b74fa0951634c1c-787e6567-52049d2e

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setstate(true);
        setLoading(false);
      } else {
        setstate(false);
        setLoading(false);
      }
    });
  }, [state]);

  if (loading) {
    return <LoadingFullPage />;
  }
  return (
    <ThemeConfig>
      <SnackbarProvider maxSnack={3}>
        <AuthUserProvider>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />

          {!state && <LoginRoutes />}
          {state && <Router />}
        </AuthUserProvider>
      </SnackbarProvider>
    </ThemeConfig>
  );
}
