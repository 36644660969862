import { forwardRef, useState, useEffect } from "react";
// material
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import firebase from "src/firebase";
import {
  CERTIFICATION_STATUS,
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
  ROUND1_STATUS,
} from "src/config/constants";
// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CertificationSubmissionDialogue({
  setOpen,
  open,
  projectCode,
}) {
  const [progress, setprogress] = useState(false);
  const [project, setProject] = useState(null);
  const [activatePrompt, setActivatePrompt] = useState(false);
  const [file, setFile] = useState(null);

  const handleUploadCertificate = async () => {
    setprogress(true);
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`certification/${projectCode}`);
    try {
      const snapshot = await fileRef.put(file);
      const url = await snapshot.ref.getDownloadURL();
      await firebase
        .firestore()
        .collection("Certification")
        .doc(projectCode)
        .update({
          status: CERTIFICATION_STATUS.COMPLETED,
          file: {
            name: file.name,
            url: url,
          },
        });
      setprogress(false);
      setOpen(false);
      // window.location.reload();
    } catch (error) {
      // handle error
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("Certification")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setprogress(false);
        }
      });
  }, [projectCode]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Upload certifcate
        </DialogTitle>
        <DialogContent>
          {project?.status === ROUND1_STATUS.COMPLETED && (
            <Typography variant="caption">✅ Certificate submited</Typography>
          )}
          <DialogContentText id="alert-dialog-slide-description">
            {/* File upload box */}
            {project?.file?.name ? (
              <a
                href={`${project?.file?.url}`}
                rel="noreferrer"
                target="_blank"
              >
                {project?.file?.name}
              </a>
            ) : (
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
          <LoadingButton
            disabled={
              project?.status === CERTIFICATION_STATUS.COMPLETED || !file
            }
            variant="contained"
            onClick={handleUploadCertificate}
            loading={progress}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
