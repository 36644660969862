import { useState, useEffect } from "react";
// material
import {
  Box,
  Step,
  Paper,
  Button,
  Stepper,
  StepLabel,
  Typography,
  Stack,
} from "@mui/material";

// import { EnergyForm } from "../Forms/energy";
// import { WaterForm } from "../Forms/water";
// import { MaterialForm } from "../Forms/material";
// import FourSubmited from "../Forms/InitialSetupForm/FourSubmited";

import firebase from "src/firebase";
import { InitialSetupForm } from "../Forms/InitialSetupForm";
import {
  architect,
  electricalEngineer,
  landscapeArchitect,
  mechanicalEngineer,
  pvConsultant,
  returnExpectedNumberOfSubmissions,
  structuralEngineer,
  totalQuestions,
  wetServiceEngineer,
} from "src/pages/mapperFuncs/questions";
import { PROJECT_STATUS } from "src/config/constants";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
import { useExternalAuth } from "src/context/externalUserContext";
import { GeneralForm } from "../Forms/generalForm";
import FourFilesUploaded from "../components/prompts/SubmitedFourInputs";
import { sanitiseFieldName } from "src/utils/smartUtils";
import { AreaBreakdownForm } from "../Forms/areaBreakdown";
const steps = [
  "Get started",
  "Architect",
  "Wet service engineer",
  "Structural engineer",
  "Electrical engineer",
  "Mechanical engineer",
  "Landscape architect",
  "PV consultant",
];

export function SubmissionStepper({
  readOnly: readOnlyProp,
  projectCode,
  projectId,
  quoteID,
  setOpenInputDialogue,
  openInputDialogue,
  adminCanSubmit,
  isAdmin,
  projectType,
}) {
  const [activeStep, setActiveStep] = useState(1);
  const [skipped, setSkipped] = useState(new Set());
  const [canGoThroughForm, setCanGoThroughForm] = useState(false);
  const [canSubmitAll, setCanSubmitAll] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [submittedCountForUser, setSubmittedCountForUser] = useState(0);
  const [expectedQuestionForUser, setExpectedQuestionForUser] = useState(0);
  const [externalAuth, setExternalAuth] = useExternalAuth();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [edgeProjectStatus, setEdgeProjectStatus] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const [openFourSubmitted, setOpenFourSubmitted] = useState(false);

  const isStepOptional = (step) => step === 1;
  const [readOnly, setReadOnly] = useState(() => {
    if (adminCanSubmit) {
      return false;
    } else {
      return readOnlyProp;
    }
  });

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === steps.length - 1) {
      // handleSave();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  useEffect(() => {
    // get the data from the database and update the state
    // use firebase to get the data from the edge_project_data collection

    const fetchData = async () => {
      // use the onSnapshot method to get the data
      // this will update the data in real time

      setIsLoading(true);
      const docRef = firebase
        .firestore()
        .collection("edge_project_data")
        .doc(projectCode);
      docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data();
          setData(data);

          const approvedCount = Object.keys(data).filter((item) => {
            return data[item].status === "approved";
          }).length;
          const questionsForUser = returnExpectedNumberOfSubmissions(
            externalAuth?.profession
          );

          setExpectedQuestionForUser(questionsForUser.length);
          // filter and count questions for user that are in data and have status of submitted
          const submittedCountForUser = Object.keys(data).filter((item) => {
            // use the questionsForUser to filter the data
            return (
              data[item].status === "submitted" ||
              data[item].status === "approved"
            );
          });

          // loop throguth the questionsForUser object and get the label name
          const sanitisedLabelNames = questionsForUser.questions.map((item) => {
            return sanitiseFieldName(item.label);
          });

          let submittedCountForUserFiltered = 0;
          // loop through the submittedCountForUser and check if the label name is in the sanitisedLabelNames array
          submittedCountForUser.forEach((item) => {
            if (sanitisedLabelNames.includes(item)) {
              submittedCountForUserFiltered++;
            }
          });

          setSubmittedCountForUser(submittedCountForUserFiltered);

          const submittedCount = Object.keys(data).filter((item) => {
            // use the questionsForUser to filter the data
            return data[item].status === "submitted";
          }).length;

          setApprovedCount(approvedCount);
          setSubmittedCount(submittedCount);

          const answeredQuestions = Object.keys(data).length;
          setAnsweredQuestions(answeredQuestions);

          if (answeredQuestions === totalQuestions) {
            // setCanSubmitAll(true);
            // get project where code === projectCode and get the stage of edge_project_data_status
            // if edge_project_data_status === "Completed" then setCanGoThroughForm to true
            // else setCanGoThroughForm to false

            firebase
              .firestore()
              .collection("projects")
              .where("code", "==", projectCode)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  // console.log(doc.id, " => ", doc.data());
                  const projectData = doc.data();

                  if (projectData?.edge_project_data_status === "Completed") {
                    setEdgeProjectStatus(projectData?.edge_project_data_status);
                    setCanSubmitAll(true);
                    setCanGoThroughForm(true);
                  } else {
                    // update the database with the completed status
                    firebase
                      .firestore()
                      .collection("projects")
                      .doc(projectId)
                      .update({
                        edge_project_data_status:
                          PROJECT_STATUS.WAITING_VERIFICATION,
                      });
                    setCanSubmitAll(true);
                    setCanGoThroughForm(true);
                  }
                });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
              });
          } else {
            setCanSubmitAll(false);
          }

          if (data?.stageFiles?.fileName) {
            setCanGoThroughForm(true);
            // alert("You can now proceed to the next stage");
          }

          if (
            data?.development_stage === "Design" ||
            data?.development_stage === "Concept"
          ) {
            setCanGoThroughForm(true);
          }
        } else {
          // setCanGoThroughForm(false);
          setCanGoThroughForm(true);
        }
      });
      setIsLoading(false);
    };
    fetchData();
    // console.log(edgeProjectStatus, "edge project status");
  }, [externalAuth?.profession, projectCode, projectId]);

  useEffect(() => {
    // if externalAuth is not owner the set step according to profession
    // if externalAuth is owner then set step to 0

    if (externalAuth?.isOwner) {
      setActiveStep(0);
    } else if (externalAuth?.profession === "Architect") {
      setActiveStep(1);
    } else if (externalAuth?.profession === "Wet service engineer") {
      setActiveStep(2);
    } else if (externalAuth?.profession === "Structural engineer") {
      setActiveStep(3);
    } else if (externalAuth?.profession === "Electrical Engineer") {
      setActiveStep(4);
    } else if (externalAuth?.profession === "Mechanical engineer") {
      setActiveStep(5);
    } else if (externalAuth?.profession === "Landscape Architect/Planner") {
      setActiveStep(6);
    } else if (externalAuth?.profession === "PV consultant") {
      setActiveStep(7);
    } else {
      setActiveStep(0);
    }
  }, [externalAuth]);

  // console.log(projectType, "type of project");
  // console.log(steps.length, "steps length", steps);
  return (
    <>
      <Stack>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {/* <Typography variant="caption" component="h1" gutterBottom>
            {answeredQuestions} / {totalQuestions} Questions answered
          </Typography>
 */}
        </Box>
      </Stack>
      {/* <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {
            completed: false,
          };
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          if (canGoThroughForm) {
            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          }
        })}
      </Stepper> */}

      {activeStep === steps.length ? (
        <>
          <Paper sx={{ p: 3, my: 3, minHeight: 120, bgcolor: "#F9FAFB" }}>
            <AfterFormIsComplete
              projectId={projectId}
              projectCode={projectCode}
              allSetToProceed={true}
            />
          </Paper>
        </>
      ) : (
        <>
          <Paper
            sx={{ p: isAdmin ? 4 : 8, minHeight: 120, bgcolor: "#F9FAFB" }}
          >
            <Typography variant="h6" component="h1" gutterBottom>
              {steps[activeStep]}
            </Typography>
            {activeStep === 0 && (
              <InitialSetupForm
                isAdmin={isAdmin}
                projectType={projectType}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                adminCanSubmit={adminCanSubmit}
              />
            )}

            {activeStep === 1 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={architect}
                adminCanSubmit={adminCanSubmit}
                page={"Architect"}
              />
            )}
            {activeStep === 2 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={wetServiceEngineer}
                adminCanSubmit={adminCanSubmit}
                page={"Wet service engineer"}
              />
            )}
            {activeStep === 3 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={structuralEngineer}
                adminCanSubmit={adminCanSubmit}
                page={"Structural engineer"}
              />
            )}
            {activeStep === 4 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={landscapeArchitect}
                adminCanSubmit={adminCanSubmit}
                page={"Landscape Architect/Planner"}
              />
            )}
            {activeStep === 5 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={electricalEngineer}
                adminCanSubmit={adminCanSubmit}
                page={"Electrical engineer"}
              />
            )}
            {activeStep === 6 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={pvConsultant}
                adminCanSubmit={adminCanSubmit}
                page={"PV consultant"}
              />
            )}
            {activeStep === 7 && (
              <GeneralForm
                isAdmin={isAdmin}
                setOpenInputDialogue={setOpenInputDialogue}
                openInputDialogue={openInputDialogue}
                projectCode={projectCode}
                readOnly={readOnly}
                projectData={data}
                canGoThroughForm={canGoThroughForm}
                setCanGoThroughForm={setCanGoThroughForm}
                questions={mechanicalEngineer}
                adminCanSubmit={adminCanSubmit}
                page={"Mechanical engineer"}
              />
            )}

            {/* {getStepContent(activeStep)} */}
            {/* {totalQuestions} */}
          </Paper>
          {/* {canGoThroughForm && (
            <Box sx={{ display: "flex" }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              <Button variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          )} */}
          {/* {externalAuth?.isOwner && canGoThroughForm && ( */}
          {true && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                position: "fixed",
                bottom: 0,
                width: "80%",
                maxWidth: "1000px",
                zIndex: 1000,
                bgcolor: "#F9FAFB",
                px: 4,
                py: 2,
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>

              <Box sx={{ flexGrow: 1 }} />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {activeStep !== steps.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={activeStep === steps.length - 1}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                    {/* {readOnly ? (
                      <Typography variant="caption" component="h1" gutterBottom>
                        {approvedCount} Approved / {submittedCount} Await
                        Approval
                      </Typography>
                    ) : (
                      <>
                      <Typography variant="caption" component="h1" gutterBottom>
                        {calculateProgress(approvedCount, totalQuestions)}
                        Approval
                      </Typography>
                      <Typography variant="caption" component="h1" gutterBottom>
                        {calculateProgress(answeredQuestions, totalQuestions)}{" "}
                        Answered
                      </Typography>
                      </>
                    )} */}
                  </Box>
                )}
              </Box>
            </Box>
          )}

          {/* {!readOnly && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                position: "fixed",
                bottom: 0,
                width: "80%",
                zIndex: 1000,
                bgcolor: "#F9FAFB",
                px: 4,
                py: 2,
                borderTop: "1px solid #E5E7EB",
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>

              <Box sx={{ flexGrow: 1 }} />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {activeStep !== steps.length && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <Button
                      color="success"
                      variant="outlined"
                      disabled={activeStep === steps.length - 1}
                      onClick={handleNext}
                      sx={{ mr: 1 }}
                    >
                      Next
                    </Button>
                    {readOnly ? (
                      <Typography variant="caption" component="h1" gutterBottom>
                        {approvedCount} Approved / {submittedCount} Await
                        Approval
                      </Typography>
                    ) : (
                      <Typography variant="caption" component="h1" gutterBottom>
                        {calculateProgress(answeredQuestions, totalQuestions)}{" "}
                        answered
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )} */}
        </>
      )}
    </>
  );
}

const AfterFormIsComplete = ({ allSetToProceed, projectId, projectData }) => {
  const handleApproveVerification = () => {
    const db = firebase.firestore();
    db.collection("projects")
      .doc(projectId)
      .update({
        status: PROJECT_STATUS.CLIENT_APPROVED,
      })
      .then(() => {
        alert("Verification status updated");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  if (!allSetToProceed) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h3" gutterBottom component="div">
          Thank you for submitting your project{" "}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          component="div"
          sx={{ maxWidth: 600, textAlign: "center", mb: 2 }}
        >
          Admins will review your submission and get back to you. Please check
          your email for updates on your submission status
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" disabled>
            Confirm submitted information
          </Button>
        </Box>
      </div>
    );
  }

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h3" gutterBottom component="div">
        Submission approved
      </Typography>
      <Typography variant="body1" gutterBottom component="div">
        You submission has been approved by the admins and you can now proceed
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1 }} />
        <Button variant="contained" onClick={handleApproveVerification}>
          Confirm submitted information
        </Button>
      </Box>
    </div>
  );
};

export function calculateProgress(questionsAnswered, totalQuestions) {
  if (questionsAnswered < 0 || totalQuestions <= 0) {
    return "0";
  }

  const progress = (questionsAnswered / totalQuestions) * 100;
  const value = Math.round(progress) + "%";

  // return 100% if progress is greater than 100% else return the progress
  return progress > 100 ? "100%" : value;
}
