// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
// utils
import { fNumber, fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';
//
const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3)
}));

// ----------------------------------------------------------------------

export default function BookingTotal({data}) {
  const [TOTAL, setTotal] = useState(0);
 useEffect(() => {
  let TOTAL_ = 0
 data?.map((e)=> {
    console.log(e.status)
    if(e.status === "CLIENT_APPROVED"){
      TOTAL_ = TOTAL_ + parseInt(e.TotalFees)
      setTotal(TOTAL_)
    }
  });
  console.log("HEEEEYY",TOTAL)
  },[data] );
  
  return (
    <RootStyle>
      <div>
        <Typography variant="h3">R {fNumber(TOTAL)}</Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          Total Cost of Completed Quotes
        </Typography>
      </div>
    </RootStyle>
  );
}
