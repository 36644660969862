import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PDFViewer = ({ url }) => {
  console.log(url, "url");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setPageNumber(1);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [url]);

  return (
    <div>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        >
          Previous
        </button>
        <button
          disabled={pageNumber >= numPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const Img = ({ files }) => {
  console.log(files, "file");
  return (
    <div>
      {files?.map((file) => (
        <div>
          <p>{file.fileName}</p>
          <img src={file.url} alt={file.fileName} />
        </div>
      ))}
    </div>
  );
};

export default function FilePreviewer({ files }) {
  return (
    <div>
      {files?.map((file) => {
        if (file?.fileType === "image/jpeg" || file?.fileType === "image/png") {
          return <Img files={files} />;
        } else if (file?.fileType === "application/pdf") {
          // console.log(file.url, "url");
          const url = file.url;
          // return <PDFViewer url={url} />;
          return (
            <h6>
              <a href={url} target="_blank" rel="noreferrer">
                {file.fileName}
              </a>
            </h6>
          );
        }

        return null;

        // return (
        //   <p>
        //     {file.fileName}
        //     File type not supported. upload pdf or image <br />
        //     <br />
        //   </p>
        // );
      })}
    </div>
  );
}
