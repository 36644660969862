import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, TextField, Stack, FormControlLabel } from "@mui/material";
// import { useSnackbar } from "notistack";
import { useExternalAuth } from "../../../context/externalUserContext";
import { LoadingButton } from "@mui/lab";
import firebase from "../../../firebase";
import { useSnackbar } from "notistack";

import Checkbox from "@mui/material/Checkbox";
import { a } from "react-spring";
export const ClientAuthForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const checkProfessionLogin = (email, code) => {
    // check if email is in the project professionals array and if the code is correct
    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", code)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // alert("Project not found");
          enqueueSnackbar("Project not found", { variant: "error" });
          setIsSubmitting(false);
        } else {
          querySnapshot.forEach((doc) => {
            const project = doc.data();
            const projectId = doc.id;
            // console.log(project, "project check");
            // alert("Project found");
            if (project?.code === projectCode) {
              enqueueSnackbar("Project found", { variant: "success" });
              setIsSubmitting(false);
              setExternalAuth({
                email: project.email,
                projectCode: project.code,
              });

              navigate(`/client/dashboard/${projectId}`, { replace: true });
              return;
            } else {
              // alert("Project not found");
              enqueueSnackbar("Project not found", { variant: "error" });
              setIsSubmitting(false);
            }
          });
        }
      });
  };
  const [externalAuth, setExternalAuth] = useExternalAuth();
  // console.log(externalAuth, "external auth");
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   // console.log("submit", email, projectCode);

  //   setIsSubmitting(true);

  //   firebase
  //     .firestore()
  //     .collection("projects")
  //     .where("email", "==", email)
  //     .get()
  //     .then((querySnapshot) => {
  //       if (querySnapshot.empty) {
  //         checkProfessionLogin(email, projectCode);

  //         // enqueueSnackbar("Project not found", { variant: "error" });
  //         setIsSubmitting(false);
  //       } else {
  //         // alert("call client");
  //         querySnapshot.forEach((doc) => {
  //           const project = doc.data();
  //           // get project id

  //           const projectId = doc.id;

  //           if (project?.code === projectCode) {
  //             // enqueueSnackbar("Project found", { variant: "success" });
  //             setIsSubmitting(false);
  //             setExternalAuth({
  //               email: project.email,
  //               projectCode: project.code,
  //             });

  //             navigate(`/client/dashboard/${projectId}`, { replace: true });
  //             return;
  //           } else {
  //             // alert("Project not found");
  //             // enqueueSnackbar("Project not found", { variant: "error" });
  //             setIsSubmitting(false);
  //           }
  //         });
  //       }
  //     });
  // };
  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("submit", email, projectCode);

    setIsSubmitting(true);

    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // Project not found
          setIsSubmitting(false);
          // alert("Project not found");
          enqueueSnackbar("Project not found", { variant: "error" });
        } else {
          querySnapshot.forEach((doc) => {
            const project = doc.data();
            const projectID = doc.id;
            if (project?.email === email) {
              // Project found and email matches
              setIsSubmitting(false);
              setExternalAuth({
                email: project.email,
                projectCode: project.code,
                isOwner: true,
              });
              navigate(`/client/dashboard/${doc.id}`, { replace: true });
            } else {
              // Email does not match
              // setIsSubmitting(false);

              // console.log(project, "project");
              // check if email is in the quote professionals array
              firebase
                .firestore()
                .collection("quotes")
                .doc(project.quoteId)
                .get()
                .then((doc) => {
                  const quote = doc.data();

                  console.log(quote, "quote");
                  // check if quote professionals array contains email
                  // professionals is an object email, name, phone and profession
                  const professionals = quote?.professionals;

                  const foundEmail = professionals.find(
                    (obj) => obj.email === email
                  );

                  if (foundEmail) {
                    // email found
                    setIsSubmitting(false);
                    setExternalAuth({
                      email: foundEmail.email,
                      projectCode: project.code,
                      isOwner: false,
                      profession: foundEmail.profession,
                    });
                    enqueueSnackbar("Successfully logged in", {
                      variant: "success",
                    });

                    navigate(`/client/dashboard/${projectID}`, {
                      replace: true,
                    });
                  } else {
                    // email not found
                    setIsSubmitting(false);
                    // alert("Invalid email");
                    enqueueSnackbar("Invalid email", { variant: "error" });
                  }
                });
              // alert("Invalid email");
              // enqueueSnackbar("Invalid email", { variant: "error" });
            }
          });
        }
      });
  };

  useEffect(() => {
    // if externalAuth is not empty, navigate to the project page
    if (externalAuth?.projectId && externalAuth?.email) {
      // register activity

      console.log("redirect");
      navigate(`/client/dashboard/${externalAuth?.projectId}`);
    }
  }, [externalAuth]);

  return (
    <Grid xs={12} md={6}>
      <Stack spacing={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // error={Boolean(errors.email)}
              // helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="text"
              label="Project code"
              value={projectCode}
              onChange={(e) => setProjectCode(e.target.value)}
              // error={Boolean(errors.password)}
              // helperText={errors.password?.message}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControlLabel control={<Checkbox />} label="Remember me" />
          <Link variant="subtitle2" component={Link} to={"/forgot-password"}>
            Reset credentials?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmit}
        >
          Login
        </LoadingButton>
      </Stack>
    </Grid>
  );
};

// keep this line at the end
// nx93mBSe90YMn9t4JZO6 clientID

// YCu59QGiyBzmyKPjS52E projectID
