import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// import emailjs from "emailjs-com";

// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
} from "@mui/material";
import validator from "validator";
import { codeGenerate } from "../../../utils/codeGenerate";
import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MemberList from "./components/ListMembers";
import { MainLoadingPage } from "src/components/customs/MainLoadingPage";
import { baseUrl } from "src/config";
import {
  INITIAL_ASSESSEMENT_STATUS,
  PROJECT_STATUS,
} from "src/config/constants";
let publicKey = "WffsPR6cSS6IwClRB";
export default function ProjectInitiate() {
  // get the project id from the url
  const location = useLocation();
  const navigate = useNavigate();
  const quoteId = location.pathname.split("/")[3];

  if (!quoteId) {
    navigate("/404");
  }
  const [errorMessage, setErrorMessage] = useState("");

  const notify = (msg) => toast(msg);

  const [progress, setprogress] = useState(false);
  const [projectCode] = useState(codeGenerate(5, "SE-"));
  const [msg, setmsg] = useState("");
  const [quote, setQuote] = useState(null);
  const [members, setMembers] = useState([]);

  const [driveUrl, setDriveUrl] = useState("");

  const handleRemoveMember = (email) => {
    let newMembers = members.filter((member) => member.email !== email);
    setMembers(newMembers);
  };

  const sendEmailToTeamUsers = (project_code, docRef) => {
    members.forEach((member) => {
      emailjs
        .send(
          "service_6a716og",
          "template_5cn6kxi",
          {
            project_code,
            to_name: member.name,
            link_to_project: `${baseUrl()}/projects/view/${
              docRef || project_code
            }`,
            to_email: member.email,
          },
          publicKey
        )
        .then(
          (result) => {
            setmsg("Email has been sent.");
            setprogress(false);
            notify("Email has been sent.");
            console.log(result.text);
          },
          (error) => {
            console.log("error thhrown");
            console.log(error);
          }
        );
    });
  };
  const sendEmailToClient = (project_code, to_name, project_name, to_email) => {
    emailjs
      .send(
        "service_6a716og",
        "template_im2mpu9",
        {
          project_code,
          to_name,
          project_name,
          portal_url: `${baseUrl()}/authenticate/client`,
          to_email,
        },
        publicKey
      )
      .then(
        (result) => {
          setmsg("Email has been sent.");
          setprogress(false);
          notify("Email has been sent.");
          console.log(result.text);
        },
        (error) => {
          console.log("error thhrown");
          console.log(error);
        }
      );
    // let obj = {
    //   id: id,
    //   email,
    //   client: name,
    //   project: `${project} - Project Code : ${projectCode}`,
    // };

    // setmsg("Sending email to client...");
    // emailjs.send("service_6a716og", "template_galhv3r", obj, publicKey).then(
    //   (result) => {
    //     setmsg("Email has been sent.");
    //     setprogress(false);
    //     notify("Email has been sent.");
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
  };

  const handleProjectInitiation = (quoteId) => {
    // if (!validator.isURL(driveUrl)) {
    //   notify("Please provide a valid drive url");
    //   setErrorMessage("Please provide a valid drive url");
    //   console.log("Please provide a valid drive url");
    //   return;
    // }

    setprogress(true);
    setmsg("Initiating project...");
    // create a new project

    let projectId;
    firebase
      .firestore()
      .collection("projects")
      .add({
        quoteId: quoteId,
        members: members,
        code: projectCode,
        email: quote.email,
        driveUrl: driveUrl || "",
        status: PROJECT_STATUS.NEW,
        createdAt: new Date(),
      })
      .then((docRef) => {
        // update the quote
        setmsg("Updating quote...");
        projectId = docRef.id;
        firebase
          .firestore()
          .collection("quotes")
          .doc(quoteId)
          .update({
            projectInitiated: true,
            projectId: docRef.id,
            projectCode: projectCode,
          })
          .then(() => {
            setprogress(false);
            setmsg("");
            notify("quote updated successfully");

            sendEmailToClient(
              projectCode,
              quote.client,
              quote.project,
              quote.email,
              docRef.id
            );

            sendEmailToTeamUsers(projectCode, docRef.id);

            // console.log("quote updated successfully", docRef.id);
            // navigate to the project page using the project id
            navigate(`/projects/view/${docRef.id}`);
            // setprogress(false);
            // return;
          });
      });

    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .set({
        status: INITIAL_ASSESSEMENT_STATUS.NOT_STARTED,
        projectCode: projectCode,
        projectId: projectId,
      })
      .then(() => {
        console.log("Document successfully written!");
        setprogress(false);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        setprogress(false);
      });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("quotes")
      .doc(quoteId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setQuote(doc.data());
        } else {
          navigate("/404");
        }
      });
  }, []);

  return (
    <Page title="Dashboard">
      {progress && <MainLoadingPage msg={msg} />}
      {/* back button */}
      <Button
        color="primary"
        size="medium"
        variant="text"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        ></Stack>
        <Stack mb={5}>
          <Typography variant="h4" gutterBottom>
            Initialize project
          </Typography>

          {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Cras ultricies mi eu turpis hendrerit fringilla. Fusce vel dui.
            Pellentesque auctor neque nec urna. Sed cursus turpis vitae tortor.
            Curabitur suscipit suscipit tellus.
          </Typography> */}
          {/* <Button
            type="submit"
            variant="contained"
            size="large"
            style={{ marginBottom: 10 }}
            onClick={handleClickOpenDialogue}
          >
            Assign member
            
          </Button> */}
        </Stack>

        <Stack bgcolor="#fff" minHeight="512px" padding={2} borderRadius={3}>
          <MemberList
            members={members}
            setMembers={setMembers}
            handleRemoveMember={handleRemoveMember}
            driveUrl={driveUrl}
            setDriveUrl={setDriveUrl}
          />
        </Stack>

        <Stack>
          <Stack width={200} marginLeft="auto" marginRight="auto" p={2}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ marginBottom: 10 }}
              onClick={() => {
                handleProjectInitiation(quoteId);
              }}
            >
              Initiate Project
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
