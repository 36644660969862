import LoadingScreen from "../LoadingScreen";

export const LoadingFullPage = () => {
  return (
    <div>
      <div
        className="loading"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 9999,
          background: "rgba(255, 255, 255, 0.8)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingScreen />
        {/* <img src="/static/SolidEdge-Logo.svg" alt="login" style={{width:128, marginBottom:20}} /> */}

        {/* <img className="loading__img" src="/public/static/SolidEdge-Logo.svg" alt="logo" style={{width:128, marginBottom:20}}/> */}
      </div>
    </div>
  );
};
