import { createContext, useContext, useEffect, useState } from "react";
// import { onAuthStateChanged } from "firebase/auth";
import firebase from "../firebase";

const AuthUserContext = createContext({
  authUser: null,
  isLoading: true,
});
export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const authStateChanged = (user) => {
  //   setIsLoading(true);
  //   if (!user) {
  //     setAuthUser(null);
  //     setIsLoading(false);
  //     return;
  //   }
  //   setAuthUser(user);
  //   setIsLoading(false);
  // };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => {
        // if (user) {
        //   console.log("within found user");
        //   setIsLoading(true);
        if (!user) {
          setAuthUser(null);
          setIsLoading(false);
          return;
        }
        setAuthUser(user);
        setIsLoading(false);
        console.log("user found - default action");
        console.log(user);
      }
      // }
    );
    return () => unsubscribe();
  }, [isLoading]);

  return { authUser, isLoading };
}

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthUserContext);
};
