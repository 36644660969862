import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";

import DashboardApp from "./pages/DashboardApp";

import User from "./pages/User";
import NotFound from "./pages/Page404";
import { useEffect, useState } from "react";

// import firebase from "./firebase";

import Quote from "./pages/pages/QuotePage";
import SendAQuote from "./pages/pages/SendAQuote";
import Quotes from "./pages/pages/QuotesPage";
import AddNewUser from "./pages/pages/AddNewUser";
import MyProfile from "./pages/pages/MyProfile";
import UserProfile from "./pages/pages/UserProfile";
import Clients from "./pages/pages/ClientsPage";
import { ClientsProjects } from "./pages/pages/clients/Projects";
import Settings from "./pages/pages/Settings";
import AllClients from "./pages/pages/AllClients";
// import { useAuthUser } from "./context/auth";
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectView,
  ProjectMembersLink,
  ProjectInitiate,
  ProjectSubmittedData,
  ProjectInitialAssessment,
} from "./pages/pages/projects";
import {
  ClientCreate,
  ClientEdit,
  ClientList,
  ClientView,
} from "./pages/pages/clients";
import {
  AdminCreate,
  AdminEdit,
  AdminList,
  AdminView,
} from "./pages/pages/admins";
import {
  QuoteCreate,
  QuoteEdit,
  QuoteList,
  QuoteView,
  QuoteMembersLink,
  QuoteInitiate,
} from "./pages/pages/quotes";
import Login from "./pages/Login";
import Register from "./pages/Register";

// ----------------------------------------------------------------------

export default function Router() {
  // const { authUser, isLoading } = useAuthUser();
  const [user, setuser] = useState(null);
  // console.log("user in auth", authUser);
  // const { authUser, isLoading } = useFirebaseAuth();
  // useEffect(() => {
  //   firebase
  //     .firestore()
  //     .collection("users")
  //     .doc(firebase.auth().currentUser.uid)
  //     .get()
  //     .then((doc) => {
  //       setuser(doc.data());
  //     });
  // }, [user]);

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: user?.super_admin
        ? [
          { path: "/", element: <DashboardApp /> },
          { path: "/admins/activate/:id", element: <Register /> },
          { path: "user", element: <User /> },
          { path: "/userProfile/:id", element: <UserProfile /> },
          { path: "/profile", element: <MyProfile /> },
          { path: "/create_user/", element: <AddNewUser /> },
          { path: "404", element: <NotFound /> },
        ]
        : [
          // { path: "/login", element: <Login /> },

          { path: "/", element: <DashboardApp /> },
          { path: "/quote/:id", element: <Quote /> },
          { path: "/quotes/", element: <Quotes /> },

          { path: "/projects/", element: <ProjectList /> },
          { path: "/projects/create", element: <ProjectCreate /> },
          { path: "/projects/edit/:id", element: <ProjectEdit /> },
          { path: "/projects/view/:id", element: <ProjectView /> },
          {
            path: "/projects/initial-assesment/:projectCode",
            element: <ProjectInitialAssessment />,
          },
          {
            path: "/projects/submitted-data/:projectCode",
            element: <ProjectSubmittedData />,
          },
          {
            path: "/projects/working-team/:id",
            element: <ProjectMembersLink />,
          },
          {
            path: "/projects/initiate/:quoteId",
            element: <ProjectInitiate />,
          },

          { path: "/clients/", element: <ClientList /> },
          { path: "/clients/create", element: <ClientCreate /> },
          { path: "/clients/edit/:id", element: <ClientEdit /> },
          { path: "/clients/view/:id", element: <ClientView /> },
          { path: "/clients/quotes/:email", element: <ClientsProjects /> },

          { path: "/admins/", element: <AdminList /> },
          { path: "/admins/create", element: <AdminCreate /> },
          { path: "/admins/edit/:id", element: <AdminEdit /> },
          { path: "/admins/view/:id", element: <AdminView /> },
          // { path: "/admins/quotes/:email", element: <AdminProjects />, },

          { path: "/settings/", element: <Settings /> },
          // {path: '/allclients/', element: <AllClients />} ,
          // clients sub routes
          // {path: '/clients/create', element: <AllClients />} ,

          { path: "/quote/create/", element: <QuoteCreate /> },
          // { path: "/send_a_quote/", element: <SendAQuote /> }, // this is the old one
          { path: "/create_user/", element: <AddNewUser /> },
          { path: "/profile", element: <MyProfile /> },
          { path: "404", element: <NotFound /> },
          { path: "*", element: <Navigate to="/" /> },
        ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
