import firebase from "firebase";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import "firebase/auth";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let firebaseConfig = {};
const stagingConfig = {
  apiKey: "AIzaSyCXugLg4T-U-N6XfvpMF5tK2y23MQPSe4k",
  authDomain: "solidgreenstage.firebaseapp.com",
  projectId: "solidgreenstage",
  storageBucket: "solidgreenstage.appspot.com",
  messagingSenderId: "61440678027",
  appId: "1:61440678027:web:95e62b97bdf9eae5655c8e",
};

const productionConfig = {
  apiKey: "AIzaSyDzpW4xYwanrH0t_P8aFk89fp28Bm69nbs",
  authDomain: "solidgreen-4c770.firebaseapp.com",
  projectId: "solidgreen-4c770",
  storageBucket: "solidgreen-4c770.appspot.com",
  messagingSenderId: "565445049513",
  appId: "1:565445049513:web:8207922c0ac745df877db8",
  measurementId: "G-L0PM5F5106",
};

if (window.location.hostname === "localhost") {
  console.log("running on localhost");
  firebaseConfig = stagingConfig;
} else if (window.location.hostname === "solidgreenstage.web.app") {
  console.log("running on staging");
  firebaseConfig = stagingConfig;
} else if (window.location.hostname === "quote.edgesg.co.za") {
  console.log("running on production");
  firebaseConfig = productionConfig;
} else {
  console.log("running on staging");
  firebaseConfig = stagingConfig;
}

const app = firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === "localhost") {
//   app.functions().useEmulator("http://localhost:5001");
//   app.auth().useEmulator("http://localhost:9099");
//   app.firestore().settings({
//     host: "localhost:8080",
//     ssl: false,
//   });
// }

export default app;
