import { useState, useEffect } from "react";
// material
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  Card,
} from "@mui/material";
// routes
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import emailjs from "@emailjs/browser";

// import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
// import useSettings from "../../hooks/useSettings";
// components
import firebase from "../../../firebase";

import Page from "../../../components/Page";
// import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
// import { BlogNewPostForm } from "../../components/_dashboard/blog";
import { SubmissionStepper } from "src/pages/external/stepper";
import LoadingScreen from "src/components/LoadingScreen";
import TeamDialogue from "src/pages/external/Forms/InitialSetupForm/PopDialogue";
import ManageFormTransfers from "./components/ManageFormTransfers";
import { InitialAssessmentFormContainer } from "./components/InitialAssessmentDisplayer";
import { LoadingButton } from "@mui/lab";
import { INITIAL_ASSESSEMENT_STATUS } from "src/config/constants";
// ----------------------------------------------------------------------

let publicKey = "WffsPR6cSS6IwClRB";

export default function ProjectInitialAssessment({ readOnly = false }) {
  // const { themeStretch } = useSettings();
  // get the project id from the url

  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [progress, setprogress] = useState(false);
  const [sentToClientLoading, setSentToClientLoading] = useState(false);
  const [quote_id, setQuoteId] = useState("");
  const [hasInitialSubmission, setHasInitialSubmission] = useState(true);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [projectId, setProjectId] = useState();
  const [approvedCount, setApprovedCount] = useState(0);
  const [countInitialAssessmentApproved, setCountInitialAssessmentApproved] =
    useState(0);
  const [countInitialAssessmentSubmitted, setCountInitialAssessmentSubmitted] =
    useState(0);
  const [submittedCount, setSubmittedCount] = useState(0);
  const [countSavedQuestions, setCountSavedQuestions] = useState(0);
  const [countAddedDesignSpecs, setCountAddedDesignSpecs] = useState(0);
  const [formsAdded, setFormsAdded] = useState(null);
  const [formDataSatus, setFormDataSatus] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const projectCode = location.pathname.split("/")[3];
  const handleSendToClientConfirmation = () => {
    // SET INITIAL ASSESSMENT FORM TO SENT TO CLIENT
    setSentToClientLoading(true);
    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .update({
        status: INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE,
      })
      .then(() => {
        alert("Form sent to client");
        setSentToClientLoading(false);
      });
  };
  const determineDisabled = () => {
    if (countSavedQuestions === 0) {
      return true;
    }
    if (countAddedDesignSpecs === 0) {
      return true;
    }
    if (countAddedDesignSpecs < countSavedQuestions) {
      return true;
    }
    return false;
  };

  const handleSendToClient = () => {
    // SET INITIAL ASSESSMENT FORM TO SENT TO CLIENT

    setSentToClientLoading(true);

    firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .update({
        status: INITIAL_ASSESSEMENT_STATUS.IN_PROGRESS,
      })
      .then(() => {
        emailjs
          .send(
            "service_6a716og",
            "template_56brr6e",
            {
              message: `Hi ${
                quote?.client || quote?.InvoiceName
              },\n\nPlease complete the initial assessment form for your project ${
                quote?.project
              }.\n\nKind regards,\nSolid Green Projects. Attached is the brief document.`,
              subject: "Solid Green - Initial assessment form",
              reply_to: "theluxaint@gmail.com",
              to_email: quote?.email,
            },
            publicKey
          )
          .then((res) => {
            enqueueSnackbar(`Email sent to ${quote.email}`, {
              variant: "success",
            });

            setTimeout(() => {
              window.location.reload();
            }, 3000);
            console.log("res", res);
          })
          .catch((err) => {
            enqueueSnackbar("Email not sent", {
              variant: "error",
            });

            console.log("err", err);
          });
        setSentToClientLoading(false);
      });
    sentToClientLoading(false);
  };

  useEffect(() => {
    // get the project data from the database
    // set the project data to the state

    setprogress(true);

    firebase
      .firestore()
      .collection("projects")
      .where("code", "==", projectCode)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          const id = doc.id;
          setProject(data);
          setProfessionals(data?.professionals);
          setQuoteId(data.quoteId);
          setProjectId(id);

          firebase
            .firestore()
            .collection("quotes")
            .doc(data.quoteId)
            .get()
            .then(
              (doc) => {
                const data = doc.data();
                setQuote(data);
                setprogress(false);
              }
              // .catch((error) => {
              //   console.log("Error getting document:", error);
              // });
            );
        });
      });
  }, [projectCode]);

  useEffect(() => {
    // count how many submissions have been approved for this project using the edge_project_data collection
    // if the count is 0, then set the hasInitialSubmission to false
    // if the count is greater than 0, then set the hasInitialSubmission to true
    // this will be used to determine whether to show the stepper or not

    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data, "data");
          const approvedCount = Object.keys(data).filter((item) => {
            return data[item].status === "approved";
          }).length;

          const submittedCount = Object.keys(data).filter((item) => {
            return data[item].status === "submitted";
          }).length;

          setApprovedCount(approvedCount);
          setSubmittedCount(submittedCount);
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  // useEffect(() => {
  //   // get submitted responses and assign to the data object
  //   function getQuestionsWithDesignSpecification(PrelimDataForms = []) {
  //     const questions = [];
  //     for (let i = 0; i < PrelimDataForms.length; i++) {
  //       const form = PrelimDataForms[i];
  //       if (form.designSpecification !== undefined) {
  //         questions.push(form.label);
  //       }
  //     }
  //     return questions;
  //   }
  //   firebase
  //     .firestore()
  //     .collection("PrelimDataForms")
  //     .doc(projectCode)
  //     .get()
  //     .then(
  //       (doc) => {
  //         if (doc.exists) {
  //           let data = doc.data();
  //           const id = doc.id;

  //           const dataObj = {
  //             id: id,
  //             ...data,
  //           };
  //           setFormsAdded(dataObj);
  //           const questionsWithDesignSpec = getQuestionsWithDesignSpecification(
  //             dataObj?.questions
  //           );
  //           // console.log(questionsWithDesignSpec);

  //           setCountSavedQuestions(dataObj?.questions.length);
  //           setCountAddedDesignSpecs(questionsWithDesignSpec.length);
  //         } else {
  //           // doc.data() will be undefined in this case
  //           console.log("No such document!");
  //           setOpenDialogue(true);
  //         }
  //       }
  //       // , (error) => {
  //       //   console.log("Error getting document:", error);
  //       // }
  //     );
  // }, [projectCode, countAddedDesignSpecs]);

  useEffect(() => {
    function getQuestionsWithDesignSpecification(PrelimDataForms = []) {
      const questions = [];
      for (let i = 0; i < PrelimDataForms.length; i++) {
        const form = PrelimDataForms[i];
        if (form.designSpecification !== undefined) {
          questions.push(form.label);
        }
      }
      return questions;
    }

    const unsubscribe = firebase
      .firestore()
      .collection("PrelimDataForms")
      .doc(projectCode)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            let data = doc.data();
            const id = doc.id;

            const dataObj = {
              id: id,
              ...data,
            };
            setFormsAdded(dataObj);
            const questionsWithDesignSpec = getQuestionsWithDesignSpecification(
              dataObj?.questions
            );

            setSubmittedCount(submittedCount);
            // console.log(questionsWithDesignSpec);

            setCountSavedQuestions(dataObj?.questions.length);
            setCountAddedDesignSpecs(questionsWithDesignSpec.length);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setOpenDialogue(true);
          }
        },
        (error) => {
          console.log("Error getting document:", error);
        }
      );

    // unsubscribe on component unmount
    return unsubscribe;
  }, [projectCode, countAddedDesignSpecs]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("PrelimDataFormsSubmissions")
      .doc(projectCode)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            console.log(data, "data");
            const approvedCount = Object.keys(data).filter((item) => {
              return data[item].status === "approved";
            }).length;

            const submittedCount = Object.keys(data).filter((item) => {
              return (
                data[item].status === "submitted" ||
                data[item].status === "rejected"
              );
            }).length;

            setCountInitialAssessmentApproved(approvedCount);
            setCountInitialAssessmentSubmitted(submittedCount);
          }
        },
        (error) => {
          console.log("Error getting document:", error);
        }
      );

    return () => {
      unsub();
    };
  }, []);

  return (
    <Page title={`${quote?.project} | Initial assessment portal`}>
      <ManageFormTransfers
        open={openDialogue}
        setOpen={setOpenDialogue}
        cleintEmail={quote?.email}
        projectCode={projectCode}
        projectId={projectId}
        fromAdmin={true}
      />

      {progress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Container sx={{ pb: 100, pt: 8 }}>
          <Stack
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              px: 4,
              color: "black",
            }}
          >
            <Link to={`/projects/view/${quote?.projectId}`}>Back</Link>
            <Stack spacing={1}>
              <Typography variant="h4" gutterBottom>
                {`${quote?.project}`}
              </Typography>

              <Typography variant="subtitle2" gutterBottom></Typography>

              <Box sx={{ textAlign: "right", marginTop: "50px" }}>
                {formsAdded?.status ===
                INITIAL_ASSESSEMENT_STATUS.IN_PROGRESS ? null : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={setOpenDialogue}
                  >
                    Manage form selections
                  </Button>
                )}
              </Box>
            </Stack>

            <Box
              sx={{
                mt: 5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack>
                {formsAdded?.status ===
                  INITIAL_ASSESSEMENT_STATUS.IN_PROGRESS ||
                formsAdded?.status ===
                  INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE ? null : formsAdded?.status === // </LoadingButton> //   form sent to client // > //   color="primary" //   variant="contained" //   disabled={true} // <LoadingButton
                  INITIAL_ASSESSEMENT_STATUS.COMPLETED ? (
                  <LoadingButton
                    disabled={true}
                    variant="contained"
                    color="primary"
                    loading={sentToClientLoading}
                    onClick={handleSendToClient}
                  >
                    Client approved
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    disabled={determineDisabled()}
                    variant="contained"
                    color="primary"
                    loading={sentToClientLoading}
                    onClick={handleSendToClient}
                  >
                    Send to client
                  </LoadingButton>
                )}
                {countSavedQuestions - countAddedDesignSpecs <= 0 ? (
                  formsAdded?.status ===
                  INITIAL_ASSESSEMENT_STATUS.IN_PROGRESS ? (
                    countInitialAssessmentApproved >
                      countInitialAssessmentSubmitted &&
                    formsAdded?.status !==
                      INITIAL_ASSESSEMENT_STATUS.COMPLETED ? null : (
                      // <Typography variant="caption" component="h1" gutterBottom>
                      //   {countInitialAssessmentApproved} Approved /{" "}
                      //   {countInitialAssessmentSubmitted - ""} Awaiting approval
                      // </Typography>
                      <Typography variant="caption" component="h1" gutterBottom>
                        {countInitialAssessmentApproved} Approved /{" "}
                        {countInitialAssessmentSubmitted - ""} Awaiting approval
                      </Typography>
                    )
                  ) : null
                ) : (
                  <Typography variant="caption" component="h1" gutterBottom>
                    {countAddedDesignSpecs} Added /{" "}
                    {countSavedQuestions - countAddedDesignSpecs} missing design
                    specifications
                  </Typography>
                )}
                {countInitialAssessmentApproved >
                  countInitialAssessmentSubmitted &&
                formsAdded?.status !== INITIAL_ASSESSEMENT_STATUS.COMPLETED ? (
                  <Stack sx={{ width: 200, mt: 2 }}>
                    {formsAdded?.status ===
                    INITIAL_ASSESSEMENT_STATUS.PENDING_CLIENT_ACCEPTANCE ? (
                      <LoadingButton
                        disabled={true}
                        variant="contained"
                        color="primary"
                      >
                        Awaiting client approval
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        disabled={determineDisabled()}
                        variant="contained"
                        color="primary"
                        loading={sentToClientLoading}
                        onClick={handleSendToClientConfirmation}
                      >
                        Trigger client approval
                      </LoadingButton>
                    )}
                    <Typography variant="caption" component="h1" gutterBottom>
                      {countInitialAssessmentApproved} Approved /{" "}
                      {countSavedQuestions - countInitialAssessmentApproved}{" "}
                      pending approval
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Box>
          </Stack>

          {/* back button */}
          <Stack
            sx={{
              mt: -4,
              mb: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "fixed",
            }}
          >
            <Button
              variant="text"
              color="primary"
              component={Link}
              to={`/projects/view/${projectId}`}
            >
              Back
            </Button>
          </Stack>
          {/* <Stack sx={{ marginTop: 24 }} /> */}
          {/* Passing a readOnly flag to prevent admin from writing to this func */}
          {/* <SubmissionStepper projectCode={project?.code} readOnly={true} /> */}
          <InitialAssessmentFormContainer
            projectCode={project?.code}
            readOnly={true}
            countAddedDesignSpecs={countAddedDesignSpecs}
            countSavedQuestions={countSavedQuestions}
            setCountAddedDesignSpecs={setCountAddedDesignSpecs}
            setCountSavedQuestions={setCountSavedQuestions}
            formsAdded={formsAdded}
          />
        </Container>
      )}
    </Page>
  );
}
