import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetBlocks = (id) => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("logs")
      .limit(10)
      .get()
      .then((doc) => {
        const neighbourhood = [];
        doc.docs.forEach((document) => {
          const nb = {
            id: document.id,
            ...document.data(),
          };
          neighbourhood.push(nb);
        });
        // limit the number of documents to the recent 5
        const recent = neighbourhood.slice(0, 5);
        setdocs(recent);
      });
  }, []);
  return { docs };
};

export default useGetBlocks;
