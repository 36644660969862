// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
export const MainLoadingPage = ({ msg }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        zIndex: 10000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "white",
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Typography variant="h4">Initialising Project...</Typography>

        <CircularProgress size={24} color="inherit" />

        <Typography variant="body1">{msg || ""}</Typography>
      </Stack>
    </div>
  );
};
