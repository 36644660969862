import React, { useEffect } from "react";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import firebase from "src/firebase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";
import { useExternalAuth } from "src/context/externalUserContext";

// import { ControlledInputs } from "../../components/controlledInputs";
// import { questionsData } from "src/pages/mapperFuncs/questions";
// import { sanitiseFieldName } from "src/utils/smartUtils";
import { LoadingButton } from "@mui/lab";
import { UploadDevelopmentStageFile } from "./UploadFile";
import { PROJECT_STATUS } from "src/config/constants";
import { baseUrl } from "src/config";
import { UploadMechanicalFiles } from "./MechanicalUploadFile";
import { UploadElectricalFiles } from "./Electrical";
import { UploadStructuralFiles } from "./StructuralUpload";
import { UploadEngineeringFiles } from "./Engineer";
import FourFilesUploaded from "../../components/prompts/SubmitedFourInputs";
import { set } from "lodash";
// import Typography from "src/theme/overrides/Typography";
let publicKey = "WffsPR6cSS6IwClRB";
const developmentStages = [
  "Concept",
  "Design",
  "Construction",
  "Post-Construction",
];

export const FirstQuestion = (props) => {
  // handle the form submission
  const { readOnly } = props;
  const {
    projectCode,
    projectId,
    setCanGoThroughForm,
    canGoThroughForm,
    projectData,
  } = props;

  const [externalAuth] = useExternalAuth();
  // console.log("projectCode", projectCode);
  const [developmentStage, setDevelopmentStage] = React.useState("");
  const [completionDate, setCompletionDate] = React.useState("");
  const [stageFile, setStageFile] = React.useState(null);
  const [stageFile2, setStageFile2] = React.useState(null);
  const [stageFile3, setStageFile3] = React.useState(null);
  const [stageFile4, setStageFile4] = React.useState(null);
  const [fileUpload, setFileUpload] = React.useState([]);
  const [quote, setQuote] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [editable, setEditable] = React.useState(false);

  const [openFourSubmitted, setOpenFourSubmitted] = React.useState(false);

  // const [professionals, setProfessionals] = React.useState(
  //   projectData?.professionals
  // );
  const [project, setProject] = React.useState({});
  const expectdFileUploads = [
    "Mechanical",
    "Electrical",
    "Materials",
    "Structural",
  ];
  const handleChange = (event) => {
    // console.log("event.target.value", event.target.value);
    setDevelopmentStage(event.target.value);
    if (event.target.value === "Concept" || event.target.value === "Design") {
      setCanGoThroughForm(true);
    }
    if (
      event.target.value === "Construction" ||
      event.target.value === "Post-Construction"
    ) {
      setCanGoThroughForm(false);
    }
  };

  // const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const handleGetStartedSetup = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // check if the required fields are filled
    // if not, show an error message

    if (completionDate === "" || !completionDate) {
      alert("Please fill in the completion date before saving");
      setIsLoading(false);
      return;
    }
    if (developmentStage === "" || !developmentStage) {
      alert("Please fill in the developement stage before saving");
      setIsLoading(false);
      return;
    }

    // if development stage is construction or post construction then require file upload
    if (
      developmentStage === "Construction" ||
      developmentStage === "Post-Construction"
    ) {
      // check if the file is uploaded
      // if not, show an error message
      // if (stageFile === null || !stageFile || !stageFile.fileName) {
      //   console.log("stageFile", stageFile);
      //   alert("Please upload a file before saving");
      //   setCanGoThroughForm(false);
      //   setIsLoading(false);
      //   return;
      // }
    }

    let stageFileData = null;
    // if file is uploaded, save the file to the database

    if (fileUpload && fileUpload.length > 0) {
      let allFilesUploaded;
      console.log("fileUpload", fileUpload);

      if (fileUpload.length >= 3) {
        allFilesUploaded = true;
      }
      const storageRef = firebase.storage().ref();

      fileUpload.forEach((file) => {
        const fileRef = storageRef.child(
          `edge_project_data/${projectCode}/development_stage/${file?.file?.name}`
        );
        fileRef.put(fileUpload?.file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            stageFileData = {
              url,
              fileName: file?.file?.name,
              fileType: file?.file?.type,
            };

            firebase
              .firestore()
              .collection("edge_project_data")
              .doc(projectCode)
              .set(
                {
                  development_stage: {
                    stage: developmentStage,
                    [file?.name]: stageFileData,
                    allFilesUploaded: allFilesUploaded ? true : false,
                  },
                  completion_date: completionDate,
                },
                { merge: true }
              )
              .then(() => {
                setStageFile(stageFileData);
                setIsLoading(false);

                firebase
                  .firestore()
                  .collection("projects")
                  .doc(projectId)
                  .set(
                    {
                      status: PROJECT_STATUS.IN_PROGRESS,
                    },
                    { merge: true }
                  )
                  .then(() => {
                    setIsLoading(false);
                  });

                enqueueSnackbar("Data saved successfully", {
                  variant: "success",
                });
              });
          });
        });
      });
      if (allFilesUploaded) {
        const dashboardLink = baseUrl() + "/projects/view/" + projectData?.id;
        const message = `Hello admin, a new project (${quote?.project}) has been submitted. Please check the admin dashboard to verify the uploaded forms ${dashboardLink}`;

        const memebers = projectData?.members;

        memebers.forEach((member) => {
          emailjs
            .send(
              "service_6a716og",
              "template_56brr6e",
              {
                message: message,
                subject: "New project submission",
                reply_to: "theluxaint@gmail.com",
                to_email: member.email,
              },
              publicKey
            )
            .then((res) => {
              enqueueSnackbar(`Email sent to ${member.email}`, {
                variant: "success",
              });
              // reload the page after 3 seconds
              setOpenFourSubmitted(true);

              setTimeout(() => {
                window.location.reload();
              }, 3000);
              console.log("res", res);
            })
            .catch((err) => {
              enqueueSnackbar("Email not sent", {
                variant: "error",
              });

              console.log("err", err);
            });
        });
      }
    } else {
      firebase
        .firestore()
        .collection("edge_project_data")
        .doc(projectCode)
        .set(
          {
            development_stage: {
              stage: developmentStage,
            },
            completion_date: completionDate,
          },
          { merge: true }
        )
        .then(() => {
          firebase
            .firestore()
            .collection("projects")
            .doc(projectId)
            .set(
              {
                status: PROJECT_STATUS.IN_PROGRESS,
              },
              { merge: true }
            )
            .then(() => {
              setIsLoading(false);
              const professionals = quote?.professionals;

              professionals.forEach((professional) => {
                emailjs
                  .send(
                    "service_6a716og",
                    "template_im2mpu9",
                    {
                      project_code: projectCode,
                      to_name: professional.name,
                      project_name: projectData.name,
                      portal_url: `${baseUrl()}/authenticate/client`,
                      to_email: professional.email,
                    },
                    publicKey
                  )
                  .then(
                    (result) => {
                      enqueueSnackbar(
                        "Email has been sent to " + professional.email,
                        {
                          variant: "success",
                        }
                      );
                      console.log(result.text);
                    },
                    (error) => {
                      enqueueSnackbar(
                        "Email could not be sent to ." + professional.email,
                        {
                          variant: "error",
                        }
                      );
                    }
                  );
              });
            });

          setOpenFourSubmitted(false);
          console.log("Data saved successfully");
        });
    }
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("edge_project_data")
      .doc(projectCode)
      .get()
      .then((doc) => {
        setProject(doc.data());
        const data = doc.data();

        setDevelopmentStage(data?.development_stage?.stage);
        if (
          data?.development_stage?.stage === "Concept" ||
          data?.development_stage?.stage === "Design"
        ) {
          setCanGoThroughForm(true);
        }
        setCompletionDate(data?.completion_date);
        setStageFile(data?.development_stage?.stage_file);
      });

    // console.log("project", project);
  }, [projectCode, setCanGoThroughForm]);

  useEffect(() => {
    // get quote data from the database
    // set the quote data to the state
    firebase
      .firestore()
      .collection("quotes")
      .doc(projectData?.quoteId)
      .get()
      .then((doc) => {
        setQuote(doc.data());
      });
  }, [projectData?.quoteId]);

  // set canSubmitFields based on page and externalAuth profession
  React.useEffect(() => {
    if (externalAuth?.isOwner) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [externalAuth]);
  return (
    // [TODO: create an object  that will contain the controlledInputs Data and replace below laziness with a map ]
    <div>
      <FourFilesUploaded
        open={openFourSubmitted}
        setOpen={setOpenFourSubmitted}
      />
      <Grid container md={12}>
        <Grid
          md={12}
          style={{
            // backgroundColor: "red",
            padding: "20px",
            position: "relative",
          }}
        >
          <Typography variant="h6" color={"primary"}>
            Project Information
          </Typography>

          {/* {professionals?.length > 0 ? null : (
          <div
            style={{
              backgroundColor: "rgb(249 249 249 / 90%)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
              cursor: "not-allowed",
            }}
          />
        )} */}
          {/* <Typography variant="h6">Get Started</Typography> */}
          <Stack
            spacing={2}
            style={{
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={handleGetStartedSetup}
              style={{
                // width: "100%",
                width: "100%",
              }}
            >
              {/* container to have 2 inputs side by side */}
              <InputLabel id="demo-simple-select-label">
                What stage is the development?
              </InputLabel>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Select
                    disabled={!editable}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={developmentStage}
                    label="Development Stage"
                    placeholder="Development Stage"
                    onChange={handleChange}
                    sx={{ width: "80%" }}
                  >
                    {developmentStages.map((stage) => (
                      <MenuItem value={stage}>{stage}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <label htmlFor="outlined-basic"></label>
                  <TextField
                    disabled={!editable}
                    id="outlined-basic"
                    label="Expected Practical Completion Date"
                    variant="outlined"
                    type="date"
                    value={completionDate}
                    sx={{ width: "80%" }}
                    onChange={(e) => setCompletionDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              {developmentStage === "Concept" || !developmentStage ? null : (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 4,
                    }}
                    color={"primary"}
                  >
                    Please upload the following documents:
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      my: 2,
                    }}
                  >
                    <Grid item md={6}>
                      <UploadMechanicalFiles
                        disabled={!editable}
                        onChange={(e) => {
                          // setStageFile(e);
                          if (e !== null) {
                            setFileUpload([
                              ...fileUpload,
                              { name: "mechanic", file: e.target.files[0] },
                            ]);
                            console.log(
                              "e.target.files",
                              e.target.files[0],
                              "<<<< attached"
                            );
                          } else {
                            setFileUpload(null);
                            console.log(
                              "e.target.files",
                              null,
                              "<<<< detached"
                            );
                          }

                          // console.log("e.target.files", e.target.files);
                        }}
                        required={
                          developmentStage === "Construction" ||
                          developmentStage === "Post-Construction"
                            ? true
                            : false
                        }
                        projectCode={projectCode}
                        setStageFile={setStageFile2}
                        stageFile={stageFile2}
                        name={"mechanicalFiles"}
                        mainLabel={"Mechanical Files"}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <UploadElectricalFiles
                        disabled={!editable}
                        onChange={(e) => {
                          // setStageFile(e);
                          if (e !== null) {
                            setFileUpload([
                              ...fileUpload,
                              { name: "electric", file: e.target.files[0] },
                            ]);
                          } else {
                            setFileUpload(null);
                            console.log(
                              "e.target.files",
                              null,
                              "<<<< detached"
                            );
                          }

                          // console.log("e.target.files", e.target.files);
                        }}
                        required={
                          developmentStage === "Construction" ||
                          developmentStage === "Post-Construction"
                            ? true
                            : false
                        }
                        projectCode={projectCode}
                        setStageFile={setStageFile2}
                        stageFile={stageFile2}
                        name={"electricalFiles"}
                        mainLabel={"Electric Files"}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      my: 2,
                    }}
                  >
                    <Grid item md={6}>
                      <UploadEngineeringFiles
                        disabled={!editable}
                        onChange={(e) => {
                          // setStageFile(e);
                          if (e !== null) {
                            setFileUpload([
                              ...fileUpload,
                              { name: "engineering", file: e.target.files[0] },
                            ]);
                          } else {
                            setFileUpload(null);
                          }

                          // console.log("e.target.files", e.target.files);
                        }}
                        required={
                          developmentStage === "Construction" ||
                          developmentStage === "Post-Construction"
                            ? true
                            : false
                        }
                        projectCode={projectCode}
                        setStageFile={setStageFile3}
                        stageFile={stageFile3}
                        name={"electricalFiles"}
                        mainLabel={"Engineer Files"}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <UploadStructuralFiles
                        disabled={!editable}
                        onChange={(e) => {
                          // setStageFile(e);
                          if (e !== null) {
                            setFileUpload([
                              ...fileUpload,
                              { name: "structural", file: e.target.files[0] },
                            ]);
                          } else {
                            setFileUpload(null);
                            console.log(
                              "e.target.files",
                              null,
                              "<<<< detached"
                            );
                          }

                          // console.log("e.target.files", e.target.files);
                        }}
                        required={
                          developmentStage === "Construction" ||
                          developmentStage === "Post-Construction"
                            ? true
                            : false
                        }
                        projectCode={projectCode}
                        setStageFile={setStageFile2}
                        stageFile={stageFile2}
                        name={"electricalFiles"}
                        mainLabel={"Structural Files"}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Stack
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                {readOnly ? null : !editable ? null : (
                  <LoadingButton
                    disabled={!editable}
                    loading={isLoading}
                    type="submit"
                    sx={{
                      mt: 3,
                    }}
                    variant="outlined"
                    style={{ width: 200 }}
                    onClick={handleGetStartedSetup}
                  >
                    Save project information
                  </LoadingButton>
                )}
              </Stack>
            </form>

            <br />
            <br />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
