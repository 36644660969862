import { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Card,
  Stack,
  Link,
  Box,
  Button,
  Container,
  Typography,
} from "@mui/material";
// layouts
import AuthLayout from "../../../layouts/AuthLayout";
// components
import Page from "../../../components/Page";
import { ClientAuthForm } from "../Forms/ClientAuthForm";
// ----------------------------------------------------------------------
import firebase from "../../../firebase";
import SubmitQuoteForm from "../components/SubmitQuoteForm";
import { PdfDisplayer } from "src/components/customs/PdfDisplayer";
import ExternalQuote from "./Quote";

import TeamDialogue from "../Forms/InitialSetupForm/PopDialogue";
import ProffessionalDialogueBox from "../Forms/InitialSetupForm/ProffessionalsDialogue";
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  // maxWidth: 480,
  margin: "auto",
  display: "flex",
  // minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function InvoiceSubmit() {
  // get the project id from the url
  const location = useLocation();
  const quoteId = location.pathname.split("/")[2];
  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);

  const [professionals, setProfessionals] = useState([]);

  const handlSetOpenDialogue = (action) => {
    // don't close the dialogue unless there is atleast one professional
    if (true) {
      setOpenDialogue(false);
    } else {
      setOpenDialogue(true);
    }
  };
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("quotes")
      .doc(quoteId)
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        if (data?.professionals) {
          setProfessionals(data?.professionals);
          if (data?.professionals.length > 0) {
            setDisableSubmit(false);
          }
        } else {
          setProfessionals([]);
          setDisableSubmit(true);
        }

        handlSetOpenDialogue();
      });
    return () => unsubscribe();
  }, [quoteId]);

  console.log(professionals, "professionals bingo");
  return (
    <RootStyle title="Accept quote">
      <Container>
        <ProffessionalDialogueBox
          open={openDialogue}
          setOpen={handlSetOpenDialogue}
          users={professionals}
          setUsers={setProfessionals}
          quoteId={quoteId}
        />

        <ContentStyle>
          <Stack>
            <img
              src="/static/SolidEdge-Logo.svg"
              alt="login"
              style={{ width: 128 }}
            />
          </Stack>

          {/* <PdfDisplayer url={quote?.pdfUrl} /> */}
          {/* <LoginForm /> */}
          {/* <ClientAuthForm /> */}
        </ContentStyle>
        <Card sx={{ mb: 2, p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Accept Quote
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Before you can accept the quote, you need to add atleast one
            profesionals to the project.
          </Typography>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={setOpenDialogue}
            >
              Add Professionals
            </Button>
          </Box>
        </Card>

        <ExternalQuote disable={disableSubmit} />
      </Container>
    </RootStyle>
  );
}
