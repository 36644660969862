import React, { useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import firebase from "../../../firebase";
import {
  Stack,
  Typography,
  TextField,
  Grid,
  Card,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function ClientView() {
  const navigate = useNavigate();
  const [progress, setprogress] = React.useState(false);
  const [client, setClient] = React.useState(null);
  const [values, setValues] = useState("");
  // get the project id from the url
  const location = useLocation();
  const email = location.pathname.split("/")[3];

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleUpdateClient = () => {
    setprogress(true);
    // setmsg("Creating client...");
    firebase
      .firestore()
      .collection("clients")
      .add({
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        type: "client",
        createdAt: new Date(Date.now()).toLocaleString(),
      })
      .then((docRef) => {
        setprogress(false);
        // notify("Client created successfully");
        navigate("/clients/view/" + values.email);
      })
      .catch((error) => {
        setprogress(false);
        // notify("Error creating client");
      });
  };
  React.useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("clients")
      .where("email", "==", email)
      .onSnapshot((snapshot) => {
        const newClient = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClient(newClient[0]);
        console.log(newClient, "check client fetch");
      });

    return () => unsubscribe();
  }, []);
  return (
    <div>
      <Link to="/clients">Go back</Link> <br />
      <Stack direction="row" justifyContent="center" mb={5}>
        <Stack direction="row" justifyContent="center" mb={5}>
          <Button
            variant="contained"
            onClick={() => navigate(`/quote/create?email=${email}`)}
          >
            create quote
          </Button>

          <Button
            variant="contained"
            onClick={() => navigate(`/clients/quotes/${email}`)}
          >
            View quotes
          </Button>
        </Stack>
        <br />
      </Stack>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Update client
          </Typography>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  onChange={handleChange("first_name")}
                  label="First Name"
                />
                <TextField
                  fullWidth
                  onChange={handleChange("last_name")}
                  label="Last Name"
                />
                <TextField
                  fullWidth
                  type="email"
                  onChange={handleChange("email")}
                  label="Email Address"
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={progress}
                  onClick={handleUpdateClient}
                >
                  Update client
                </LoadingButton>
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
