import { Link as RouterLink, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
// material
import { Grid, Button, Container, Checkbox, Stack, Typography, Card, TextField, MenuItem } from '@mui/material';
// components
import Page from '../../../components/Page';
 
import firebase from '../../../firebase'  
import UserView from '../UserView';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Residential({data}) {
 
    console.log(data)
    const notify = (msg) => toast(msg);
    
    const residentialSchema = Yup.object().shape({
        DCPCC : Yup.string().required("Enter a value"),
        DPC : Yup.string().required("Enter a value"),
        PA : Yup.string().required("Enter a value"),
        PCA : Yup.string().required("Enter a value"),       
    })
 
    const residenitalformik = useFormik({
        initialValues:{
            DCPCC: data.DCPCC,
            DPC: data.DPC,
            PA: data?.PA || "",
            PCA: data?.PCA || "",          
            lastUpdated : new Date(Date.now()).toLocaleDateString(),
            user_id : firebase.auth().currentUser.uid
        },
        validationSchema: residentialSchema,
        onSubmit : async (values, {setSubmitting, resetForm})=>{
            try{
            //    console.log(values)
                await updateResidentialValues(values)
                setSubmitting(false)
            } catch (error){
           //     console.log(error)
                setSubmitting(false)
            }
        }
    })

    const {errors, values, touched, handleSubmit, isSubmitting, getFieldProps} = residenitalformik
     
  

 const updateResidentialValues = (values) =>{
    firebase.firestore().collection("settings").doc("residential").set(values).then(()=>{
      notify("updated")
    })
}



  return (
    
      <Grid item xs={12} sm={12} md={6}>
      <FormikProvider value={residenitalformik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
  
      <Card sx={{p:3}}>
        <Stack spacing={3}>  
        <Typography variant="body1" gutterBottom>
          Residential
        </Typography>     
        <TextField
        fullWidth type="number"
         
        {...getFieldProps('DPC')}
        error={Boolean(touched.DPC && errors.DPC)}
        helperText={touched.DPC && errors.DPC}
        label="Documentation and Preliminary Certification" /> 

        <TextField
        fullWidth
        type="number"
         {...getFieldProps('DCPCC')}
        error={Boolean(touched.DCPCC && errors.DCPCC)}
        helperText={touched.DCPCC && errors.DCPCC} 
        label="Documentation and Compiling Post Construction Certification" /> 
        
        <TextField
        fullWidth type="number"
         {...getFieldProps('PA')}
        error={Boolean(touched.PA && errors.PA)}
        helperText={touched.PA && errors.PA}
        label="Preliminary Audit" /> 
        
        <TextField
        fullWidth type="number"
          {...getFieldProps('PCA')}
        error={Boolean(touched.PCA && errors.PCA)}
        helperText={touched.PCA && errors.PCA}  
        label="Post Construction Audit" />         
        <Typography>
          Last Updated {data.lastUpdated} by <UserView data={data.user_id} />
        </Typography>
        <LoadingButton  type="submit" variant="contained" size="large" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
         
        </Stack>
      </Card>
      </Form>
      </FormikProvider>
      </Grid>
  )
}
