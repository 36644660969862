import { useState, useEffect } from "react";
// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  Stack,
  Typography,
  Card,
  TextField,
  CircularProgress,
  LinearProgress,
  Box,
  MenuItem,
} from "@mui/material";
import Page from "src/components/Page";
import { Link, useNavigate, useLocation } from "react-router-dom";
import firebase from "src/firebase";

import { InfoCols1, InfoCols2 } from "./components/ProjectInfoCols";
import { StatusBarTracker } from "./components/StatusBarTracker";

// import { MainLoadingPage } from "src/components/customs/MainLoadingPage";
import { projectDataSchemaShaper } from "src/utils/dataSchemers";

import useGetProject from "src/hooks/useGetProject";
import useGetQuote from "src/hooks/useGetQuote";
import {
  countObjectElements,
  formatDateWithMoment,
  makeStatusReadable,
} from "src/utils/smartUtils";
import moment from "moment";
import LoadingScreen from "src/components/LoadingScreen";
import { EdgeRFI } from "src/pages/central/ProjectStatus/EdgeRfi";
import { InitialAssessMent } from "src/pages/central/ProjectStatus/InitialAssessment";
import { RoundOneSubmission } from "src/pages/central/ProjectStatus/RoundOne";
import { Certification } from "src/pages/central/ProjectStatus/Certification";
import { totalQuestions } from "src/pages/mapperFuncs/questions";
import ProffessionalDialogueBox from "./Forms/InitialSetupForm/ProffessionalsDialogue";
import { useExternalAuth } from "src/context/externalUserContext";

export default function HomeDashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = location.pathname.split("/")[3];
  const [quoteId, setQuoteId] = useState(null);
  const [project, setProject] = useState(null);
  const [quote, setQuote] = useState(null);
  const [externalAuth, setExternalAuth] = useExternalAuth();

  const [openDialogue, setOpenDialogue] = useState(false);

  const [professionals, setProfessionals] = useState([]);

  const handlSetOpenDialogue = (action) => {
    // don't close the dialogue unless there is atleast one professional
    if (professionals.length >= 1) {
      setOpenDialogue(false);
    } else {
      setOpenDialogue(true);
    }
  };

  if (!projectId) {
    navigate("/404");
  }
  const [progress, setprogress] = useState(false);

  const handleNavigateToQuote = (quoteId) => {
    console.log(quoteId, "quote external");
    // return;
    navigate(`/quote/${quoteId}`);
  };

  const handleNavigateToWorkingTeam = () => {
    setOpenDialogue(true);
  };
  const handleClientSignOut = () => {
    // delete local storage
    localStorage.removeItem("externalAuth");
    // redirect to login page
    navigate("/authenticate/client");
  };
  useEffect(() => {
    setprogress(true);
    firebase
      .firestore()
      .collection("projects")
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProject(doc.data());
          setQuoteId(doc.data().quoteId);

          firebase
            .firestore()
            .collection("quotes")
            .doc(doc.data().quoteId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const data = doc.data();
                setQuote(data);
                if (data.professionals) {
                  setProfessionals(data.professionals);
                } else {
                  setProfessionals([]);
                }
              } else {
                console.log("No such document!");
              }
            });
          setprogress(false);
          console.log(doc.data(), "documnet found");
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, [projectId]);

  useEffect(() => {
    console.log(externalAuth, "externalAuth home");
  }, [externalAuth]);
  return (
    <Page title="Dashboard">
      {progress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <>
          {/* back button */}

          <ProffessionalDialogueBox
            open={openDialogue}
            setOpen={handlSetOpenDialogue}
            users={professionals}
            setUsers={setProfessionals}
            quoteId={quoteId}
          />
          <Container
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "space-between",
              textAlign: "right",
            }}
          >
            <Stack>
              <img
                src="/static/SolidGreen-Logo.svg"
                alt="login"
                style={{ width: 128 }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" color="GrayText">
                Logged in as
                <br />
                {externalAuth?.email} <br /> (
                {externalAuth?.profession || " Project Owner"})
              </Typography>
            </Stack>
          </Container>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 4,
            }}
          >
            <Typography variant="h4" color="GrayText">
              {quote?.project || quote?.client}
            </Typography>
            <Button variant="outlined" onClick={handleClientSignOut}>
              Sign out
            </Button>
          </Container>
          <Container
            style={{
              backgroundColor: "#79838D",
              padding: "20px",
              paddingLeft: "40px",
              paddingRight: "40px",
              borderRadius: "12px",
            }}
          >
            <InfoCols1
              func={handleNavigateToWorkingTeam}
              firstDatasetInRibbon={{
                client: quote?.client || quote?.email,
                payment: makeStatusReadable(quote?.status),
                currentDuration: formatDateWithMoment(project?.createdAt),
              }}
            />
            <InfoCols2
              secondDatasetInRibbon={{
                gfa: quote?.gfa,
                buildingType: quote?.type,
                performance_modeling: quote?.PMS_Enabled,
              }}
              linkTo={quoteId}
              func={() => {
                handleNavigateToQuote(quoteId);
              }}
            />
            {/* add link to drive that opens a new tab */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2, width: "300px" }}
            >
              {/* <Button
                variant="contained"
                sx={{
                  cursor: project?.driveUrl ? "pointer" : "not-allowed",
                }}
                disabled={project?.driveUrl ? false : true}
              >
                {project?.driveUrl ? (
                  <a href={`${project.driveUrl}`}>
                    <Typography variant="button" color="white">
                      Drive Link
                    </Typography>
                  </a>
                ) : (
                  <Typography variant="button" color="white">
                    Drive Link
                  </Typography>
                )}
              </Button> */}
            </Stack>
          </Container>
          <Container>
            <Box sx={{ mt: 3, pl: 4 }}>
              {/* <Typography variant="subtitle2" color="GrayText" gutterBottom>
                Recent client activity -
              </Typography> */}
            </Box>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                md={9}
                justifyContent="space-between"
                p={2}
              >
                <Stack xs={12} md={6}>
                  <Typography variant="h4" color="GrayText" gutterBottom>
                    Expert Services
                  </Typography>
                  <EdgeRFI fromClient={true} projectId={projectId} />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <InitialAssessMent
                    fromClient={true}
                    projectCode={project?.code}
                  />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <RoundOneSubmission
                    fromClient={true}
                    projectCode={project?.code}
                  />
                  <Box sx={{ mt: 3, pl: 4 }} />
                  <Certification
                    projectCode={project?.code}
                    fromClient={true}
                  />
                </Stack>

                {/* <Stack xs={12} md={6}>
                  <Typography variant="h4" color="GrayText" gutterBottom>
                    Auditor Services
                  </Typography>
                </Stack> */}
              </Grid>
              <Grid item xs={12} md={3} p={2}>
                {/* <Typography variant="h4" color="GrayText" gutterBottom>
                  Recent Activity
                </Typography> */}
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </Page>
  );
}
