import { forwardRef, useState } from "react";
// material
import {
  Slide,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function TransitionsDialogs({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Submission complete!
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Your submission has been completed. Our team will be verifying your
          submission and will get back to you shortly.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button color="inherit" onClick={handleClose}>
          Disagree
        </Button> */}
        <Button variant="contained" onClick={handleClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
