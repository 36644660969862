import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import firebase from "src/firebase";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from "@mui/material";
// components
import Page from "src/components/Page";

import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/sections/@dashboard/user";
//
//import USERLIST from '../_mocks_/user';
import useGetQuotes from "src/hooks/useGetQuotes";
import { fNumber } from "src/utils/formatNumber";
import { useAuthUser } from "src/context/auth";
import {
  formatDateWithMoment,
  makeStatusReadable,
  renderStatusConsideringNewAndOldStatus,
} from "src/utils/smartUtils";
import LoadingScreen from "src/components/LoadingScreen";
import { calculateDaysPast } from "src/utils/dateUtils";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "project_code", label: "Code", alignRight: false },
  { id: "project", label: "Project Name", alignRight: false },
  { id: "client", label: "Client", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "payment_status", label: "Payment", alignRight: false },
  { id: "duration", label: "Duration", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.status.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.createdAt.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProjectTable({ projects }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [progress, setProgress] = useState(false);
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);

  let PROJECTSLIST = projects;
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = PROJECTSLIST.map((n) => n.client);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - PROJECTSLIST.length) : 0;

  const filteredUsers = applySortFilter(
    PROJECTSLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  // const getQuoteFromFirebase = async (id) => {
  //   const quoteRef = firebase.firestore().collection("quotes").doc(id);
  //   const doc = await quoteRef.get();
  //   if (!doc.exists) {
  //     console.log("No such document!");
  //   } else {
  //     setQuote(doc.data());
  //     setLoading(false);
  //     return doc.data();
  //   }
  // };

  useEffect(() => {
    setProgress(true);
    setTimeout(() => {
      setProgress(false);
    }, 2000);
  }, []);

  return (
    <Page title="Project list">
      {progress ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Container maxWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Projects
            </Typography>
          </Stack>

          <Card>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={PROJECTSLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          code,
                          quote,
                          createdAt,
                          status,
                          project,
                          TotalFees,
                          email,
                        } = row;

                        console.log("quote", quote, "<<<<< y");
                        return (
                          <TableRow hover key={code} tabIndex={-1}>
                            <TableCell align="left">{code}</TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginLeft: 2 }}
                                  noWrap
                                >
                                  {quote?.project}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {quote?.client || email}
                            </TableCell>
                            <TableCell align="left">{status}</TableCell>
                            <TableCell align="left">{quote?.status}</TableCell>
                            <TableCell align="left">
                              {formatDateWithMoment(createdAt)}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              <Link
                                to={"/projects/view/" + id}
                                state={{ data: id }}
                                style={{
                                  display: "grid",
                                  color: "inherit",
                                  textDecoration: "none",
                                  justifyContent: "center",
                                }}
                              >
                                <Button size="small" variant="contained">
                                  View
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={PROJECTSLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
