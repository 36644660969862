// SG#Admin
// lombefrancis@gmail.vom
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// import firebase from "src/firebase"; // Make sure this path matches where your Firebase config is initialized

// material
import {
  Grid,
  Button,
  Container,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
  Card,
  TextField,
  MenuItem,
  Input,
} from "@mui/material";

import Page from "../../../components/Page";
import { fCurrency, fNumber } from "../../../utils/formatNumber";
import firebase from "../../../firebase";
import emailjs from "@emailjs/browser";
import ReactPDF, { Document, usePDF, Text } from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InvoicePDF from "../InvoicePDF";
import useConstants from "../../../hooks/useConstants";
import { QUOTE_STATUS } from "src/config/constants";
import { baseUrl } from "src/config";
import { MainLoadingPage } from "src/components/customs/MainLoadingPage";
import InvoiceForm from "src/pages/external/invoice-submit/InvoiceForm";
import FullPDF from "../FullPDF";
import "firebase/storage";

export default function QuoteCreate() {
  const settings = useConstants().docs;
  const notify = (msg) => toast(msg);
  const [officeValues, setOfficeValues] = useState(null);
  const [residentialValues, setresidentialValues] = useState(null);
  const [warehouseValues, setWarehouseValues] = useState(null);
  const [existingQuoteValues, setExistingQuoteValues] = useState(null);
  const [values, setValues] = useState("");
  const [progress, setprogress] = useState(false);
  const [msg, setmsg] = useState("");
  const [client, setClient] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);
  const [clientFirstName, setClientFirstName] = useState(null);
  const [clientLastName, setClientLastName] = useState(null);
  const [fileURLCOmplete, setFileURLComplete] = useState(null);

  // console.log(settings, "check settings");
  // get email from url query
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [quoteId, setQuoteId] = useState(null);
  const email = query.get("email");

  const [checked, setChecked] = useState(false);
  let publicKey = "WffsPR6cSS6IwClRB";
  const [options] = useState([null, "Office", "Residential", "Warehouse"]);
  const [optionsType] = useState([null, "Edge", "GreenStar", "NetZero"]);

  const handleChange = (prop) => (event) => {
    if (prop === "type") {
      setOfficeValues(null);
      setWarehouseValues(null);
      setresidentialValues(null);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  async function getBlob(doc, data, id) {
    setmsg("Creating PDF");
    const blob = await ReactPDF.pdf(<FullPDF data={data} />)
      .toBlob()
      .then((res) => {
        sendEmail(res, doc, id, data);
      })
      .catch((err) => {
        console.log(err);
      });
    return blob;
  }

  const sendEmail = (blob, quote, id, data) => {
    setmsg("Sending Email");
    let storageRef = firebase.storage().ref().child(`${id}_SOLID_EDGE_QUOTE`);
    storageRef.put(blob).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        console.log(percentage);
      },
      (err) => {
        notify(err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        var file = await convertBlobToBase64(blob);

        let simpleFIle = null;
        await ReactPDF.pdf(<InvoicePDF data={data} />)
          .toBlob()
          .then(async (res) => {
            simpleFIle = await convertBlobToBase64(res);
            console.log(simpleFIle, "Testing 0");
          })
          .catch((err) => {
            console.log(err);
          });

        firebase
          .firestore()
          .collection("quotes")
          .doc(quote.id)
          .update({
            quote_file: url,
          })
          .then((doc) => {
            let totals = null;

            if (values.type === "Office") {
              totals = officeValues.TotalFees.toFixed(2);
            }
            if (values.type === "Warehouse") {
              totals = warehouseValues.TotalFees.toFixed(2);
            }
            if (values.type === "Residential") {
              totals = residentialValues.TotalFees.toFixed(2);
            }

            const invoiceUrl = `${baseUrl()}/invoice/${quoteId || quote.id}`;
            let obj = {
              id: id,
              invoiceUrl,
              email: values.email,
              client: values.client,
              project: values.project,
              total: totals,
              breakdown:
                values.type === "Office" || values.type === "Warehouse"
                  ? `Gross Floor Area ${values.gfa}`
                  : `Topology : ${values.typology}, Units : ${values.units}`,

              link: url,
              content: simpleFIle,
            };

            setmsg("Uploading files to server");
            emailjs
              .send("service_6a716og", "template_galhv3r", obj, publicKey)
              .then(
                (result) => {
                  setmsg("Email has been sent.");
                  setValues([]);
                  setresidentialValues(null);
                  setWarehouseValues(null);
                  setOfficeValues(null);
                  console.log(result, "email sent");

                  setprogress(false);
                  setmsg("");
                },
                (error) => {
                  console.log(error.text);
                  console.log(error);
                }
              );
          });

        console.log(url);

        // navigate to the quote page
        navigate(`/quote/${quote.id}`, { replace: true });
      }
    );
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const convertBlobToBase64 = async (blob) => {
    // blob data
    return await blobToBase64(blob);
  };

  // calculate for office
  const calculateOffice = (gfa, existingValues) => {
    setWarehouseValues(null);
    setresidentialValues(null);
    let officeValues = settings[0];
    let data;
    let DPC;

    if (!existingValues) {
      DPC =
        (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
          0.00007 * Math.pow(parseFloat(gfa), 2) +
          2.6441 * parseFloat(gfa) +
          20629) *
        officeValues.PC;

      data = {
        SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
        DPC: parseFloat(DPC.toFixed(2)),
        DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
        ExpertServices:
          parseFloat((DPC * officeValues?.SC).toFixed(2)) +
          parseFloat(DPC.toFixed(2)) +
          parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
        PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
        PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
        AuditorServices:
          parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
          parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
          (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
        PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
        PMS_Enabled: checked,
        TotalFees:
          parseFloat((DPC * officeValues?.SC).toFixed(2)) +
          parseFloat(DPC.toFixed(2)) +
          parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
          parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
          parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
          (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
      };
    } else {
      console.log("using existing value Office ✅ ", existingValues);
      // DPC = existingValues.DPC;
      const ExpertServices =
        existingValues.SC + existingValues.DPC + existingValues.DPCC;

      const AuditorServices =
        existingValues.PDA +
        existingValues.PCSA +
        (existingValues.PMS !== undefined ? existingValues.PMS : 0);
      console.log("AS CHECK", AuditorServices);
      const TotalFees = ExpertServices + AuditorServices;

      data = {
        PA: existingValues.SC,
        DPC: existingValues.DPC,
        DPCC: existingValues.DPCC,
        PDA: existingValues.PDA,
        PCSA: existingValues.PCSA,
        PMS: existingValues.PMS || 0,
        PMS_Enabled: checked,
        ExpertServices,
        AuditorServices,
        TotalFees,
      };
    }
    console.log("DATTATATA", { data });
    // AuditUnits
    setOfficeValues(data);

    return data;
  };

  // calculate for residential
  const calculateResidential = (typology, units, existingValues) => {
    setOfficeValues(null);
    setWarehouseValues(null);
    let officeValues = settings[1];
    let eight = units / typology;
    let ES;
    let DPC;
    let AS;
    let data;

    if (!existingQuoteValues) {
      ES =
        16555 +
        officeValues.DPC * parseInt(typology) +
        officeValues.DCPCC * parseInt(typology);

      DPC = parseFloat(officeValues.DPC * typology).toFixed(2) * 1;

      const PA_ = parseFloat(officeValues.DPC * officeValues.PA * typology);
      const PCA_ = parseFloat(
          officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1))
        ),
        AS = PA_ + PCA_ + (checked && parseFloat(DPC * officeValues?.PMS));

      // AS =
      //   officeValues.DPC * officeValues.PA * typology +
      //   officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1)) +
      //   DPC +
      //   (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2)));

      data = {
        ExpertServices: ES,
        AuditUnits: parseFloat(typology * (Math.sqrt(eight) + 1)).toFixed(0),
        IA: 16555,
        DPC: DPC,
        DCPCC: parseFloat(officeValues.DCPCC * typology).toFixed(2),
        AuditorServices: AS,
        PA: parseFloat(officeValues.DPC * officeValues.PA * typology),
        PCA: parseFloat(
          officeValues.PCA * parseFloat(typology * (Math.sqrt(eight) + 1))
        ),
        PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
        PMS_Enabled: checked,

        TotalFees: ES + AS,
      };
    } else {
      const ExpertServices =
        existingValues.AuditUnits + existingValues.DPC + existingValues.DPCC;

      const AuditorServices =
        existingValues.PDA + existingValues.PCSA + (existingValues.PMS || 0);

      const TotalFees = ExpertServices + AuditorServices;

      console.log("check exsisting services", existingValues);
      console.log(AuditorServices, "check auditor services");
      console.log(ExpertServices, "check expert services");
      console.log(TotalFees, "check total fees");

      data = {
        SC: existingValues.AuditUnits,
        DPC: existingValues.DPC,
        DPCC: existingValues.DPCC,
        PDA: existingValues.PDA,
        PCSA: existingValues.PCSA,
        PMS: existingValues.PMS,
        PMS_Enabled: checked,
        PMS: checked,
        type: "Residential",
        ExpertServices: ExpertServices,
        AuditorServices: AuditorServices,
        TotalFees,
      };

      // data = {

      //   AuditUnits: existingValues.AuditUnits,
      //   IA: existingValues.AuditUnits,

      //   PA: existingValues.PDA,
      //   PCA: existingValues.PCSA,
      //   PMS: checked,
      //   PMS_Enabled: checked,

      //   TotalFees,
      // };

      // data = {

      //   SC: existingValues.SC,
      //   DPC: existingValues.DPC,
      //   DPCC: existingValues.DPCC,
      //   PDA: existingValues.PDA,
      //   PCSA: existingValues.PCSA,
      //   PMS: existingValues.PMS,
      //   PMS_Enabled: checked,
      //   ExpertServices,
      //   AuditorServices,
      //   TotalFees,
      // };
    }

    console.log(data, "check for data object <<<<<");
    setresidentialValues(data);
    return data;
  };

  // calculate for office
  const calculateWarehouse = (gfa) => {
    setOfficeValues(null);
    console.log("QARE HOUSE", { existingQuoteValues });
    const existingValues = existingQuoteValues;
    setresidentialValues(null);
    let officeValues = settings[2];

    let data;
    let DPC =
      (0.0000000009 * Math.pow(parseFloat(gfa), 3) -
        0.00007 * Math.pow(parseFloat(gfa), 2) +
        2.6441 * parseFloat(gfa) +
        20629) *
      officeValues.PC;

    if (!existingValues) {
      data = {
        SC: parseFloat((DPC * officeValues?.SC).toFixed(2)),
        DPC: parseFloat(DPC.toFixed(2)),
        DPCC: parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
        ExpertServices:
          parseFloat((DPC * officeValues?.SC).toFixed(2)) +
          parseFloat(DPC.toFixed(2)) +
          parseFloat((DPC * officeValues?.DPCC).toFixed(2)),
        PDA: parseFloat((DPC * officeValues?.PDA).toFixed(2)),
        PCSA: parseFloat((DPC * officeValues?.PCSA).toFixed(2)),
        AuditorServices:
          parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
          parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
          (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
        PMS: checked ? parseFloat((DPC * officeValues?.PMS).toFixed(2)) : 0,
        PMS_Enabled: checked,
        TotalFees:
          parseFloat((DPC * officeValues?.SC).toFixed(2)) +
          parseFloat(DPC.toFixed(2)) +
          parseFloat((DPC * officeValues?.DPCC).toFixed(2)) +
          parseFloat((DPC * officeValues?.PDA).toFixed(2)) +
          parseFloat((DPC * officeValues?.PCSA).toFixed(2)) +
          (checked && parseFloat((DPC * officeValues?.PMS).toFixed(2))),
      };

      setWarehouseValues(data);
    } else {
      console.log("EXSIT WAREHouse", existingValues);
      const AuditorServices =
        existingValues.PDA +
        existingValues.PCSA +
        (existingValues.PMS !== undefined ? existingValues.PMS : 0);
      const ExpertServices =
        existingValues.SC + existingValues.DPC + existingValues.DPCC;
      const TotalFees = ExpertServices + AuditorServices;

      data = {
        PA: existingValues.SC,
        DPC: existingValues.DPC,
        DPCC: existingValues.DPCC,
        PDA: existingValues.PDA,
        PCSA: existingValues.PCSA,
        PMS: existingValues.PMS || 0,
        PMS_Enabled: checked,
        ExpertServices,
        AuditorServices,
        TotalFees,
      };
    }
    setWarehouseValues(data);

    return data;
  };

  const calculate = () => {
    if (values.gfa === undefined) {
      alert("Enter a  value");
      return;
    }

    calculateOffice(values.gfa);
  };

  const calculateR = () => {
    if (values.typology === undefined || values.units === undefined) {
      alert("Enter your typology and units.");

      return;
    }

    calculateResidential(values.typology, values.units);
  };

  const calculateW = () => {
    if (values.gfa === undefined) {
      alert("Enter a GFA value");
      return;
    }

    console.log(values.gfa, "check gfa");
    calculateWarehouse(values.gfa);
  };

  // calculate existig quotevalues
  // const calculateExistingQuoteValues = () => {
  // };
  const createQuote = (exist = false) => {
    const id = Date.now();

    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    setprogress(true);
    setmsg("Initializing quote");

    // check if client exists with the email
    // split name and surname
    let name = values?.name?.split(" ");
    // const [firstName, ...lastName] = name;
    firebase
      .firestore()
      .collection("clients")
      .where("email", "==", values.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // alert("Client does not exist, creating new client");
          setmsg("Creating client");
          // create client
          firebase
            .firestore()
            .collection("clients")
            .add({
              ...values,
              createdAt: new Date(Date.now()).toLocaleDateString(),
            })
            .then((doc) => {
              setmsg("attaching quote to client");
              let asd = {
                ...values,
                quote_id: id,
                client_id: doc.id,
                quoteFile: fileURLCOmplete,
                quoteType: exist ? "existing" : "normal",
                status: exist
                  ? QUOTE_STATUS.INVOICED
                  : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
                user_id: firebase.auth().currentUser.uid,
                quote_source: "OUTBOUND",
                ...calculateOffice(values.gfa),
                createdAt: new Date(Date.now()).toLocaleDateString(),
                dateAdded: new Date(Date.now()),
              };
              firebase
                .firestore()
                .collection("quotes")
                .add(asd)
                .then((doc) => {
                  setmsg("saving data to the Database");
                  return getBlob(doc, asd, id);
                });
              // return createQuoteWithClient(doc.id);
            });

          return;
        }
        querySnapshot.forEach((doc) => {
          setmsg("Attaching quote to client");
          let asd = {
            ...values,
            quote_id: id,
            client_id: doc.id,
            quoteFile: fileURLCOmplete,
            quoteType: exist ? "existing" : "normal",
            status: exist
              ? QUOTE_STATUS.INVOICED
              : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
            user_id: firebase.auth().currentUser.uid,
            quote_source: "OUTBOUND",
            ...calculateOffice(values.gfa, existingQuoteValues),
            createdAt: new Date(Date.now()).toLocaleDateString(),
            dateAdded: new Date(Date.now()),
          };

          firebase
            .firestore()
            .collection("quotes")
            .add(asd)
            .then((doc) => {
              setmsg("saving data to the Database");
              // set quote id to quote
              console.log("quote id", doc.id);
              setQuoteId(doc.id);
              return getBlob(doc, asd, id);
            });
          // return createQuoteWithClient(doc.id);
          // return createQuoteWithClient(doc.id);
        });
      });
  };

  const createProposalQuote = (exist = false) => {
    const id = Date.now();

    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    setprogress(true);
    setmsg("Initializing quote");

    // check if client exists with the email
    // split name and surname
    // let name = values?.name?.split(" ");
    // const [firstName, ...lastName] = name;
    firebase
      .firestore()
      .collection("clients")
      .where("email", "==", values.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // alert("Client does not exist, creating new client");
          setmsg("Creating client");

          // create client
          firebase
            .firestore()
            .collection("clients")
            .add({
              ...values,
              createdAt: new Date(Date.now()).toLocaleDateString(),
            })

            // Attach Quote Section
            //

            .then((doc) => {
              setmsg("attaching quote to client");
              let asd = {
                ...values,
                quote_id: id,
                client_id: doc.id,
                quoteFile: fileURLCOmplete,
                quoteType: exist ? "existing" : "normal",
                status: exist
                  ? QUOTE_STATUS.INVOICED
                  : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
                user_id: firebase.auth().currentUser.uid,
                quote_source: "OUTBOUND",
                quoteType: exist ? "existing" : "normal",
                ...calculateOffice(values.gfa),
                createdAt: new Date(Date.now()).toLocaleDateString(),
                dateAdded: new Date(Date.now()),
              };

              // Saving Generated quote to Firbase Store
              //

              firebase
                .firestore()
                .collection("quotes")
                .add(asd)
                .then((doc) => {
                  setmsg("saving data to the Database");
                  return getBlob(doc, asd, id);
                });

              // return createQuoteWithClient(doc.id);
            });

          return;
        }
        querySnapshot.forEach((doc) => {
          setmsg("Attaching quote to client");
          let asd = {
            ...values,
            quote_id: id,
            client_id: doc.id,
            quoteFile: fileURLCOmplete,
            quoteType: exist ? "existing" : "normal",
            status: exist
              ? QUOTE_STATUS.INVOICED
              : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
            user_id: firebase.auth().currentUser.uid,
            quote_source: "OUTBOUND",
            ...calculateOffice(values.gfa, existingQuoteValues),
            createdAt: new Date(Date.now()).toLocaleDateString(),
            dateAdded: new Date(Date.now()),
          };

          firebase
            .firestore()
            .collection("quotes")
            .add(asd)
            .then((doc) => {
              setmsg("saving data to the Database");
              // set quote id to quote
              console.log("quote id", doc.id);
              setQuoteId(doc.id);
              return getBlob(doc, asd, id);
            });
          // return createQuoteWithClient(doc.id);
          // return createQuoteWithClient(doc.id);
        });
      });
  };

  const createWarehouseQuote = (exist = false) => {
    const id = Date.now();
    console.log(existingQuoteValues);

    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }
    setprogress(true);
    firebase
      .firestore()
      .collection("clients")
      .where("email", "==", values.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // alert("Client does not exist, creating new client");
          setmsg("Creating client");
          // create client
          firebase
            .firestore()
            .collection("clients")
            .add({
              ...values,
              createdAt: new Date(Date.now()).toLocaleDateString(),
            })
            .then((doc) => {
              setmsg("attaching quote to client");
              let asd = {
                ...values,
                quote_id: id,
                client_id: doc.id,
                quoteFile: fileURLCOmplete,
                quoteType: exist ? "existing" : "normal",
                status: exist
                  ? QUOTE_STATUS.INVOICED
                  : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
                user_id: firebase.auth().currentUser.uid,
                quote_source: "OUTBOUND",

                ...calculateWarehouse(values.gfa, existingQuoteValues),
                createdAt: new Date(Date.now()).toLocaleDateString(),
                dateAdded: new Date(Date.now()),
              };
              firebase
                .firestore()
                .collection("quotes")
                .add(asd)
                .then((doc) => {
                  setmsg("saving data to the Database");
                  return getBlob(doc, asd, id);
                });
              // return createQuoteWithClient(doc.id);
            });

          return;
        }
        querySnapshot.forEach((doc) => {
          setmsg("Attaching quote to client");
          let asd = {
            ...values,
            quote_id: id,
            client_id: doc.id,
            quoteFile: fileURLCOmplete,
            quoteType: exist ? "existing" : "normal",
            status: exist
              ? QUOTE_STATUS.INVOICED
              : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
            user_id: firebase.auth().currentUser.uid,
            quote_source: "OUTBOUND",
            ...calculateWarehouse(values.gfa),
            createdAt: new Date(Date.now()).toLocaleDateString(),
            dateAdded: new Date(Date.now()),
          };

          firebase

            .firestore()
            .collection("quotes")
            .add(asd)
            .then((doc) => {
              setmsg("saving data to the Database");
              // set quote id to quote
              console.log("quote id", doc.id);
              setQuoteId(doc.id);
              return getBlob(doc, asd, id);
            });
          // return createQuoteWithClient(doc.id);
          // return createQuoteWithClient(doc.id);
        });
      });

    // setprogress(true);
    // setmsg("Putting things together");
    // let asd = {
    //   ...values,
    //   quote_id: id,
    //   status: "NEW",
    //   state: QUOTE_STATUS.SENT_TO_CLIENT,
    //   quote_source: "OUTBOUND",
    //   ...calculateWarehouse(values.gfa),
    //   createdAt: new Date(Date.now()).toLocaleDateString(),
    //   dateAdded: new Date(Date.now()),
    // };

    // console.log(asd);
    // firebase
    //   .firestore()
    //   .collection("quotes")
    //   .add(asd)
    //   .then((doc) => {
    //     setmsg("Sending data to the Database");
    //     return getBlob(doc, asd, id);
    //   });
  };

  const createResidentialQuote = (exist = false) => {
    const id = Date.now();
    setprogress(true);
    setmsg("Putting things together");
    if (values.email === null || values.email === "" || values.gfa === null) {
      notify("Enter all the values required");
      return;
    }

    firebase
      .firestore()
      .collection("clients")
      .where("email", "==", values.email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          // alert("Client does not exist, creating new client");
          setmsg("Creating client");
          // create client
          firebase
            .firestore()
            .collection("clients")
            .add({
              ...values,
              createdAt: new Date(Date.now()).toLocaleDateString(),
            })
            .then((doc) => {
              setmsg("attaching quote to client");
              let asd = {
                ...values,
                quote_id: id,
                quoteFile: fileURLCOmplete,
                quoteType: exist ? "existing" : "normal",
                client_id: doc.id,
                status: exist
                  ? QUOTE_STATUS.INVOICED
                  : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
                user_id: firebase.auth().currentUser.uid,
                quote_source: "OUTBOUND",
                ...calculateResidential(
                  values.typology,
                  values.units,
                  existingQuoteValues
                ),
                createdAt: new Date(Date.now()).toLocaleDateString(),
                dateAdded: new Date(Date.now()),
              };
              firebase

                .firestore()
                .collection("quotes")
                .add(asd)
                .then((doc) => {
                  setmsg("saving data to the Database");
                  return getBlob(doc, asd, id);
                });
              // return createQuoteWithClient(doc.id);
            });

          return;
        }
        querySnapshot.forEach((doc) => {
          setmsg("Attaching quote to client");
          let asd = {
            ...values,
            quote_id: id,
            quoteFile: fileURLCOmplete,
            quoteType: exist ? "existing" : "normal",
            client_id: doc.id,
            status: exist
              ? QUOTE_STATUS.INVOICED
              : QUOTE_STATUS.PENDING_CLIENT_ACCEPTANCE,
            user_id: firebase.auth().currentUser.uid,
            quote_source: "OUTBOUND",
            ...calculateResidential(
              values.typology,
              values.units,
              existingQuoteValues
            ),
            createdAt: new Date(Date.now()).toLocaleDateString(),
            dateAdded: new Date(Date.now()),
          };

          firebase
            .firestore()
            .collection("quotes")
            .add(asd)
            .then((doc) => {
              setmsg("saving data to the Database");
              // set quote id to quote
              console.log("quote id", doc.id);
              setQuoteId(doc.id);
              return getBlob(doc, asd, id);
            });
          // return createQuoteWithClient(doc.id);
          // return createQuoteWithClient(doc.id);
        });
      });

    // console.log(values, "check values in here");
    // return;
    // let asd = {
    //   ...values,
    //   quote_id: id,
    //   status: "NEW",
    //   state: QUOTE_STATUS.SENT_TO_CLIENT,
    //   quote_source: "OUTBOUND",
    //   ...calculateResidential(values.typology, values.units),
    //   createdAt: new Date(Date.now()).toLocaleDateString(),
    //   dateAdded: new Date(Date.now()),
    // };

    // // remove all the undefined values
    // Object.keys(asd).forEach(
    //   (key) => asd[key] === undefined && delete asd[key]
    // );

    // // console.log(asd, "check here");

    // // return;
    // firebase
    //   .firestore()
    //   .collection("quotes")
    //   .add(asd)
    //   .then((doc) => {
    //     setmsg("Sending data to the Database");
    //     return getBlob(doc, asd, id);
    //   });
  };

  const handleChange1 = (event) => {
    setChecked(event.target.checked);
    console.log(event.target.checked, "Checked ");
  };

  const handleAUChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, AuditUnits: newValue });
  };

  const handleSCChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, SC: newValue });
  };

  const handleDPCChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, DPC: newValue });
  };
  const handleDPCCChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, DPCC: newValue });
  };
  const handlePDAChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, PDA: newValue });
  };
  const handlePCSAChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, PCSA: newValue });
  };
  const handlePMSChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setExistingQuoteValues({ ...existingQuoteValues, PMS: newValue });
  };

  const handleAddExistingQuote = () => {};
  React.useEffect(() => {
    // get client with email
    if (email) {
      firebase
        .firestore()
        .collection("clients")
        .where("email", "==", email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            console.log(data, "data");
            setClient(data);
            setClientEmail(data?.email || "");
            setClientName(data?.firstName + " " + data?.lastName || "");
            setClientPhone(data?.phone || "");
            setClientFirstName(data?.firstName || "");
            setClientLastName(data?.lastName || "");
          });
        });
    }
  }, [email]);

  return (
    <Page title="Create quote | Dashboard">
      {progress && <MainLoadingPage msg={msg} />}

      <Container>
        {/* back button */}
        <Button
          color="primary"
          size="medium"
          variant="text"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Create Quote
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    value={clientName}
                    onChange={(e) => {
                      setClientName(e.target.value);
                    }}
                    label={"Client Name"}
                    InputLabelProps={{
                      shrink: clientName ? true : false,
                    }}
                  />
                  <TextField
                    fullWidth
                    type="email"
                    value={clientEmail}
                    onChange={(e) => {
                      setClientEmail(e.target.value);
                    }}
                    label={"Client Email"}
                    InputLabelProps={{
                      shrink: clientEmail ? true : false,
                    }}
                  />
                  <TextField
                    fullWidth
                    type="number"
                    value={clientPhone}
                    onChange={(e) => {
                      setClientPhone(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: clientPhone ? true : false,
                    }}
                    label={"Client Phone"}
                  />
                  {/* 
<TextField
                    fullWidth
                    select
                    onChange={handleChange("type")}
                    label="Project type"
                  >
                    <MenuItem key={null} value={optionsType[0]}>
                      Select a project type
                    </MenuItem>
                    {optionsType.map((optionsType) => (
                      <MenuItem key={optionsType} value={optionsType}>
                        {optionsType}
                      </MenuItem>
                    ))}
                  </TextField>

                  {values.type === null
                    ? null
                    : values.type === "Edge" && (
                        <TextField
                          fullWidth
                          type="number"
                          onChange={handleChange("gfa")}
                          label="Edge"
                        />
                      )}

                  {values.type === null
                    ? null
                    : values.type === "GreenStar" && (
                        <TextField
                          fullWidth
                          type="number"
                          onChange={handleChange("gfa")}
                          label="Gross Floor Area"
                        />
                      )}

                  {values.type === null
                    ? null
                    : values.type === "NetZero" && (
                        <Stack spacing={3}>
                          <TextField
                            fullWidth
                            type="number"
                            onChange={handleChange("typology")}
                            label="Typologies"
                          />
                          <TextField
                            fullWidth
                            type="number"
                            onChange={handleChange("units")}
                            label="Units in Development"
                          />
                        </Stack>
                      )} */}

                  <TextField
                    fullWidth
                    onChange={handleChange("project")}
                    label="Project Name"
                  />

                  <TextField
                    fullWidth
                    select
                    onChange={handleChange("type")}
                    label="Building Type"
                  >
                    <MenuItem key={null} value={options[0]}>
                      Select a building Type
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  {values.type === null
                    ? null
                    : values.type === "Office" && (
                        <TextField
                          fullWidth
                          type="number"
                          onChange={handleChange("gfa")}
                          label="Gross Floor Area"
                        />
                      )}

                  {values.type === null
                    ? null
                    : values.type === "Warehouse" && (
                        <TextField
                          fullWidth
                          type="number"
                          onChange={handleChange("gfa")}
                          label="Gross Floor Area"
                        />
                      )}

                  {values.type === null
                    ? null
                    : values.type === "Residential" && (
                        <Stack spacing={3}>
                          <TextField
                            fullWidth
                            type="number"
                            onChange={handleChange("typology")}
                            label="Typologies"
                          />
                          <TextField
                            fullWidth
                            type="number"
                            onChange={handleChange("units")}
                            label="Units in Development"
                          />
                        </Stack>
                      )}
                </Stack>
              </Card>
              <Stack
                spacing={3}
                direction="row"
                style={{ alignItems: "center" }}
              >
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    handleChange1(e);
                  }}
                />{" "}
                <Typography variant="body1" gutterBottom>
                  Performance Modelling
                </Typography>
              </Stack>

              <Grid sx={{ p: 3 }}>
                <Stack>
                  {values.type ? (
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        fullWidth={false}
                        variant="contained"
                        onClick={() => {
                          setExistingQuoteValues(false);
                          values?.type === "Office" && calculate();
                          values?.type === "Residential" && calculateR();
                          values?.type === "Warehouse" && calculateW();

                          // add client, phone, and email to values
                          values.client = clientName;
                          values.email = clientEmail;
                          values.phone = clientPhone;
                        }}
                      >
                        Calculate
                      </Button>

                      {/* <Button
                        fullWidth={false}
                        variant="outlined"
                        onClick={() => {
                          setExistingQuoteValues(true);

                          // add client, phone, and email to values
                          values.client = clientName;
                          values.email = clientEmail;
                          values.phone = clientPhone;
                        }}
                      >
                        Add existing quote
                      </Button> */}

                      <Button
                        fullWidth={false}
                        variant="outlined"
                        onClick={() => {
                          setExistingQuoteValues(true);

                          // values?.type === "Office" && calculate();
                          // values?.type === "Residential" && calculateR();
                          // values?.type === "Warehouse" && calculateW();

                          console.log("calcs");
                          // add client, phone, and email to values
                          values.client = clientName;
                          values.email = clientEmail;
                          values.phone = clientPhone;
                        }}
                      >
                        Add existing quote
                      </Button>
                    </Grid>
                  ) : (
                    <Typography>Select a building type</Typography>
                  )}
                </Stack>
              </Grid>
            </Stack>
          </Grid>

          {officeValues && !existingQuoteValues && (
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Expert Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R {fNumber(officeValues?.ExpertServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Assessment
                    <br /> R{fNumber(officeValues?.SC)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Preliminary Certification
                    <br /> R{fNumber(officeValues?.DPC)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Post Construction Certification
                    <br /> R{fNumber(officeValues?.DPCC)}
                  </Typography>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Auditor Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(officeValues?.AuditorServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Design Audit
                    <br /> R{fNumber(officeValues?.PDA)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Post Construction Site Audit
                    <br /> R{fNumber(officeValues?.PCSA)}
                  </Typography>

                  <Stack>
                    <Typography variant="body2" gutterBottom>
                      Performance Modeling and Simulation
                      <br />R{fNumber(officeValues?.PMS)}
                    </Typography>
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Total Consulting Fees
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(officeValues?.TotalFees)}
                  </Typography>
                </Card>

                <Card sx={{ p: 3 }}>
                  {progress ? null : (
                    <Button
                      fullWidth={false}
                      variant="contained"
                      onClick={() => createQuote()}
                    >
                      Send Quote
                    </Button>
                  )}
                </Card>
              </Stack>
            </Grid>
          )}
          {warehouseValues && !existingQuoteValues && (
            <Grid item xs={12} sm={6} md={6}>
              <h1>{values.type}</h1>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Expert Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R {fNumber(warehouseValues.ExpertServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Assessment
                    <br /> R{fNumber(warehouseValues.SC)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Preliminary Certification
                    <br /> R{fNumber(warehouseValues.DPC)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Post Construction Certification
                    <br /> R{fNumber(warehouseValues.DPCC)}
                  </Typography>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Auditor Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(warehouseValues.AuditorServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Design Audit
                    <br /> R{fNumber(warehouseValues.PDA)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Post Construction Site Audit
                    <br /> R{fNumber(warehouseValues.PCSA)}
                  </Typography>

                  <Stack>
                    <Typography variant="body2" gutterBottom>
                      Performance Modeling and Simulation
                      <br />R{fNumber(warehouseValues.PMS)}
                    </Typography>
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Total Consulting Fees
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(warehouseValues.TotalFees)}
                  </Typography>
                </Card>

                <Card sx={{ p: 3 }}>
                  {progress
                    ? null
                    : values.type === "Office" && (
                        <Button
                          fullWidth={false}
                          variant="contained"
                          onClick={() => createQuote()}
                        >
                          Send Office Quote
                        </Button>
                      )}

                  {progress
                    ? null
                    : values.type === "Warehouse" && (
                        <Button
                          fullWidth={false}
                          variant="contained"
                          onClick={() => createWarehouseQuote()}
                        >
                          Send Warehouse Quote
                        </Button>
                      )}
                </Card>
              </Stack>
            </Grid>
          )}
          {residentialValues && !existingQuoteValues && (
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Expert Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R {fNumber(residentialValues.ExpertServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Sustainability Consulting
                    <br /> R{fNumber(residentialValues.IA)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Preliminary Certification
                    <br /> R{fNumber(residentialValues.DPC)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Post Construction Certification
                    <br /> R{fNumber(residentialValues.DCPCC)}
                  </Typography>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Auditor Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(residentialValues.AuditorServices)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Design Audit
                    <br /> R{fNumber(residentialValues.PA)}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Post Construction Site Audit
                    <br /> R{fNumber(residentialValues.PCA)}
                  </Typography>

                  <Stack>
                    <Typography variant="body2" gutterBottom>
                      Performance Modeling and Simulation
                      <br />R{fNumber(residentialValues?.PMS)}
                    </Typography>
                  </Stack>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Total Consulting Fees
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    R{fNumber(residentialValues.TotalFees)}
                  </Typography>
                </Card>

                <Card sx={{ p: 3 }}>
                  <Button
                    fullWidth={false}
                    variant="contained"
                    onClick={() => createResidentialQuote()}
                  >
                    Send Quote
                  </Button>
                </Card>
              </Stack>
            </Grid>
          )}

          {existingQuoteValues && (
            <Grid item xs={12} sm={6} md={6}>
              <Stack spacing={3}>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Expert Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {/* R 60,749 */}
                  </Typography>
                  {values?.type === "Residential" ? (
                    <Typography variant="body2" gutterBottom>
                      Sustainability Consulting
                      <Input
                        sx={{
                          display: "block",
                        }}
                        placeholder="Enter Sustainability Consulting
                        "
                        onChange={handleAUChange}
                        value={existingQuoteValues.AuditUnits}
                      />
                    </Typography>
                  ) : (
                    <Typography variant="body2" gutterBottom>
                      Preliminary Assessment
                      <Input
                        sx={{
                          display: "block",
                        }}
                        placeholder="Enter preliminary assessment"
                        onChange={handleSCChange}
                        value={existingQuoteValues.SC}
                      />
                    </Typography>
                  )}

                  <Typography variant="body2" gutterBottom>
                    Documentation Preliminary Certification
                    <Input
                      sx={{
                        display: "block",
                      }}
                      placeholder="Enter cost"
                      onChange={handleDPCChange}
                      value={existingQuoteValues.DPC}
                    />
                    {/* <br /> R{fNumber(officeValues.DPC)} */}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Documentation Post Construction Certification
                    <Input
                      sx={{
                        display: "block",
                      }}
                      placeholder="Enter cost"
                      onChange={handleDPCCChange}
                      value={existingQuoteValues.DPCC}
                    />
                    {/* <br /> R{fNumber(officeValues.DPCC)} */}
                  </Typography>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    EDGE Auditor Services
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {/* R{fNumber(officeValues.AuditorServices)} */}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Preliminary Design Audit
                    {/* <br /> R{fNumber(officeValues.PDA)} */}
                    <Input
                      sx={{
                        display: "block",
                      }}
                      placeholder="Enter cost"
                      onChange={handlePDAChange}
                      value={existingQuoteValues.PDA}
                    />
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Post Construction Site Audit
                    {/* <br /> R{fNumber(officeValues.PCSA)} */}
                    <Input
                      sx={{
                        display: "block",
                      }}
                      placeholder="Enter cost"
                      onChange={handlePCSAChange}
                      value={existingQuoteValues.PCSA}
                    />
                  </Typography>
                  {checked === false ? null : (
                    <Stack>
                      <Typography variant="body2" gutterBottom>
                        Performance Modeling and Simulation
                        {/* <br />R{fNumber(officeValues.PMS)} */}
                        <Input
                          sx={{
                            display: "block",
                          }}
                          placeholder="Enter cost"
                          onChange={handlePMSChange}
                          value={existingQuoteValues.PMS}
                        />
                      </Typography>
                    </Stack>
                  )}
                </Card>
                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Attach quote file
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {/* R{fNumber(officeValues.TotalFees)} */}
                    {/* <Input
                      type="file"
                      sx={{
                        display: "block",
                      }}
                      onChange={() => {
                        console.log("upload image now");
                      }}
                      placeholder="Attach quote"
                    /> */}
                    <FileUploader
                      onUploadComplete={setFileURLComplete}
                      editable={true}
                    />
                  </Typography>
                </Card>

                <Card sx={{ p: 3 }}>
                  <Typography variant="body1" gutterBottom>
                    Invoice details
                  </Typography>
                  <InvoiceForm
                    indicateLoading={false}
                    disable={false}
                    hideButton={true}
                  />
                </Card>
                <Card sx={{ p: 3 }}>
                  {progress ? null : (
                    <Button
                      fullWidth={false}
                      variant="contained"
                      onClick={() => {
                        const type = values?.type;

                        if (type === "Office") {
                          createQuote(true);
                        }
                        if (type === "Residential") {
                          createResidentialQuote(true);
                        }
                        if (type === "Warehouse") {
                          // console.log("TETTTETETTET",{existingQuoteValues})
                          createWarehouseQuote(true);
                        }

                        // values?.type === "Office" && createQuote(true);
                        // values?.type === "Residential" &&
                        //   createResidentialQuote(true);
                        // values?.type === "Warehouse" &&
                        //   createWarehouseQuote(true);
                      }}
                    >
                      Add Quote
                    </Button>
                  )}
                </Card>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

function FileUploader({ onUploadComplete, editable = true }) {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0); // Optional: can be used to show upload progress

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = firebase.storage().ref(`uploads/${file.name}`);
    setUploading(true);

    const uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
        setUploading(false);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          onUploadComplete(downloadURL);
          setUploading(false);
        });
      }
    );
  };

  return (
    <div>
      {uploading ? (
        <div>
          <CircularProgress variant="determinate" value={progress} />
          <p>Uploading... {Math.round(progress)}%</p>
        </div>
      ) : (
        <label htmlFor="file-upload">
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            disabled={!editable}
            style={{ display: "none" }}
          />
          <Button variant="contained" component="span" disabled={!editable}>
            Upload File
          </Button>
        </label>
      )}
    </div>
  );
}
