import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const banUser = async (uid) => {
  const db = firebase.firestore();

  try {
    // Get the user's document from Firestore
    const userDoc = await db.collection("users").doc(uid).get();

    if (userDoc.exists) {
      console.log("user found");
      // Update the user's document with the "banned" field set to true
      await db.collection("users").doc(uid).update({
        banned: true,
      });

      // If the user is currently logged in, sign them out
      if (firebase.auth().currentUser?.uid === uid) {
        await firebase.auth().signOut();
      }

      // Return a success message
      console.log("user found and banned");
      return { success: true };
    } else {
      console.log("user not found");
      // If the user doesn't exist, return an error message
      return { error: "User not found." };
    }
  } catch (error) {
    // If there's an error, return an error message
    return { error: error.message };
  }
};
