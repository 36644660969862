import { useState, useEffect } from "react";
import firebase from "../firebase";

const useGetClientsNew = () => {
  const [docs, setdocs] = useState([]);

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection("clients")
      .get()
      .then((doc) => {
        const dt = doc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log("dt", dt);
        setdocs(dt);
      });
    return unsub;
  }, []);
  return { docs };
};

export default useGetClientsNew;
