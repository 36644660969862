import { Link as RouterLink, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
// material
import { Grid, Button, Container, Checkbox, Stack, Typography, Card, TextField, MenuItem } from '@mui/material';
// components
import Page from '../../components/Page';
import { fNumber } from '../../utils/formatNumber';
import firebase from '../../firebase' 
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AddNewUser() {
  //  const location = useLocation()
  const notify = (msg) => toast(msg);
    const [checked, setChecked] = useState(true);
    let publicKey = "WffsPR6cSS6IwClRB"
   const [values, setValues] = useState(null)

     const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
 
  const sendEmail = () => {

    let obj = {
      email : values.email,
      client: values.firstName,
      subject :"A new account has been created with your email address",
      password : "S0l1d3d9E"
     }
      emailjs.send('service_6a716og',
       'template_e0un0q9',obj , publicKey)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
   
    };

  const createQuote = () =>{
    console.log(values)
    let asd = {
        ...values, 
        createdAt: new Date(Date.now()).toLocaleDateString()
    }
  
    firebase.auth().createUserWithEmailAndPassword(values.email, "S0l1d3d9E").then((doc)=>{
        firebase.firestore().collection("users").doc(doc.user.uid).set(asd).then(()=>{
          notify("Account created")
            sendEmail()
        }).catch((error)=>{
          notify(error.message)
        })
    }).catch((error)=>{
      notify(error.message)
    })
    
  }

  return (
    <Page title="Dashboard">
    <Container>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
           Create New User
        </Typography> 
      </Stack>

      <Grid container spacing={3}>
      <Grid item xs={6} md={6}>
      <Card sx={{p:3}}>
        <Stack spacing={3}>
        <TextField
        fullWidth
        onChange={handleChange('firstName')}    
        label="First  Name" />
         <TextField
        fullWidth
        onChange={handleChange('lastName')}    
        label="Last Name" />
           <TextField
        fullWidth
        onChange={handleChange('email')}    
        label="Email Address" />
         <TextField
        fullWidth
        select
        onChange={handleChange('admin')}    
        label="Admin" >
         <MenuItem  value={true}>
               Yes
            </MenuItem>
            <MenuItem  value={false}>
               No
            </MenuItem>
        </TextField>
        <TextField
        fullWidth
        select
        onChange={handleChange('group')}    
        label="Group" >
         <MenuItem  value={"ADMIN"}>
               Admin
            </MenuItem>
            <MenuItem  value={"ACCOUNTS"}>
               Accounts
            </MenuItem>
            <MenuItem  value={"NORMAL"}>
               Normal
            </MenuItem>
        </TextField>
         <Typography>
         Default Password : S0l1d3d9E
         </Typography>
        <Button
        fullWidth={false}
          variant="contained"
           onClick={()=>createQuote()}
             >
          Create User
        </Button>
        </Stack>
      </Card>
      </Grid>

      <Grid item xs={6} md={6}>
      <Card sx={{p:3}}>
        <Stack spacing={3}>
            <Typography variant="h5">User Account types</Typography>
            <Typography variant="h6">Admin</Typography>
            <Typography variant="body1">Admin account description</Typography>
            <Typography variant="h6">Accounts</Typography>
            <Typography variant="body1">Accounts account description</Typography>
            <Typography variant="h6">Normal</Typography>
            <Typography variant="body1">Normal account description</Typography>
        </Stack>
        </Card>
        </Grid>
      </Grid>
    </Container>
  </Page>
  )
}
