import { useState, useEffect } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { Link, Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Chip,
  IconButton,
  // Icon,
  TablePagination,
  LinearProgress,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BlockIcon from "@mui/icons-material/Block";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../../sections/@dashboard/user";
//
import useGetAdmins from "../../../hooks/useGetAdmins";
// ----------------------------------------------------------------------
import firebase from "src/firebase";
import LoadingScreen from "src/components/LoadingScreen";
import { ChangeAdminType } from "./components/ChangeAdminType";
import { banUser } from "src/utils/BanUser";

const TABLE_HEAD = [
  { id: "firstName", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "userType", label: "User type", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  // console.log("array", stabilizedThis, "<<<<<");
  return stabilizedThis.map((el) => el[0]);
}

export default function AdminList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [adminEditState, setAdminEditState] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const groupBy = (key) => (array) =>
    array.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
      }),
      {}
    );
  let admins = groupBy("email", "firstName", "lastName", "userType");
  let ADMINSLIST = Object.keys(admins(useGetAdmins().docs));
  const Admins = useGetAdmins().docs;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    console.log("property", property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Admins.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Admins.length) : 0;

  const filteredUsers = applySortFilter(
    Admins,
    getComparator(order, orderBy),
    filterName
  );

  const handleBanUserFromLogin = (uid) => {
    // prompt user to confirm ban
    const confirmBan = window.confirm(
      "Are you sure you want to ban this user from login?"
    );
    if (!confirmBan) return; // user cancels ban
    console.log("uid", uid);
    firebase
      .firestore()
      .collection("users")
      .where("uid", "==", uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("users")
            .doc(doc.id)
            .update({
              status: "banned",
            })
            .then(() => {
              alert("user banned!");
              // refresh page
              window.location.reload();
              console.log("user banned");
            })
            .catch((error) => {
              console.log("Error updating user:", error);
            });
        });
      });
  };

  const handleUnbanUserFromLogin = (uid) => {
    // prompt user to confirm ban
    const confirmBan = window.confirm(
      "Are you sure you want to unban this user from login?"
    );
    if (!confirmBan) return; // user cancels ban
    console.log("uid", uid);
    firebase
      .firestore()
      .collection("users")
      .where("uid", "==", uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firebase
            .firestore()
            .collection("users")
            .doc(doc.id)
            .update({
              status: "active",
            })
            .then(() => {
              alert("user unbanned!");
              window.location.reload();
              console.log("user unbanned");
            })
            .catch((error) => {
              console.log("Error updating user:", error);
            });
        });
      });
  };

  const isUserNotFound = filteredUsers.length === 0;
  // console.log("ADMINSLIST", admins(useGetAdmins().docs));

  useEffect(() => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("users")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setLoggedInUser(doc.data());
          setIsLoading(false);
          console.log(doc.data(), "check use on auth");
        });
      });
  }, []);

  return (
    <Page title="Admins">
      <ChangeAdminType
        open={adminEditState}
        admin={selectedAdmin}
        setOpen={setAdminEditState}
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LoadingScreen />
        </div>
      ) : (
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Admins
            </Typography>
            {loggedInUser?.type === "root" && (
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={{ marginBottom: 10 }}
              >
                <Link
                  to={"/admins/create"}
                  style={{
                    display: "grid",
                    color: "inherit",
                    textDecoration: "none",
                    justifyContent: "center",
                  }}
                >
                  Add Admin
                </Link>
              </Button>
            )}
          </Stack>

          <Card>
            {/* {isLoading && <LinearProgress />}  */}
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={ADMINSLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        // console.log("row", row, "<<<<");
                        return (
                          <TableRow hover key={index} tabIndex={-1}>
                            <TableCell>
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary" }}
                              >
                                {row?.firstName + " " + row?.lastName}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginLeft: 2 }}
                                  noWrap
                                >
                                  {row?.email}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell>
                              {row?.status === "active" ? (
                                <Chip
                                  label="Active"
                                  size="small"
                                  sx={{
                                    backgroundColor: "success.main",
                                    color: "success.contrastText",
                                  }}
                                />
                              ) : row.status === "banned" ? (
                                <Chip
                                  label="Banned"
                                  size="small"
                                  sx={{
                                    backgroundColor: "error.main",
                                    color: "error.contrastText",
                                  }}
                                />
                              ) : (
                                <Chip
                                  label="Pending"
                                  size="small"
                                  sx={{
                                    backgroundColor: "warning.main",
                                    color: "warning.contrastText",
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {row?.type === "root"
                                ? "Super Admin"
                                : row?.type === "admin"
                                ? "Admin"
                                : "Auditor"}
                            </TableCell>
                            <TableCell />
                            {loggedInUser?.type === "root" && (
                              <TableCell align="right">
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: 100,
                                  }}
                                >
                                  <IconButton>
                                    <ModeEditIcon
                                      onClick={() => {
                                        setAdminEditState(true);
                                        setSelectedAdmin(row);
                                      }}
                                    />
                                  </IconButton>

                                  {/* <IconButton>
                                    <VisibilityIcon />
                                  </IconButton> */}

                                  {/* <DeleteForeverIcon /> */}
                                  <IconButton>
                                    {row?.status === "banned" ? (
                                      <PersonAddAltIcon
                                        onClick={() => {
                                          handleUnbanUserFromLogin(row.uid);

                                          // console.log("banned");
                                        }}
                                      />
                                    ) : (
                                      <BlockIcon
                                        onClick={() => {
                                          handleBanUserFromLogin(row?.uid);
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={ADMINSLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
